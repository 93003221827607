import React from "react"
import {Card} from "../../../components/Elements";

const FareRuleComponentInner = ({fareRuleData}) => {
    return (
        <>
            <div className={'fare_rule_pop'}>
                {fareRuleData && fareRuleData['Airline'] ?
                    <Card className={'mt-2 airline_info'}>
                        <div className={'airline_info_details'}>
                            <div
                                dangerouslySetInnerHTML={{__html: fareRuleData['FareRuleDetail'].replace(/\n/g, '<br />')}}></div>
                        </div>
                    </Card> : null}
            </div>
        </>
    )
}
export default FareRuleComponentInner
