import React, {useState, useEffect} from "react";
import {Modal} from "../../components/Elements";
import {CancellationRequestTypes, ReissueRequestTypes} from "../../components/utils/appUtils";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom"
import {cancelRequestFxn, cancelChargesFxn, reissueTicketFxn} from "../ticketList/action";
import moment from "moment";

const initState = {
    cabinClass: "2",
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    resultFareType: "2",
    preferredDepartureTime: moment(),
    preferredArrivalTime: moment()

}
const CancelRequestModal = (props) => {
    let {visible, onClose, ticket, onSubmit} = props;
    const navigate = useNavigate()
    const [state, setState] = useState(initState)
    const BookingId = ticket?.response?.BookingId || '';
    const [formData, setFormData] = useState({
        RequestType: 0,
        Remarks: "",
        Sectors: [],
        TicketId: [],
        PassengerNames: [],
        CancelInsurance: false,
        BookingId,
        Document: null,
        reissueStartDate: null,
        reissueReturnDate: null,
        ReissueType: null,
        PNR: ticket?.response?.PNR,
        xTravelTicketId: ticket?._id
    });
    const [cancelCharges, setCancelCharges] = useState({})
    // console.log(ticket, 'ticket----');

    const dispatch = useDispatch();

    useEffect(() => {
        if (formData.TicketId.length > 0) {
            setFormData((prev) => {
                const updatedSectors = prev.Sectors.map((sector) => ({
                    ...sector,
                    // TicketId: formData.TicketId,
                }));
                return {...prev, Sectors: updatedSectors};
            });
        }
    }, [formData.TicketId]);

    const handleChange = (key, value) => {
        setFormData((prev) => ({
            ...prev,
            [key]: key.includes("Date") ? moment(value) : value,
        }));
    };


    const handleRequestTypeChange = async (value) => {
        setFormData((prev) => {
            let updatedSectors = [...prev.Sectors];
            let updatedTicketIds = [...prev.TicketId];

            if (Number(value) === 1) {
                updatedSectors = segments.map((segment) => ({
                    Origin: segment.Origin?.Airport?.AirportCode,
                    Destination: segment.Destination?.Airport?.AirportCode,
                }));
                updatedTicketIds = passengers.map((passenger) => Number(passenger.Ticket.TicketId));
            }

            return {
                ...prev,
                RequestType: Number(value),
                Sectors: Number(value) === 1 ? updatedSectors : prev.Sectors,
                TicketId: Number(value) === 1 ? updatedTicketIds : prev.TicketId,
            };
        });

        if (Number(value) === 1) {
            try {
                const response = await dispatch(cancelChargesFxn({
                    BookingId: ticket?.response?.BookingId,
                    RequestType: value
                }));
                if (response.success) {
                    setCancelCharges(response.data);
                }
            } catch (error) {
                // console.error("Error in cancelChargesFxn:", error);
            }
        }
    };

    const handleSearchFlight = async () => {
        const passengerCounts = ticket?.response?.FlightItinerary?.Passenger?.reduce(
            (acc, passenger) => {
                if (passenger.PaxType === 1) acc.adultCount += 1;
                if (passenger.PaxType === 2) acc.childCount += 1;
                if (passenger.PaxType === 3) acc.infantCount += 1;
                return acc;
            },
            {adultCount: 0, childCount: 0, infantCount: 0}
        );

        let updatedFlightObj = {
            ...state,
            ...passengerCounts,
            journeyType: 1,
            origin: ticket?.response?.FlightItinerary?.Origin || "",
            destination: ticket?.response?.FlightItinerary?.Destination || "",
            preferredDepartureTime: formData.reissueStartDate || "",
            preferredArrivalTime: formData.reissueReturnDate || "",
            SearchType: 1,
            PNR: ticket?.response?.PNR,
            BookingId,
            traceId: ticket?.TraceId,
            tokenId: ticket?.TokenId,
            ticketData: ticket?.response
        };

        // console.log(updatedFlightObj, "Updated Flight Object for Search");

        dispatch({type: "SEARCH_FLIGHT", flightObj: updatedFlightObj});
        navigate('/flight-search-result');
    };

    const getCancelCharges = async (bookingId, requestType) => {
        try {
            const resp = await dispatch(cancelChargesFxn({BookingId: bookingId, RequestType: requestType}));
            // console.log("Cancel Charges Response:", resp);
            if (resp.success) {
                setCancelCharges(resp.data)
            }
        } catch (error) {
            // console.error("Error in cancelChargesFxn:", error);
        }
    }

    const segments = ticket?.response?.FlightItinerary?.Segments || [];
    const passengers = ticket?.response?.FlightItinerary?.Passenger || [];
    const fareRules = ticket?.response?.FlightItinerary?.FareRules || [];

    const extractULContent = (htmlString) => {
        const match = htmlString.match(/<ul>(.*?)<\/ul>/is);
        return match ? match[0] : null;
    };

    const refundSectorOptions = [
        {value: "All", name: "All"},
        ...segments.map((segment) => ({
            value: `${segment.Origin?.Airport?.AirportCode}-${segment.Destination?.Airport?.AirportCode}`,
            name: `${segment.Origin?.Airport?.AirportCode} → ${segment.Destination?.Airport?.AirportCode}`,
        })),
    ];

    const passengerOptions = passengers.map((passenger) => ({
        value: passenger.Ticket.TicketId,
        name: `${passenger.FirstName} ${passenger.LastName}`,
    }));

    const handleSelectionChange = (key, options, value) => {
        setFormData((prev) => {
            let updatedTicketIds = [...prev.TicketId];
            let updatedPassengerNames = [...prev.PassengerNames];

            if (key === "Sectors") {
                const updatedSectors = [...prev.Sectors];

                if (value === "All") {
                    updatedSectors.length = 0;
                    segments.forEach((segment) => {
                        updatedSectors.push({
                            Origin: segment.Origin?.Airport?.AirportCode,
                            Destination: segment.Destination?.Airport?.AirportCode,
                        });
                    });
                } else {
                    const selectedSector = options.find((option) => option.value === value);
                    const [Origin, Destination] = selectedSector?.value.split("-");

                    const isSelected = updatedSectors.some(
                        (sector) => sector.Origin === Origin && sector.Destination === Destination
                    );

                    if (isSelected) {
                        const index = updatedSectors.findIndex(
                            (sector) => sector.Origin === Origin && sector.Destination === Destination
                        );
                        updatedSectors.splice(index, 1);
                    } else {
                        updatedSectors.push({
                            Origin,
                            Destination,
                        });
                    }
                }

                return {...prev, Sectors: updatedSectors};
            } else {
                if (value === "All") {
                    updatedTicketIds = options.map((opt) => Number(opt.value));
                    updatedPassengerNames = options.map((opt) => opt.name);
                } else {
                    const selectedOption = options.find((opt) => opt.value === value);
                    const numericValue = Number(value);

                    if (updatedTicketIds.includes(numericValue)) {
                        updatedTicketIds = updatedTicketIds.filter((id) => id !== numericValue);
                        updatedPassengerNames = updatedPassengerNames.filter((name) => name !== selectedOption?.name);
                    } else {
                        updatedTicketIds.push(numericValue);
                        updatedPassengerNames.push(selectedOption?.name);
                    }
                }

                return {...prev, TicketId: updatedTicketIds, PassengerNames: updatedPassengerNames};
            }
        });
    };


    const handleSubmit = async () => {
        const formDataToSend = new FormData();

        let objData = {...formData, TicketId: formData.TicketId, traceId: ticket?.TraceId, tokenId: ticket?.TokenId};
        delete objData.Document;
        objData.xTravelTicketId = ticket._id

        formDataToSend.append("obj", JSON.stringify(objData));

        if (formData.Document) {
            objData.append("document", formData.Document);
        }

        try {
            const response = await dispatch(cancelRequestFxn(objData));
            if (response.success) {
                onSubmit()
            }
        } catch (error) {
            // console.error("Error submitting form:", error);
        }
    };

    const handleFileChange = (file) => {
        setFormData((prev) => ({...prev, Document: file}));
    };

    const allPassengersSelected =
        passengerOptions.length > 0 &&
        formData.TicketId.length === passengerOptions.length;


    return (
        <Modal visible={visible} width="50%" onClose={onClose} className="popup-confirm"
               title={`Request (PNR: ${ticket?.response?.PNR || ''})`}
        >
            <div className="popup pt-0 p-3">
                <div className="row row-gap-2">
                    <div className="col-md-12">
                        <label className="form-label">Request Type</label>
                        <select
                            className="form-control"
                            value={formData.RequestType}
                            onChange={(e) => handleRequestTypeChange(e.target.value)}
                        >
                            <option value="0">Select Request Type</option>
                            {CancellationRequestTypes.map(({value, name}) => (
                                <option key={value} value={value}>{name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Please select Refund Sectors</label>
                        {refundSectorOptions.map(({value, name}) => {
                            const [Origin, Destination] = value.split("-");
                            const isSelected = formData.Sectors.some(
                                (sector) => sector.Origin === Origin && sector.Destination === Destination
                            );
                            const isDisabled = formData.RequestType === 1;

                            return (
                                <div className="form-check" key={value}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`refund-${value}`}
                                        checked={isSelected}
                                        onChange={() => handleSelectionChange("Sectors", refundSectorOptions, value)}
                                        disabled={isDisabled}
                                    />
                                    <label className="form-check-label" htmlFor={`refund-${value}`}>{name}</label>
                                </div>
                            );
                        })}
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Please select Passenger</label>
                        {["All", ...passengerOptions.map((p) => p.value)].map((value) => {
                            const isSelected = formData.TicketId.includes(value);
                            const isDisabled = formData.RequestType === 1;

                            return (
                                <div className="form-check" key={value}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`passenger-${value}`}
                                        checked={isSelected}
                                        onChange={() => handleSelectionChange("TicketId", passengerOptions, value)}
                                        disabled={isDisabled}
                                    />
                                    <label className="form-check-label" htmlFor={`passenger-${value}`}>
                                        {value === "All" ? "All" : passengerOptions.find((p) => p.value === value)?.name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {formData.RequestType == 3 &&
                    formData.Sectors.length === refundSectorOptions.length - 1 && (
                        <div className="col-md-12">
                            <label className="form-label">Departure Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={formData.reissueStartDate ? moment(formData.reissueStartDate).format("YYYY-MM-DD") : ""}
                                onChange={(e) => handleChange("reissueStartDate", e.target.value)}
                            />
                        </div>
                    )}
                    {formData.RequestType == 3 &&
                    formData.Sectors.length === refundSectorOptions.length - 1 && ticket?.response?.FlightItinerary?.JourneyType == 2 && (
                        <div className="col-md-12">
                            <label className="form-label">Return Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={formData.reissueReturnDate ? moment(formData.reissueReturnDate).format("YYYY-MM-DD") : ""}
                                onChange={(e) => handleChange("reissueReturnDate", e.target.value)}
                            />
                        </div>
                    )}
                    {formData.RequestType == 3 && allPassengersSelected && (
                        <div className="col-md-12">
                            <label className="form-label">Please select the Type of ReIssue</label>
                            <div className={'d-flex gap-3'}>
                                {ReissueRequestTypes.map(({value, name}) => (
                                    <div className="form-check" key={value}>
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id={`reissue-${value}`}
                                            name="ReissueType"
                                            value={value}
                                            checked={formData.ReissueType === value}
                                            onChange={() => handleChange("ReissueType", value)}
                                        />
                                        <label className="form-check-label" htmlFor={`reissue-${value}`}>{name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="col-md-12">
                        <hr/>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="cancelInsurance"
                                checked={formData.CancelInsurance}
                                onChange={(e) => handleChange("CancelInsurance", e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="cancelInsurance">
                                Cancel Insurance Booking
                            </label>
                        </div>
                    </div>
                    {formData.ReissueType !== 1 && (
                        <div className="col-md-12">
                            <label className="form-label">Please Enter Remarks</label>
                            <textarea
                                className="form-control"
                                placeholder="Please Enter Remarks*"
                                required
                                value={formData.Remarks}
                                onChange={(e) => handleChange("Remarks", e.target.value)}
                            />
                        </div>
                    )}

                    {/*<div className="col-md-12">*/}
                    {/*    <label className="form-label">Upload Document</label>*/}
                    {/*    <input*/}
                    {/*        type="file"*/}
                    {/*        className="form-control"*/}
                    {/*        onChange={(e) => handleFileChange(e.target.files[0])}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="col-md-12">
                        <span><b>Note :</b></span>
                        <hr/>
                        {fareRules.length > 0 ? (
                            <ul className="mt-2" style={{fontSize: '13px'}}>
                                {fareRules[0] && (
                                    <li dangerouslySetInnerHTML={{__html: extractULContent(fareRules[0].FareRuleDetail)}}/>
                                )}
                            </ul>
                        ) : (
                            <p>No fare rules available.</p>
                        )}

                    </div>
                </div>
                <hr/>
                {cancelCharges?.CancellationCharge &&
                <div className={'col-md-12 text-center'}>
                    <p><b>Total Cancellation Charges: {cancelCharges?.CancellationCharge}</b></p>
                    <p><b>Total Refund Amount: {cancelCharges?.RefundAmount}</b></p>
                </div>
                }


                <div className="d-flex justify-content-center gap-3 mb-1 mt-2">
                    {formData.RequestType == 1 ? (
                        <button className="customYesBtn"
                                onClick={() => getCancelCharges(BookingId, formData.RequestType)}>
                            View Charges
                        </button>
                    ) : null}

                    {formData.RequestType == 3 && formData.ReissueType === 1 ? (
                        <a className="customYesBtn" onClick={handleSearchFlight}

                        >
                            Search
                        </a>
                    ) : (
                        <>
                            <button className="customYesBtn" onClick={handleSubmit}>
                                Send Request
                            </button>
                            <button className="customNoBtn" onClick={onClose}>
                                Cancel
                            </button>
                        </>
                    )}
                </div>

            </div>
        </Modal>
    );
};

export default CancelRequestModal;
