import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const Support = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <section className="about-banner">

                <img src="../images/about-banner.jpg" className="w-100"/>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">At X Travel World, we understand the importance of timely assistance in the fast-paced travel industry. That’s why we offer round-the-clock customer support, ensuring you always have help when you need it.
                    </h3>
                </div>
            </section>




            <section className="our-values">
                <div className="container">

                    <div className="row row-gap-3 mt-5">
                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">Why Choose Our 24/7 Support?</h5>
                                <ul className="ps-3 text-secondary listing">

                                    <li><strong>Instant Assistance: </strong>Get immediate help for any booking-related queries, technical issues, or urgent requests, no matter the time.</li>

                                    <li><strong>Expert Support:  </strong>Our dedicated support team is available 24/7 to assist you with all aspects of your travel bookings, from flight changes to hotel inquiries.</li>

                                    <li><strong>Global Reach: </strong> Whether you’re in a different time zone or working late hours, our team is ready to help, making your business operations smoother.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">How to Contact Us:</h5>
                                <ul className="ps-3 text-secondary listing">
                                    <li><strong>Email:</strong> enquiry@xtravelworld.com</li>
                                    <li ><strong>Phone:</strong> 1800-102-0340</li>
                                    <li><strong>Live Chat:   </strong>Available on our platform for real-time support</li>

                                </ul>
                                <p>We are committed to providing you with seamless service and timely solutions, so you can focus on growing your business with confidence.</p>
                            </div>
                        </div>




                    </div>
                </div>
            </section>


            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default Support;
