export const activeAgentsUrl = () => {
    return '/api/activeAgent'
}

export const regAgentUrl = () => {
    return '/agents'
}

export const uploadCertificateUrl = () => {
    return '/api/uploadCertificate'
}

export const checkCertificationTokenUrl = () => {
    return '/checkCertificationToken'
}

export const assignedAgentUrl = () => {
    return '/api/assign-agents'
}
