import React from "react";
import PhoneInput2 from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import './index.css'
import {BaseSelect as Select} from "../Select";
import countryJson from "./countryFlag.json"

let {Option} = Select
const PhoneInput = (props) => {
    let {
        value = "91", defaultCountry = "in", onChange = () => null, disabled = false, required = false,
        customClass = ""
    } = props;
    return (
        <>
            <PhoneInput2
                country={defaultCountry}
                id="basic-default-email"
                className={`${customClass}`}
                searchPlaceholder={'Search'}
                enableSearch={true}
                required={required}
                disableSearchIcon={true}
                value={value}
                onChange={(mobile, country, e, formattedValue) => {
                    onChange(mobile)
                }}
                disabled={disabled}
            />
        </>
    )
}
const PhoneInputE = (props) => {
    let {
        value = "91", defaultCountry = "in", onChange = () => null
    } = props;
    return (
        <>
            <Select className={'flag-select'}>
                {countryJson.map((item) => {
                    let label = ` ${item.emoji} ${item.dialCode} ${item.name}`;
                    return (
                        <Option value={item.dialCode}>
                            {label}
                        </Option>
                    )
                })}
            </Select>
        </>
    )
}
export default PhoneInput
