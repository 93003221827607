import React from "react";
import moment from "moment";
import {OverseasRoundTripFlightDetails} from "../roundTripFlightDetails";
import {getTotalDurationSegments} from "../../../components/utils/appUtils";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY")
}

const FlightDestination = (props) => {
    let {allSegmentData} = props;
    let firstSegment = allSegmentData[0] || {}
    let lastSegment = allSegmentData[allSegmentData.length - 1] || [];
    return (
        <>
            <div className="flight-destimation-time">
                <div className="row row-gap-3 align-items-center">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center"
                             style={{gap: '30px'}}>
                            <h6 className="m-0 white">
                                {firstSegment['Origin'] && firstSegment['Origin']['Airport'] && firstSegment['Origin']['Airport']['CityCode'] ? firstSegment['Origin']['Airport']['CityCode'] : ""}
                            </h6>
                            <span><img src="../images2/plane.png" width="22"
                                       alt="plane"/></span>
                            <h6 className="m-0 white">
                                {lastSegment['Destination'] && lastSegment['Destination']['Airport'] && lastSegment['Destination']['Airport']['CityCode'] ? lastSegment['Destination']['Airport']['CityCode'] : ""}
                            </h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        {firstSegment && firstSegment["Origin"] && firstSegment["Origin"]['DepTime'] ?
                            <p className="m-0">on {flightDate(firstSegment["Origin"]['DepTime'])}</p> : null}
                    </div>
                    <div className="col-lg-4">
                        <p className="m-0">{getTotalDurationSegments(allSegmentData)}</p>
                    </div>
                </div>
            </div>

        </>
    )
}

const OverseasFlightInfoComponent = (props) => {
    let {flightObj, selectedFlight, bookingType = '', sessionId, flightBookingType} = props;
    return (
        <>

            <div className={'mb-4 find-flight-main'}>
                <div className={'accordion-flight-track'}>
                    <div className={'row'}>
                        {selectedFlight['Segments'] ? (
                            <>
                                {selectedFlight['Segments'] && selectedFlight['Segments'][0] ?
                                    <div className={'col-md-12'}>
                                        <FlightDestination
                                            flightObj={flightObj}
                                            segments={selectedFlight['Segments']}
                                            allSegmentData={selectedFlight['Segments'][0]}/>

                                        <OverseasRoundTripFlightDetails
                                            journeyType={flightObj.journeyType}
                                            data={selectedFlight}
                                            allSegmentData={selectedFlight['Segments'][0]}
                                            bookingType={bookingType}
                                        />
                                    </div> : null}

                                {selectedFlight['Segments'] && selectedFlight['Segments'][1] ?
                                    <div className={'col-md-12'}>
                                        <FlightDestination
                                            flightObj={flightObj}
                                            segments={selectedFlight['Segments']}
                                            allSegmentData={selectedFlight['Segments'][1]}/>

                                        <OverseasRoundTripFlightDetails
                                            journeyType={flightObj.journeyType}
                                            data={selectedFlight}
                                            allSegmentData={selectedFlight['Segments'][1]}
                                            bookingType={bookingType}
                                        />
                                    </div> : null}


                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OverseasFlightInfoComponent
