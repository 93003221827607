import React, {useEffect, useState} from "react";
import {ticketListFxn, releasePnrFxn} from "./action";
import moment from "moment";
import {useDispatch} from "react-redux";
import {Tooltip, Popconfirm, Card, Empty, Pagination} from "../../components/Elements";
import {displayDate, displayDateOnly} from "../../components/utils/appUtils";
import {getAirlineLogo, userTypesObj} from "../../components/utils/appUtils";

const HoldTicket = ({user}) => {
    const dispatch = useDispatch();
    let pageSize = 10
    let [page, setPage] = useState(1);
    let [total, setTotal] = useState(0)
    const [ticketList, setTicketList] = useState([]);
    const events = {
        ticketList: async () => {
            let obj = {
                results: pageSize,
                count: pageSize,
                page: page,
                type: "holding"
            }
            let {data, total} = await dispatch(ticketListFxn(obj));
            setTotal(total)
            setTicketList(data);
        },
        releasePnr: async (bookingId, traceId, source, tokenId, ticketId) => {
            let resp = await dispatch(releasePnrFxn({
                bookingId: bookingId,
                traceId: traceId,
                source: source,
                tokenId: tokenId,
                ticketId: ticketId
            }))
            if (resp.success) {

                setTicketList(prevList =>
                    prevList.map(ticket =>
                        ticket._id === ticketId ? {...ticket, releaseStatus: 1} : ticket
                    )
                );

            }
        }
    };

    useEffect(() => {
        events.ticketList();
    }, [page]);

    return (
        <div className="row row-gap-3">
            <div className="col-lg-12">
                <div className={'d-flex align-items-center justify-content-between'}>
                    <h4 className="mb-4 ">Booking Queue - ({total})</h4>
                    <Pagination
                        page={page}
                        total={total}
                        pageSize={pageSize}
                        onSubmit={(page) => {
                            setPage(page)
                        }}/>
                </div>
                <div className="ticket-list pe-3">
                    {ticketList && ticketList.length ?
                        <div>

                            <div className={'mt-2'}>
                                {ticketList.map((ticket, index) => {
                                    let {bookingResponse} = ticket;
                                    return (
                                        <div className="track-flights" key={index}>
                                            <div
                                                className="accordion"
                                                id={`accordionPanelsStayOpenExample${index}`}
                                            >
                                                <div className="accordion-item">
                                                    <h2
                                                        className="accordion-header"
                                                        id={`panelsStayOpen-heading${index}`}
                                                    >
                                                        <ul>
                                                            <li className="p-3 head-accordion manageBookingList">
                                                                <h6 className="pnr">

                                                                    <div>
                                                                        PNR:&nbsp;
                                                                        {ticket &&
                                                                        ticket.bookingResponse &&
                                                                        ticket.bookingResponse.PNR}
                                                                        <a
                                                                            href={`/response/${ticket?._id}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className={'ms-2'}>
                                                                            <img
                                                                                src="/images2/eye.png"
                                                                                width="20"
                                                                                alt="eye-icon"
                                                                            />
                                                                        </a>
                                                                    </div>

                                                                    <small className={'d-block'}>
                                                                        Booking Date:&nbsp;
                                                                        {ticket &&
                                                                        ticket.createdAt &&
                                                                        moment(ticket.createdAt).format('DD/MMM/YY')}
                                                                    </small>
                                                                </h6>
                                                                <div className={'d-flex'}>
                                                                    <img className="airlineLogoSize"
                                                                         src={ticket &&
                                                                         ticket.bookingResponse &&
                                                                         ticket.bookingResponse.FlightItinerary &&
                                                                         ticket.bookingResponse.FlightItinerary.Segments &&
                                                                         ticket.bookingResponse.FlightItinerary.Segments.length > 0 &&
                                                                         ticket.bookingResponse.FlightItinerary.Segments[0].Airline &&
                                                                         ticket.bookingResponse.FlightItinerary.Segments[0].Airline.AirlineCode ? getAirlineLogo(ticket.bookingResponse.FlightItinerary.Segments[0].Airline.AirlineCode) : ""}
                                                                         alt=""/>
                                                                    <h6 className="name">
                                                                        {ticket &&
                                                                        ticket.bookingResponse &&
                                                                        ticket.bookingResponse.FlightItinerary &&
                                                                        ticket.bookingResponse.FlightItinerary.Segments &&
                                                                        ticket.bookingResponse.FlightItinerary.Segments.length > 0 &&
                                                                        ticket.bookingResponse.FlightItinerary.Segments[0].Airline &&
                                                                        ticket.bookingResponse.FlightItinerary.Segments[0].Airline.AirlineName &&
                                                                        ticket.bookingResponse.FlightItinerary.Segments[0].Airline.AirlineName}
                                                                        <small className="d-block">
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments.length > 0 &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments[0].Origin &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                            moment(ticket.bookingResponse.FlightItinerary.Segments[0].Origin.DepTime).format('DD/MM/YY')}
                                                                        </small>
                                                                    </h6>

                                                                </div>

                                                                <div className="d-flex align-items-center">
                                                                    <h6 style={{
                                                                        justifyContent: "center",
                                                                        textAlign: "center",
                                                                    }}>
                                                                        <small className="d-block">
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary.Origin &&
                                                                            ticket.bookingResponse.FlightItinerary.Origin}
                                                                            <br/>
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments.length > 0 &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments[0].Origin &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                            moment(ticket.bookingResponse.FlightItinerary.Segments[0].Origin.DepTime).format('HH:MM A')}
                                                                        </small>
                                                                    </h6>
                                                                    <span className="mx-4">
                                                                <img src="images2/plane.png" alt="plane"/>
                                                              </span>
                                                                    <h6
                                                                        style={{
                                                                            justifyContent: "center",
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <small className="d-block me-3">
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary.Destination &&
                                                                            ticket.bookingResponse.FlightItinerary.Destination}
                                                                            <br/>
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments.length > 0 &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments[0].Origin &&
                                                                            ticket.bookingResponse.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                            moment(ticket.bookingResponse.FlightItinerary.Segments[0].Origin.ArrTime).format('HH:MM A')}
                                                                        </small>
                                                                    </h6>
                                                                </div>
                                                                {/*<h6 className="passengerBlock d-flex">*/}
                                                                {/*    {ticket &&*/}
                                                                {/*    ticket.Passengers &&*/}
                                                                {/*    ticket.Passengers.map((passenger, index) => (*/}
                                                                {/*        <a*/}
                                                                {/*            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}*/}
                                                                {/*            target="_blank"*/}
                                                                {/*            key={index}*/}
                                                                {/*            className={'passengersStyle'}>*/}
                                                                {/*            <small className="d-block">*/}
                                                                {/*                <Tooltip*/}
                                                                {/*                    title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>*/}
                                                                {/*                    {passenger.FirstName} {passenger.LastName}*/}
                                                                {/*                    {index !== ticket.Passengers.length - 1 && ','}*/}
                                                                {/*                </Tooltip>*/}
                                                                {/*            </small>*/}
                                                                {/*        </a>*/}
                                                                {/*    ))}*/}
                                                                {/*</h6>*/}

                                                                <div className={'d-flex align-items-center'}>
                                                                    {ticket?.releaseStatus == 1 ?
                                                                        <p className="mr10 ms-2 f-14">
                                                                            Released
                                                                        </p>
                                                                        :
                                                                        <>
                                                                            {[...userTypesObj.admin, 'agent'].includes(user.userType) ?
                                                                                <Popconfirm
                                                                                    title="Are you sure you want to release this PNR?"
                                                                                    onConfirm={() => events.releasePnr(bookingResponse?.BookingId, ticket?.TraceId, bookingResponse?.FlightItinerary?.Source, ticket?.TokenId, ticket?._id)}
                                                                                    okText="Yes"
                                                                                    cancelText="No">
                                                                                    <button
                                                                                        className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}>
                                                                                        Release
                                                                                    </button>
                                                                                </Popconfirm> : null
                                                                            }

                                                                        </>
                                                                    }


                                                                    <a
                                                                        href={`/response/${ticket?._id}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className={'btn btn-primary fw-semibold buttonFontSize gradient-button ms-2'}>

                                                                        Open
                                                                    </a>


                                                                    <p className="mr10 ms-2 f-14">
                                                                        PNR Limit Time: <br/>
                                                                        <span style={{color: 'red'}}
                                                                              className={'none-span'}>{displayDate(ticket?.fareQuote?.LastTicketDate)}</span>
                                                                    </p> <br/>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </h2>
                                                    <div
                                                        id={`panelsStayOpen-collapse${index}`}
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby={`panelsStayOpen-heading${index}`}
                                                    >
                                                        <div className="accordion-body">
                                                            <ul>
                                                                <li className="manageBookingList">
                                                                    <h6>
                                                                        {ticket &&
                                                                        ticket.Passengers &&
                                                                        ticket.Passengers.length
                                                                            ? ticket.Passengers.length
                                                                            : 0}{" "}
                                                                        <small className="d-block">Passengers</small>
                                                                    </h6>
                                                                    <h6>
                                                                        {ticket &&
                                                                        ticket.bookingResponse &&
                                                                        ticket.bookingResponse.FlightItinerary &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments[0] &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments[0].Origin &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments[0].Origin.DepTime &&
                                                                        moment(
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Origin.DepTime
                                                                        ).format("DD MMM, YY")}
                                                                        <small className="d-block">Departure</small>
                                                                    </h6>
                                                                    <h6>
                                                                        Departure Time{" "}
                                                                        <small className="d-block">
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0] &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Origin &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Origin.DepTime &&
                                                                            moment(
                                                                                ticket.bookingResponse.FlightItinerary
                                                                                    .Segments[0].Origin.DepTime
                                                                            ).format("HH:mm A")}
                                                                        </small>
                                                                    </h6>
                                                                    <h6>
                                                                        {ticket &&
                                                                        ticket.bookingResponse &&
                                                                        ticket.bookingResponse.FlightItinerary &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments[0] &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments[0].Destination &&
                                                                        ticket.bookingResponse.FlightItinerary
                                                                            .Segments[0].Destination.ArrTime &&
                                                                        moment(
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Destination.ArrTime
                                                                        ).format("DD MMM, YY")}
                                                                        <small className="d-block">Arrival</small>
                                                                    </h6>
                                                                    <h6>
                                                                        Arrival Time{" "}
                                                                        <small className="d-block">
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0] &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Destination &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Destination.ArrTime &&
                                                                            moment(
                                                                                ticket.bookingResponse.FlightItinerary
                                                                                    .Segments[0].Destination.ArrTime
                                                                            ).format("HH:mm A")}
                                                                        </small>
                                                                    </h6>
                                                                    <h6>
                                                                        Class:{" "}
                                                                        <small className="d-block">Business</small>
                                                                    </h6>
                                                                    <h6>
                                                                        Journey Time:{" "}
                                                                        <small className="d-block">
                                                                            {ticket &&
                                                                            ticket.bookingResponse &&
                                                                            ticket.bookingResponse.FlightItinerary &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments &&
                                                                            ticket.bookingResponse.FlightItinerary
                                                                                .Segments[0].Duration &&
                                                                            `${Math.floor(
                                                                                ticket.bookingResponse.FlightItinerary
                                                                                    .Segments[0].Duration / 60
                                                                            )}h ${
                                                                                ticket.bookingResponse.FlightItinerary
                                                                                    .Segments[0].Duration % 60
                                                                            }m`}
                                                                        </small>
                                                                    </h6>
                                                                </li>
                                                                {/* <div
                                                            className="d-flex flex-row"
                                                            style={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "8px",
                                                                padding: "10px",
                                                                width: "fit-content",
                                                            }}
                                                        >
                                                            <h6 className={'mt10'}>Passengers:</h6>
                                                            {ticket && ticket.Passengers && (
                                                                <small className="btn btn-link">
                                                                    {ticket.Passengers.map((passenger, idx) => (
                                                                        <a
                                                                            className={idx > 0 ? 'mt10' : ''}
                                                                            key={idx}
                                                                            // href={`/ticket-details?bookingId=${ticket?.bookingResponse?.Response?.BookingId}&pnr=${ticket?.bookingResponse?.Response?.PNR}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}&traceId=${ticket?.TraceId}`}
                                                                            // target="_blank"
                                                                            // rel="noopener noreferrer"
                                                                            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                            target="_blank"
                                                                        >
                                                                            {idx + 1}. {passenger.FirstName} {passenger.LastName}
                                                                            <br/>
                                                                        </a>

                                                                    ))}
                                                                </small>
                                                            )}

                                                        </div>*/}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div> :
                        <div>
                            <Empty title={'No Records Found.'}/>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default HoldTicket;
