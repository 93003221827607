import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom"; // Import NavLink
import {unizportalUrl} from "../../settings";
// import blackHeader from "../../assets/img/black-header.png"

const Header = (props) => {
    let {currentUser} = props;
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <header>
                {/*<img src={blackHeader} className={'header-main'}/>*/}
                <div className={'header-main header-black'}/>
                <nav className={`navbar navbar-expand-md navbar-dark fixed-top ${isScrolled ? "fixed-header" : ""}`}>
                    <div className="container">
                        <NavLink className="navbar-brand" to="/">
                            <img src="../images/logo.png" alt="logo" width="200"/>
                        </NavLink>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="navbar-collapse collapse" id="navbarCollapse">
                            <ul className="navbar-nav mx-auto mb-2 mb-md-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/home" href="#" activeClassName="active"
                                             exact>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/agents" activeClassName="active">Agents</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about-us" activeClassName="active">About
                                        Us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/events" activeClassName="active">Events</NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact-us" activeClassName="active">Contact
                                        Us</NavLink>
                                </li>
                            </ul>
                            <div className="header-btn-group">
                                {/* <a href={unizportalUrl} className="btn switch-portal" target={'_blank'}>Switch
                                    to <b>UnizPortal</b></a>*/}
                                <div className="flex-btns">
                                    {currentUser && currentUser.name ?
                                        <ul className="navbar-nav mx-auto mb-2 mb-md-0 mt-0">
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active"
                                                         to="/ticket-list">Welcome {currentUser.name}</NavLink>
                                            </li>
                                        </ul> :
                                        <ul className="navbar-nav mx-auto mb-2 mb-md-0 mt-0">
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active"
                                                         to="/login">Login</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active"
                                                         to="/register">Register</NavLink>
                                            </li>
                                        </ul>}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
