import React, {useEffect} from 'react'
import {apiUrl, apiUrl2} from './settings'
import {connect} from 'react-redux'
import {useDispatch, useSelector} from 'react-redux'
import {push} from 'connected-react-router'
import {getUserToken, getUserData} from './request'
import IO from 'socket.io-client'

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
// let token = localStorage.getItem('token') ? localStorage.getItem('token') : null

let Socket = IO(apiUrl, {
    secure: false,
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000
})

let Socket2 = IO(apiUrl2, {
    secure: false,
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000
})

if (!Socket.connected) {
    console.log('connct attempted')
    Socket.connect()
}

Socket.on('connect', async () => {
    console.log('conneciton succssful')
    reAuthenticate()
})

Socket.on('disconnect', function () {
    if (user && user._id) {
        Socket.emit('discountUser', user._id)
    }
})

Socket.on('authenticated', () => {
})

Socket.on('unauthorized', () => {
})

Socket.on('connect_error', err => {
    //  socket.disconnect()
    console.log('on disconecced socket', err)
})

Socket.on('connect_failed', err => {
})

Socket.on('got_back', () => {
})


if (!Socket2.connected) {
    console.log('connct 2 attempted')
    Socket2.connect()
}

Socket2.on('connect', async () => {
    console.log('connection 2 successfully')
    reAuthenticate2()
})

Socket2.on('disconnect', function () {
    if (user && user._id) {
        console.log('disconnect 2 successfully')
        Socket2.emit('discountUser', user._id)
    }
})


const reAuthenticate = async () => {
    let userToken = await getUserToken()
    let currentUser = await getUserData()
    if (userToken && Socket.connected && currentUser && currentUser._id) {
        Socket.emit('authenticate', {userToken, userId: currentUser._id})
    }
}
const reAuthenticate2 = async () => {
    let userToken = await getUserToken()
    let currentUser = await getUserData()
    if (userToken && Socket2.connected && currentUser && currentUser._id) {
        Socket2.emit('authenticate', {userToken, userId: currentUser._id})
    }
}


const SocketComponentMain = (props) => {
    const dispatch = useDispatch()


    useEffect(() => {
        Socket.on('reload notification', (data) => {
            // console.log(data, 'notification')

            dispatch({
                type: 'RELOAD_NOTIFICATION',
                value: true,
                socketData: data
            })

        })

        Socket2.on('refresh_user_balance', (data) => {
            dispatch({type: "LOAD_USER_BALANCE", value: true})
            setTimeout(() => {
                dispatch({type: "LOAD_USER_BALANCE", value: false})
            }, 1000)
        })


    }, [])


    return null

}


const mapStateToProps = ({counter, global}) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const SocketComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(SocketComponentMain)

export {SocketComponent, Socket, Socket2}


