import React, {useState} from "react";
import Slider from "react-slick";
import DownloadApplications from "../components/pageComponents/downloadApplications";
import Reviews from "../components/pageComponents/reviews";
import RegisterBanner from "../components/pageComponents/registerBanner";

const Agents = () => {

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        contactNumber: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send formData to your API
    };

    return (
        <>
            {/* Begin page content */}
            <section className="about-banner">
                <div className="spacing-60">
                    <div className="container">
                        <div className="col-lg-10 mx-auto col-12">
                            <div className="row row-gap-3 align-items-center">
                                <div className="col-lg-6 col-12">
                                    <h2 className="m-0">
                                        Unlock a World of Opportunities as a Partner Agent
                                    </h2>
                                </div>
                                <div className="col-lg-5 ms-auto col-12">
                                    <p className="m-0">
                                       Designed for travel agents, tour operators, and distributors to maximize business potential.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="form-section">
                <img
                    src="/images/agent-banner.jpg"
                    className="banner-login"
                />
                <div className="container">
                    <div className="row row-gap-3">
                        <div className="col-lg-5 col-md-6 col-12 me-auto">
                            <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                <div className="main-head mb-3">
                                    <h5 style={{color: "#d71a21"}}>
                                        Sign Up and Elevate Your Travel Business
                                    </h5>
                                    <p className="m-0">X Travel World’s agent portal is built to streamline your operations and increase profitability. As a registered partner </p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Contact"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">
                                        REGISTER
                                    </button>
                                    <div className="mt-2 d-flex flex-wrap gap-3">
                                        <p className="m-0">
                                            Join X Travel World and unlock exclusive access to premium flight and hotel deals designed specifically for B2B travel professionals.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">
                        Register as a partner today and experience the difference with X Travel World.
                    </h3>
                </div>
            </section>

            <section className="our-values spacing-60 pb-0">
                <div className="container">
                    <div className="head text-center mb-5">
                        <h3>Why Partner with X Travel World?</h3>
                    </div>

                    <div className="row row-gap-3">
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <p className="m-0">
                                    Exclusive B2B Deals
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <p className="m-0">User-Friendly Platform</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <p className="m-0">
                                    Dedicated Support
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <p className="m-0">Real-Time Inventory Updates</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <p className="m-0">
                                    Customizable Solutions
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <p className="m-0">
                                    Incentive Programs and Rewards
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DownloadApplications/>

            <Reviews/>

            <RegisterBanner/>

        </>
    );
};

export default Agents;
