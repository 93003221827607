import React from "react"
import airPlane from "../../assets/imgs/airplane.png"

const PlaneSliderComponent = () => {
    return (
        <>
            <div style={{position: "relative"}}>
                <div className="slider-container">
                    <div className="progress-container">
                        <div className="progress-bar"></div>
                    </div>
                </div>
                <div className="fly-container">
                    <img src={airPlane} alt="Airplane" className="airplane" style={{height: 75, width: "auto"}}/>
                </div>
            </div>
        </>
    )
}
export default PlaneSliderComponent
