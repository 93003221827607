import React from "react"
import {Modal} from "../../components/Elements";

const ChangePrice = (props) => {
    let {visible, onClose, oldFare, newFare, quoteFare, onSubmit} = props;
    return (
        <Modal visible={visible} width={'38%'} onClose={onClose}
               maskClosable={false}
               closable={false}
               title={'Flight details have been changed, Please verify the changed Price before proceed to booking. '}>
            <div className={'popup p-3'}>
            <table className={'table table-border'}>
                <tr>
                    <th></th>
                    <th>Published Fare</th>
                    {/*<th>Offered Fare</th>*/}
                </tr>
                <tr>
                    <td>New Price</td>
                    <td>{newFare['newFare']}</td>
                    {/*<td>{newFare['OfferedFare']}</td>*/}
                </tr>
                <tr>
                    <td>Old Price</td>
                    <td>{oldFare['newFare']}</td>
                    {/*<td>{oldFare['OfferedFare']}</td>*/}
                </tr>

                <tr>
                    <td>(* TDS Included)</td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
            <br/>
            <div>Do you want to proceed further?</div>
            <div className="d-flex justify-content-center gap-3 mb-1 mt-2">
                <button className="customYesBtn" onClick={() => onSubmit(quoteFare)}>Accept</button>
                <button className="customNoBtn" onClick={onClose}>Choose Another Fare</button>
            </div>
          </div>

        </Modal>
    )
}
export default ChangePrice
