import React, {useEffect} from "react"

let $ = window.$;

const ExclusiveDeals = () => {
    useEffect(() => {
        const initializeSlick = () => {
            try {
                $('.offers-box').not('.slick-initialized').slick({
                    dots: false,
                    speed: 300,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            } catch (e) {

            }
        };

        setTimeout(() => {
            initializeSlick();
        }, 10)


        return () => {
            if ($('.offers-box').hasClass('slick-initialized')) {
                $('.offers-box').slick('unslick'); // Destroy Slick instance
            }
        };
    }, []);

    return (
        <>
            <section className="deal-offer spacing-60 pb-0">
                <div className="container">
                    <div className="head-1 text-center mb-lg-5 mb-4">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <h3 className="mb-3">
                                    <img src="images2/planeNew.png" alt="plane" width={28}/>{" "}
                                    Exclusive Deals &amp; Offers{" "}
                                    <img src="images2/planeNew.png" alt="plane" width={28}/>
                                </h3>
                                <p className="m-0">
                                    Unlock unbeatable rates with X Travel World! Our platform offers exclusive deals on flights and hotels, available only to our B2B partners. With direct access to discounted prices and special promotions, you can provide your clients with exceptional value while maximizing your business profits.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slides">
                        <div className="offers-box">
                            <div>
                                <div className="content">
                                      <span className="box">
                                        <img
                                            src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                            alt="dummy-image"
                                        />
                                        <a href="#" className="btn">
                                          View Detail
                                        </a>
                                      </span>
                                    <h6>
                                        Copenhagen <span>Rs. 25266</span>
                                    </h6>
                                </div>
                            </div>
                            <div>
                                <div className="content">
              <span className="box">
                <img
                    src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="dummy-image"
                />
                <a href="#" className="btn">
                  View Detail
                </a>
              </span>
                                    <h6>
                                        Copenhagen <span>Rs. 25266</span>
                                    </h6>
                                </div>
                            </div>
                            <div>
                                <div className="content">
              <span className="box">
                <img
                    src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="dummy-image"
                />
                <a href="#" className="btn">
                  View Detail
                </a>
              </span>
                                    <h6>
                                        Copenhagen <span>Rs. 25266</span>
                                    </h6>
                                </div>
                            </div>
                            <div>
                                <div className="content">
              <span className="box">
                <img
                    src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="dummy-image"
                />
                <a href="#" className="btn">
                  View Detail
                </a>
              </span>
                                    <h6>
                                        Copenhagen <span>Rs. 25266</span>
                                    </h6>
                                </div>
                            </div>
                            <div>
                                <div className="content">
              <span className="box">
                <img
                    src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="dummy-image"
                />
                <a href="#" className="btn">
                  View Detail
                </a>
              </span>
                                    <h6>
                                        Copenhagen <span>Rs. 25266</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <a href="#" className="btn btn-primary rounded-pill px-4">
                            View All
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ExclusiveDeals
