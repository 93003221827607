import React, {useState, useRef, useEffect, useCallback} from "react";
import _ from "lodash";
import {CabinObject, CabinTypeOptions} from "../../../components/utils/appUtils";
import TravellersPickerComponent from "../commonComponents/travellersPicker";
/*
const SinglePassTypeComponent = (props) => {
    let {noOfPass, name, message, value, onSelect} = props;
    return (
        <div className={'mb-4'}>
            <div className={'traveller-title'}>
                <b>{name}</b> ({message})<br/>
                {/!*on the day of travel*!/}
            </div>
            <div className={'d-flex'} style={{gap: 10}}>
                <div className={'btn-row'}>
                    {_.times(noOfPass, (item) => {
                        let count = item;
                        return (
                            (name !== "Adult" || count !== 0) &&
                            <div className={`btn-row-box ${value == count ? "selected" : ""}`} onClick={() => {
                                onSelect(count)
                            }}>
                                {count}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
const TravellersPickerComponent = (props) => {
    let {state, onConfirm} = props;
    let [localState, setLocalState] = useState(state);
    const events = {
        _updateState: (data) => {
            setLocalState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        }
    }

    return (
        <>
            <div className={'travellers_picker'}>

                <div className={'row'}>
                    <div className={'col-lg-5 col-12'}>
                        <SinglePassTypeComponent
                            name={'Adult'}
                            message={'12y +'}
                            noOfPass={10}
                            value={localState.adultCount}
                            onSelect={(value) => {
                                events._updateState({adultCount: value})
                            }}
                        />
                    </div>
                    <div className={'col-lg-7 col-12'}>
                        <div className={'row'}>
                            <div className={'col-lg-6 col-12'}>
                                <SinglePassTypeComponent
                                    name={'Children'}
                                    message={'2y - 12y'}
                                    noOfPass={6}
                                    value={localState.childCount}
                                    onSelect={(value) => {
                                        events._updateState({childCount: value})
                                    }}
                                />
                            </div>
                            <div className={'col-lg-6 col-12'}>
                                <SinglePassTypeComponent
                                    name={'Infants'}
                                    message={'below 2y'}
                                    noOfPass={6}
                                    value={localState.infantCount}
                                    onSelect={(value) => {
                                        events._updateState({infantCount: value})
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className={'row'}>
                    <div className={'col-lg-7 col-12'}>
                        <div className={'traveller-title'}>
                            <b>Choose Travel Class</b>
                        </div>
                    </div>
                    <div className={'col-lg-7 col-12'}>
                        <div className={'btn-traveller-row mb-4'}>
                            {CabinTypeOptions.map((item) => {
                                return (
                                    item.display !== 'Any' &&
                                    <div
                                        className={`btn--cabin-row-box ${localState.cabinClass == item.id ? "selected" : ""}`}
                                        onClick={() => {
                                            events._updateState({cabinClass: item.id})
                                        }}
                                        key={item.id}>
                                        {item.display}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={'col-lg-3 offset-lg-2 col-12'}>
                        <a className={'btn btn-primary apply-btn'} onClick={() => {
                            onConfirm(localState)
                        }}>
                            <b>Apply</b>
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
*/


const TravellersComponent = (props) => {
    let {state, onChange} = props;
    const [visibleTypehead, setVisibleTypehead] = useState(false);
    const containerRef = useRef(null);

    // Click outside handler
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleTypehead(false);
        }
    }, []);

    useEffect(() => {
        if (visibleTypehead) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visibleTypehead, handleClickOutside]);

    const getNoOfPass = () => {
        let total = (state.adultCount || 0) + (state.childCount || 0) + (state.infantCount || 0)
        return total
    }

    return (
        <div ref={containerRef} className="content" onClick={() => setVisibleTypehead(true)}>
            <span>Travellers & Class</span>
            <h5>{getNoOfPass()} Travellers</h5>
            <small>{CabinObject[state.cabinClass]}</small>

            {visibleTypehead && (
                <TravellersPickerComponent
                    state={state}
                    onConfirm={(obj) => {
                        onChange(obj)
                        setTimeout(() => setVisibleTypehead(false), 100);
                    }}
                />
            )}
        </div>
    );
};

export default TravellersComponent;
