import React, {useEffect, useState} from "react"
import {Card, Modal} from "../../../components/Elements";
import _ from "lodash";
import {getFlightFearRuleFxn} from "../../flightSearch/actions";
import FareRuleComponentInner from "./fareRuleComponentInner";
const FareRuleModal = (props) => {
    let {flight, bookingType, traceId} = props;
    let [fareRuleData, setFareRuleData] = useState("")
    let [isLoading, setIsLoading] = useState(false)
    let {visible, onClose} = props;
    useEffect(() => {
        events.getFareRuleFxn()
    }, [])
    const events = {
        getFareRuleFxn: async (e) => {
            let obj = {
                traceId,
                resultIndex: flight['ResultIndex'],
                bookingType
            }
            setIsLoading(true)
            let {success, data: fareRuleData} = await getFlightFearRuleFxn(obj);
            setFareRuleData(fareRuleData)
            setIsLoading(false)
        }
    }

    return (
        <Modal visible={visible} onClose={onClose}>
            {isLoading ? <div className={'p-4 align-center'}>
                Loading ...
            </div> : null}

            <FareRuleComponentInner fareRuleData={fareRuleData}/>
        </Modal>
    )
}


const FareRuleComponent = (props) => {
    let {children} = props;
    let [visible, setVisible] = useState(false)
    return (
        <>
        <span onClick={() => {
            setVisible(true)
        }}>
            {children}
        </span>
            {visible ? <FareRuleModal {...props} visible={visible} onClose={() => {
                setVisible(false)
            }}/> : null}
        </>
    )
}

export default FareRuleComponent
