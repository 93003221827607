import {Api_Url2, XTravel_Url} from "../../../api";

export const addPaymentUrl = () => {
    return XTravel_Url + "/x-travel-world/save-payment"
}
export const singlePaymentUrl = () => {
    return Api_Url2 + "/x-travel-world/single-payment"
}
export const updatePaymentUrl = () => {
    return XTravel_Url + "/x-travel-world/update-payment"
}
export const ccAvenuePaymentUrl = (id) => {
    return XTravel_Url + `/x-travel-cc-avenue-payment/${id}`
}
export const ccAvenueSuccessUrl = () => {
    return XTravel_Url + `/x-travel-payment-success`
}
export const ccAvenueCancelUrl = () => {
    return XTravel_Url + `/x-travel-payment-cancel`
}
