import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {addPaymentFxn, ccAvenuePaymentFxn} from "../payment/action";
import ReactHtmlParser from "react-html-parser";
import {getWalletBalanceFxn} from "../flightSearch/actions";
import {Socket2} from "../../socket";

const DirectDepositAmount = (props) => {
    let {user} = props;
    let [walletBalance, setWalletBalance] = useState(0)
    let dispatch = useDispatch()


    let [ccForm, setCcForm] = useState("")
    useEffect(() => {
        events.loadParams()
        events.loadBalance()

    }, [])
    const events = {
        loadParams: () => {
            const params = new URLSearchParams(window.location.search);
            let amount = params.get("amount");
            events.addPayment(amount)
        },
        addPayment: async (amount) => {
            let obj = {
                amount: amount,
                paymentBy: "CCAvenue",
                email: user.email,
                name: user.name,
                phoneNo: `${user.countryCode || ""} ${user.mobile || ""}`,
                paymentFrom: "ticketing"
            }
            let resp = await dispatch(addPaymentFxn(obj));
            if (resp && resp.data) {
                events.loadPayment(resp.data._id)
            }
        },
        loadPayment: async (id) => {
            let respForm = await dispatch(ccAvenuePaymentFxn(id));
            setCcForm(respForm)
        },
        loadBalance: async () => {
            let {success, xTravelWallet, data} = await dispatch(getWalletBalanceFxn());
            if (success) {
                setWalletBalance(xTravelWallet);
            }
        },
    }


    useEffect(() => {
        if (ccForm) {
            const form = document.getElementById("nonseamless");
            form.submit()
        }
    }, [ccForm])

    return (
        <>

            <div className={'m-4'}>
                {user && user._id ?
                    <div className={'custom-pay-card'}>
                        <div className="row row-gap-3">
                            <div className="col-lg-12 col-12 text-center">
                                <div>
                                    <h6>Your Current Balance</h6>
                                    <h5><b>Rs. {walletBalance} </b></h5>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <h5>
                                    Processing...
                                </h5>
                            </div>
                        </div>
                        {ccForm ? ReactHtmlParser(ccForm) : null}

                    </div>
                    :
                    <div className={'custom-pay-card'}>
                        <div className="row row-gap-3">
                            <div className="col-lg-12 col-12 text-center">
                                <br/>
                                <br/>
                                <br/>
                                <h5>
                                    Processing...
                                </h5>
                            </div>
                        </div>
                        {ccForm ? ReactHtmlParser(ccForm) : null}

                    </div>

                }
            </div>
        </>
    )
}
export default DirectDepositAmount
