import lodash from 'lodash'
import React from 'react'
import CountryJson from '../../assets/jsonFiles/countries'
import CityJson from '../../assets/jsonFiles/indiaCities.json'
import StateJson from '../../assets/jsonFiles/states'

const LoadAllCountry = () => {
    return CountryJson
}

export const Cities = [...CityJson]

const LoadState = countryId => {
    if (countryId) {
        let filterArr = lodash.filter(StateJson, item => {
            return item.country_id == countryId;
        });
        return filterArr;
    } else {
        return StateJson;
    }
};


const LoadCity = stateId => {
    if (stateId) {
        let filterArr = lodash.filter(CityJson, item => {
            return item.state_id == stateId
        })
        return filterArr
    } else {
        return CityJson
    }
}


export {
    CountryJson,
    LoadAllCountry,
    LoadCity,
    LoadState,
}
