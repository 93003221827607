import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { recentSearchFlightsFxn } from "../actions";
import moment from "moment";
import { fareTypeList } from "../../../components/utils/appUtils";

const LastSearchFlightComponent = (props) => {
    let { onClick } = props;
    const dispatch = useDispatch();
    const [recentSearches, setRecentSearches] = useState([]);

    useEffect(() => {
        loadSearchData();
    }, []);

    const loadSearchData = async () => {
        let params = {
            sortField: 'createdAt',
            sortOrder: 'desc'
        }
        let { data } = await recentSearchFlightsFxn(params);
        setRecentSearches(data);
    };


    const handleSearchFlight = (searchData) => {
        dispatch({ type: "SEARCH_FLIGHT", flightObj: searchData });
        onClick(searchData);
    };

    const getFareTypeName = (value) => {
        const fareType = fareTypeList.find((fare) => fare.value === value);
        return fareType ? fareType.name : "Unknown Fare";
    };

    return (
        <>
            <div className="tab-head">
                <h6 className="m-0">Last Searches</h6>
            </div>

            {recentSearches.length === 0 ? (
                <p>No recent searches available.</p>
            ) : (
                recentSearches.map((search, index) => (
                    <div className="track-flights" key={index}>
                        <div className="accordion" id={`accordionExample${index}`}>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading${index}`}>
                                    <ul className={'d-flex flex-wrap'}>
                                        <li className="p-3 head-accordion manageBookingList">
                                            <h6 className="pnr">
                                                <div className={'fw-semibold'}>
                                                    {search.originCity} ({search.origin}) → {search.destinationCity} ({search.destination})
                                                </div>
                                                <small className={'d-block'}>
                                                    Departure Date: {moment(search.preferredDepartureTime).format('DD/MMM/YY')}
                                                </small>
                                            </h6>
                                            <h5 className={'ConfirmedStyle'}>
                                                {search.journeyType == "2" ? "Round Trip" : "One Way"}
                                            </h5>

                                            <ul className={'cabinClassDiv'}>
                                                <div>
                                                    <h6>
                                                        Travellers: {search.adultCount + search.childCount + search.infantCount}
                                                        <small className={'d-block'}>
                                                            {getFareTypeName(search.resultFareType)}
                                                        </small>
                                                    </h6>
                                                </div>
                                            </ul>
                                            <div className="d-flex flex-wrap align-items-center">
                                                <h6 style={{ justifyContent: "center", textAlign: "center" }}>
                                                    <small className="d-block">
                                                        Departure: <br/><b>{moment(search.preferredDepartureTime).format('HH:mm A')}</b>
                                                    </small>
                                                </h6>
                                                <span className="mx-4">
                                                    <img src="images2/plane.png" alt="plane"/>
                                                </span>
                                                <h6 style={{ justifyContent: "center", textAlign: "center" }}>
                                                    <small className="d-block">
                                                        {search.journeyType === "2" && search.preferredReturnTime ? (
                                                            <>Return: <br/><b>{moment(search.preferredReturnTime).format('HH:mm A')}</b></>
                                                        ) : (
                                                            "One Way"
                                                        )}
                                                    </small>
                                                </h6>
                                            </div>

                                            <ul className={'lowerButtonUl flex-wrap'}>
                                                <li className="p-3 head-accordion manageBookingList">
                                                    <button
                                                        className={'btn btn-primary fw-semibold buttonFontSize gradient-button lg'}
                                                        onClick={() => handleSearchFlight(search)}
                                                    >
                                                        Search Again
                                                    </button>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </h2>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default LastSearchFlightComponent;
