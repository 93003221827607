import React, {useState} from "react";
import DownloadApplications from "../../../components/pageComponents/downloadApplications";
import {recoverPassword, resetPasswordFxn} from "../action";
import {Form, notification} from "../../../components/Elements";
import {useDispatch} from "react-redux";
import {CustomFormFields} from "../../../components/utils/customFormUtils";
import {push} from "connected-react-router";
const changePasswordInitState = {

    password: "",
    confirmPassword:""
}
const ResetPassword = (props) => {


    let {form} = props;
    let dispatch = useDispatch()
    const [state, setState] = useState(changePasswordInitState);
    let inputField = {
        fields: [

            {
                label: 'Enter new password',
                key: 'password',
                type: 'password',
                placeholder: 'Enter new password',
                required: true
            },
            {
                label: 'Enter confirm password',
                key: 'confirmPassword',
                type: 'password',
                placeholder: 'Enter confirm password',
                required: true
            }
        ]
    }
    const events = {
        updatePassState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        submitFinal : async () => {

            let {pathname} = window.location
            let token = pathname.split('/').pop()

            let {password, confirmPassword} = state
            if (!password) {
                notification.warning({message: 'Enter new password.'})
                return
            }
            if (password && password.length < 6) {
                notification.warning({message: 'Enter minimum 6 digit password.'})
                return
            }
            if (!confirmPassword) {
                notification.warning({message: 'Enter confirm password.'})
                return
            }
            if (password !== confirmPassword) {
                notification.warning({message: 'Confirm Password not match, enter correct password.'})
                return
            }
            let resp = await dispatch(resetPasswordFxn({password, confirmPassword, token}))
            if (resp && resp.success) {
                setState({
                    password: '',
                    confirmPassword: ''
                })
                window.location.href = '/login';
            }

        }
    }
    return (
      <>
          <section className="form-section">
              <img src="../images/forgot-banner.jpg" className="banner-login"/>
              <div className="container">
                  <div className="row row-gap-3">
                      <div className="col-lg-7 me-auto">
                          <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                              <div className="head mb-4">
                                  <h3 className="mb-2">Reset Password</h3>
                          <Form onSubmit={events.submitFinal}>

                              {inputField.fields.map((item) => {
                                  return (
                                      <div className={item.span}>
                                          <CustomFormFields style={{textAlign:'left'}}
                                              item={{
                                                  size: 'small',
                                                  ...item,
                                                  required: item.required,
                                                  value: state && state[item.key] ? state[item.key] : "",
                                                  onChange: (value) => {
                                                      events.updatePassState({[item.key]: value})
                                                  }
                                              }}/>
                                      </div>
                                  )
                              })}
                              <button type="submit" className="btn btn-primary mt-3">
                                  UPDATE
                              </button>
                          </Form>
                      </div>
                  </div>

                      </div>
                  </div>
              </div>
          </section>
      </>




    );
};
const WrappedLogin = Form.create()(ResetPassword)
export default WrappedLogin
// export default ForgotPassword;
