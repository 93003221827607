import React, {useEffect, useState} from "react";
import _ from "lodash";
import CountryJson from "../../assets/jsonFiles/countries.json";
import {useDispatch} from "react-redux";
import {Form, Icon, Input, Tooltip} from "../../components/Elements";
import moment from "moment";
import {LoadState, LoadCity, LoadAllCountry} from "../../components/utils/countryUtil";
import {getUser, getUserData, updateUser} from "../authications/action";

const initState = {
    name: "",
    mobile: "",
    companyName: "",
    address: "",
    pincode: "",
    allBranch: [],
    logo: {},
    uploadKey: moment(),
    allCountries: LoadAllCountry(),
    allStates: [],
    allCities: [],
    countryName: "",
    stateName: "",
    cityName: "",
    email: "",
    certificate: {},
    newCertificate: {},
    oldLogo: {},
};

const AgentProfile = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initState);
    console.log(state, 'state')
    const handleChange = (e) => {
        const {name, value} = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: name === "name" ? value.replace(/[^a-zA-Z]/g, "") : value,
        }));
    };

    const events = {
        chooseCountry: (name) => {
            let country = _.find(state.allCountries, (item) => item.name === name);
            if (country) {
                setState((prevState) => ({
                    ...prevState,
                    countryName: country.name || "",
                    stateName: "",
                    cityName: "",
                    allStates: country.id ? LoadState(country.id) : [],
                    allCities: [],
                }));
            }
        },
        chooseState: (name) => {
            let stateData = _.find(state.allStates, (item) => item.name === name);
            if (stateData) {
                setState((prevState) => ({
                    ...prevState,
                    stateName: stateData.name || "",
                    cityName: "",
                    allCities: stateData.id ? LoadCity(stateData.id) : [],
                }));
            }
        },
        chooseCity: (name) => {
            setState((prevState) => ({
                ...prevState,
                cityName: name || "",
            }));
        },
        getUserData: () => {
            const userData = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
            if (userData && userData._id) {
                dispatch(getUserData(userData._id)).then((resp) => {
                    if (resp) {
                        if (resp.countryName) {
                            events.chooseCountry(resp.countryName);
                        }
                        if (resp.stateName) {
                            events.chooseState(resp.stateName);
                        }
                        setTimeout(() => {
                            setState((prevState) => ({
                                ...prevState,
                                name: resp.name,
                                mobile: resp.mobile,
                                email: resp.email,
                                userType: resp.userType,
                                address: resp.address,
                                countryName: resp.countryName,
                                companyName: resp.companyName,
                                stateName: resp.stateName,
                                cityName: resp.cityName,
                                pincode: resp.pincode || undefined,
                                oldLogo: resp.logo,
                                userId: resp._id,
                            }));
                        }, 500);
                    }
                });
            }
        },
        handleSubmit: async () => {
            let obj = {
                userType: 'agent',
                address: state.address,
                countryName: state.countryName,
                stateName: state.stateName,
                cityName: state.cityName,
                pincode: state.pincode,
                email: state.email
            }
            let fd = new FormData()
            fd.append('obj', JSON.stringify(obj))
            if (state.logo && state.logo.name) {
                fd.append('logo', state.logo)
            }
            let {data} = await dispatch(updateUser(fd, state.userId))
            if (data && !data.error) {
                let userData = JSON.parse(localStorage.getItem('user'))
                userData.logo = data.logo
                localStorage.setItem('user', JSON.stringify(userData))
              /*  setState({
                    ...initState
                })*/
            }
        }

    };

    useEffect(() => {
        events.getUserData();
    }, []);

    return (
        <div className="row row-gap-3">
            <div className="col-lg-12 me-auto">
                <div className={"p-4"}>
                    <h3>Update Profile</h3>
                    <hr/>
                    <Form>
                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <label className="form-label">Contact Person Name*:</label>
                                <Input type="text"
                                       className="form-control"
                                       required value={state.name}
                                       disabled/>
                            </div>
                            <div className="col-lg-4 col-12">
                                <label className="form-label">Phone Number *</label>
                                <Input type="tel"
                                       className="form-control"
                                       required value={state.mobile}
                                       disabled/>
                            </div>
                            <div className="col-lg-4 col-12">
                                <label className="form-label">Company Name *</label>
                                <Input type="text"
                                       className="form-control"
                                       required value={state.companyName}
                                       disabled/>
                            </div>
                            <div className="col-lg-4 col-12">
                                <label className="form-label">Country *</label>
                                <select className="form-control"
                                        onChange={(e) => events.chooseCountry(e.target.value)}
                                        style={{height: '52px'}}
                                        required value={state.countryName}
                                >
                                    <option value="" disabled>
                                        Choose Country
                                    </option>
                                    {CountryJson.map((item, key) => (
                                        <option value={item.name} key={key}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-12">
                                <label className="form-label">State *</label>
                                <select className="form-control"
                                        style={{height: '52px'}}
                                        onChange={(e) =>
                                            events.chooseState(e.target.value)}
                                        required
                                        value={state.stateName}>
                                    <option value=""
                                            disabled>
                                        Choose State
                                    </option>
                                    {state.allStates.map((item, key) => (
                                        <option value={item.name} key={key}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-12">
                                <label className="form-label">City *</label>
                                <Input type="text"
                                       className="form-control"
                                       required placeholder="Enter City"
                                       value={state.cityName}
                                       onChange={(e) => events.chooseCity(e)}/>
                            </div>
                            <div className="col-lg-4 col-12">
                                <label className="form-label">Postal Code *</label>
                                <Input type="number"
                                       className="form-control"
                                       required value={state.pincode}
                                       name="pincode"
                                       onChange={handleChange}/>
                            </div>
                            <div className="col-lg-8 col-12">
                                <label className="form-label">Logo *</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    name="logo"
                                    id="logo"
                                    style={{height: '52px'}}
                                    onChange={(e) => setState(
                                        (prevState) => ({
                                            ...prevState,
                                            logo: e.target.files?.[0] || null
                                        }))}/>
                                {state.oldLogo && state.oldLogo.url && (
                                    <Tooltip title={state.oldLogo.name}>
                                        <a className="linkBtn"
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           href={state.oldLogo.url}>
                                            <Icon type="eye"/>
                                        </a>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="col-lg-12 col-12">
                                <label className="form-label">Address *</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    required name="address"
                                    value={state.address}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <button type="submit"
                                onClick={() => {
                                    events.handleSubmit()
                                }}
                                className="btn btn-primary mt-4"
                        >
                            Submit
                        </button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AgentProfile;
