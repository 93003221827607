import {Icon, Modal} from "../../../components/Elements";
import _ from "lodash";
import {
    calculateNewFareWithMargins,
    passengerTypeObject,
    updateSingleObjectPriceUpdate
} from "../../../components/utils/appUtils";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import UpdateMarginCode from "./updateMarginModal";

const fixed2Digit = (value) => {
    return parseFloat(value).toFixed(2);
};

const CollapsibleSection = ({id, title, children, isOpen = false}) => {
    const [isExpanded, setIsExpanded] = React.useState(isOpen);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="accordion-item border-1 mb-1">
            <h2 className="accordion-header d-flex align-items-center" id={`heading-${id}`}>
                <button
                    className={`accordion-button d-flex justify-content-between align-items-center customCollapse ${isExpanded ? 'expanded' : ''}`}
                    onClick={toggleExpand}
                    aria-expanded={isExpanded}
                    aria-controls={`collapse-${id}`}
                >
                    {title}
                    <span
                        style={{
                            transform: isExpanded ? "rotate(90deg)" : "rotate(0)",
                            transition: "transform 0.3s",
                        }}
                    />
                </button>
            </h2>
            <div
                id={`collapse-${id}`}
                className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
                aria-labelledby={`heading-${id}`}
            >
                <div className="accordion-body p-1">{children}</div>
            </div>
        </div>
    );
};

const SummaryRow = (props) => {
    const {title, published, showIcon = true, negative = false, showEditIcon = false, setShowMarginModal} = props;

    return (
        <div className="summary-grid" style={{color: negative ? "red" : "black"}}>
            <div>{title}{negative && " (-)"}</div>
            <div>
                {published ? (
                    <>
                        {negative && "-"} {showIcon ? <Icon name="rupee"/> : ""}
                        {published}
                        {showEditIcon && (
                            <span onClick={() => setShowMarginModal(true)}>
                                <Icon name="edit"/>
                            </span>
                        )}
                    </>
                ) : 0}
            </div>
        </div>
    );
};

const ResponseFlightSummary = (props) => {
    let {data, callBack, xtravelMarginDataCallback, response, xtravelMarginData, convinceAmount = 0} = props;

    const [totalAmt, setTotalAmt] = useState(response?.fareQuote?.Fare?.newFare || data?.FlightItinerary?.Fare)
    const Fare = data?.FlightItinerary?.Fare || {};
    const FareQuote = response?.fareQuote?.Fare || {}
    const Passengers = data?.FlightItinerary?.Passenger || [];
    // console.log(response , "dattat--", data, "-------", )
    const publishedFare = Fare?.PublishedFare
    const totalTax = ((Fare?.OtherCharges || 0) / 1.18).toFixed(2);
    const totalGST = totalTax * 0.18;
    let AgentXTravelMarginAmount = convinceAmount || 0


    //discount
    // let commissionEarnedNew = ((parseFloat(Fare?.CommissionEarned ?? 0)) +
    //     (parseFloat(Fare?.IncentiveEarned ?? 0)) +
    //     (parseFloat(Fare?.PLBEarned ?? 0))).toFixed(2)

    let commissionEarnedNew = 0 //just in case for coupon code flow needed

    //after discount
    let amountBeforeDiscount = publishedFare;

    //after discount
    let amountAfterDiscount = amountBeforeDiscount - (commissionEarnedNew || 0);

    //Gst convenience fees
    // let gstOnConvenienceFees = (AgentXTravelMarginAmount * 0.18) || 0;

    let xTravelExchangeMargin =
        response?.fareQuote?.agentXTravelMargin != undefined ?
            response?.fareQuote?.agentXTravelMargin :
            response?.fareQuote?.xTravelExchangeMargin

    //service fees
    let xTravelMargin = (amountAfterDiscount * (xTravelExchangeMargin / 100)) || 0;

    //gst on service fees
    let gstOnXTravelMargin = (xTravelMargin * 0.18) || 0;

    //admin and x travel margin combined
    let TotalMarginWithAgent = (xTravelMargin || 0) + (AgentXTravelMarginAmount || 0)
    let TotalMarginGst = (gstOnXTravelMargin || 0)


    const totalAmountWithAncillaries = Math.round(
        amountAfterDiscount +
        (xTravelMargin || 0) +
        (gstOnXTravelMargin || 0)
    );

    const netAmount = Math.round(
        amountAfterDiscount +
        (xTravelMargin || 0) +
        (AgentXTravelMarginAmount || 0) +
        (gstOnXTravelMargin || 0)
    );

    useEffect(() => {
        callBack(totalAmountWithAncillaries)
        xtravelMarginDataCallback({
            commissionEarnedNew,
            amountBeforeDiscount,
            amountAfterDiscount,
            // gstOnConvenienceFees,
            xTravelMargin,
            gstOnXTravelMargin,
            TotalMarginWithAgent,
            totalGST,
            netAmount
        })
    }, [
        totalAmountWithAncillaries,
        commissionEarnedNew,
        amountBeforeDiscount,
        amountAfterDiscount,
        // gstOnConvenienceFees,
        xTravelMargin,
        gstOnXTravelMargin,
        TotalMarginWithAgent,
        TotalMarginGst,
        totalGST,
        netAmount
    ])

    useEffect(() => callBack(totalAmt), [totalAmt]);
    useEffect(() => {
        let marginData = {
            xTravelExchangeMargin: response?.fareQuote?.xTravelExchangeMargin,
            agentXTravelMargin: response?.fareQuote?.agentXTravelMargin
        }

        let marginNew = calculateNewFareWithMargins(Fare, marginData)
        // console.log(marginNew, "new")
        setTotalAmt(marginNew)
    }, [])

    const seenPaxTypes = new Set();

    const paxTypeTotals = Passengers.reduce((acc, passenger) => {
        const paxType = passenger.PaxType;
        const baseFare = passenger.Fare?.BaseFare || 0;
        const tax = passenger.Fare?.Tax || 0;
        const otherCharges = passenger.OtherCharges || 0;
        const totalFare = baseFare + tax + otherCharges;

        if (!acc[paxType]) {
            acc[paxType] = {count: 0, total: 0};
        }
        acc[paxType].count += 1;
        acc[paxType].total += totalFare;
        return acc;
    }, {});

    return (
        <div className="detail-box sticky-check-box">
            <h4>Fare Summary</h4>

            <CollapsibleSection id="pax-wise-fare" title="Pax-Wise Fare">
                {Passengers.map((passenger, index) => {
                    if (seenPaxTypes.has(passenger.PaxType)) return null;
                    seenPaxTypes.add(passenger.PaxType);

                    const baseFare = passenger.Fare?.BaseFare || 0;
                    const tax = passenger.Fare?.Tax || 0;
                    const otherCharges = passenger.OtherCharges || 0;
                    const totalFare = baseFare + tax + otherCharges;

                    return (
                        <div key={index}>
                            <SummaryRow title={<b>{passengerTypeObject[passenger.PaxType]}</b>}
                                        published={fixed2Digit(baseFare)} offered={baseFare}/>
                            <SummaryRow title="Tax" published={fixed2Digit(tax)} offered={tax}/>
                            <SummaryRow title="T.fee and S.charges" published={fixed2Digit(otherCharges)}
                                        offered={otherCharges}/>

                            <hr style={{margin: '7px 0'}}/>
                            <SummaryRow
                                title="Total"
                                published={fixed2Digit(totalFare)}
                                offered={fixed2Digit(totalFare)}
                                showIcon={false}
                            />
                            <hr style={{margin: '7px 0'}}/>
                        </div>
                    );
                })}
            </CollapsibleSection>

            <CollapsibleSection id="base-fare" isOpen title="Base Fare">
                {/*{Object.entries(paxTypeTotals).map(([paxType, { count, total }]) => (*/}
                {/*    <SummaryRow*/}
                {/*        key={paxType}*/}
                {/*        title={`${passengerTypeObject[paxType]} x ${count}`}*/}
                {/*        published={fixed2Digit(total)}*/}
                {/*        offered={total}*/}
                {/*    />*/}
                {/*))}*/}
                <hr/>
                <SummaryRow title="Total Base Fare" published={fixed2Digit(Fare.PublishedFare)}
                            offered={Fare.PublishedFare}/>
            </CollapsibleSection>

            <CollapsibleSection id="additional-charges" isOpen title="Additional Charges">
                <SummaryRow title="Total Baggage" published={fixed2Digit(Fare.TotalBaggageCharges)}
                            offered={Fare.TotalBaggageCharges}/>
                <SummaryRow title="Total Meal" published={fixed2Digit(Fare.TotalMealCharges)}
                            offered={Fare.TotalMealCharges}/>
                <SummaryRow title="Total Seat" published={fixed2Digit(Fare.TotalSeatCharges)}
                            offered={Fare.TotalSeatCharges}/>
                <SummaryRow title="Total Special Service" published={fixed2Digit(Fare.TotalSpecialServiceCharges)}
                            offered={Fare.TotalBaggageCharges}/>

            </CollapsibleSection>

            <hr/>

            {/* Total Amount */}
            <CollapsibleSection id="total-amount" title={<><b>Total Amount - <Icon
                name="rupee"/> {fixed2Digit(totalAmt || xtravelMarginData?.netAmount)}</b></>}>
                <SummaryRow title="Total Amount" published={fixed2Digit(Fare?.PublishedFare)}
                            offered={Fare?.newFare || Fare?.PublishedFare}/>
                <SummaryRow title="Service Fees & Taxes"
                            published={fixed2Digit(xtravelMarginData?.TotalMarginWithAgent || 0)}
                            offered={xtravelMarginData?.TotalMarginWithAgent}/>
                <SummaryRow title="Total Gst" published={fixed2Digit(xtravelMarginData?.gstOnXTravelMargin || 0)}
                            offered={xtravelMarginData?.gstOnXTravelMargin}/>
                <hr/>
            </CollapsibleSection>
        </div>
    );
};

export default ResponseFlightSummary;

