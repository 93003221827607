import React, {useEffect, useState} from "react";
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    getDay
} from "date-fns";
import "./FlightCalendar.css";
import moment from "moment";

const Calendar = (props) => {
    let {value, onSelect, disabledDate, searchResults, callback, label, startFrom} = props;
    let today = moment().startOf('day')
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showPrev, setShowPrev] = useState(false);
    let currentMonth = startFrom ? moment(startFrom).month() : moment().month()

    useEffect(() => {
        if (startFrom) {
            setCurrentDate(startFrom)
        }
    }, [startFrom])

    const generateMonthDays = (date) => {
        const start = startOfMonth(date);
        const end = endOfMonth(date);
        const days = eachDayOfInterval({start, end});
        const firstDayIndex = getDay(start);
        const blanks = Array(firstDayIndex).fill(null);
        return [...blanks, ...days];
    };
    const renderMonth = (date) => {
        const days = generateMonthDays(date);
        const monthYear = format(date, "MMMM yyyy");

        return (
            <div className="month-container">
                <h3 className="month-title">{monthYear}</h3>
                <div className="day-labels">
                    {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((d) => (
                        <div key={d} className="day-label">{d}</div>
                    ))}
                </div>
                <div className="day-grid">
                    {days.map((day, index) => {
                        const formattedDate = moment(day).format("YYYY-MM-DD"); // Format to match API response
                        const flightFare = searchResults?.find(
                            flight => moment(flight.DepartureDate).format("YYYY-MM-DD") === formattedDate
                        )?.Fare;
                        let isDisabled = disabledDate(day)
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    if (!isDisabled) {
                                        if (label !== "Departure") {
                                            props.changeJourneyType("2");
                                        }
                                        onSelect(day)
                                    }
                                }}
                                className={`day-cell ${value && moment(value).isSame(day, "day") ? "selected" : ""} ${isDisabled ? "disabled" : ""} ${day ? "active" : "empty"}`}
                            >
                                {day && (
                                    <>
                                        <div className="date-number">{format(day, "d")}</div>
                                        <div
                                            className={`price ${flightFare !== "N/A" && flightFare <= 4300 ? "low-price" : ""}`}>
                                            {flightFare ? <>
                                                    ₹{Math.round(flightFare)}
                                                </>
                                                : null}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const nextMonth = () => {
        const newDate = addMonths(currentDate, 1);
        setCurrentDate(newDate);
        if (callback) {
            callback(newDate, label);
        }
    };

    const prevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    useEffect(() => {
        setShowPrev(currentMonth < (currentDate && moment(currentDate).month()))
    }, [currentDate])

    return (
        <div className="calendar-wrapper">
            <div className="calendar-header">
                {showPrev ?
                    <a onClick={prevMonth} className="nav-btn">&#8592;</a> : <a style={{width: 57}}/>}
                <span className="title">Book round trip for great savings</span>
                <a onClick={nextMonth} className="nav-btn">&#8594;</a>
            </div>
            <div className="calendar-body">
                {renderMonth(currentDate)}
                {renderMonth(addMonths(currentDate, 1))}
            </div>
            <div className="footer">Showing our lowest prices in ₹</div>
        </div>
    );
};

export default Calendar;
