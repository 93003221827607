import React, {useState, useRef, useEffect, useCallback} from "react";
import {flightTypeOption, JourneyTypeObj} from "../../../components/utils/appUtils";

const JourneyPicker = (props) => {
    let {onSelect, journeyType} = props;
    return (
        <div className={'city_picker'}>
            <div>
                {flightTypeOption && flightTypeOption.length ?
                    <div className={'city_list'}>
                        {flightTypeOption.map((item) => {
                            return (
                                <div key={item.id} className={'city_row'} onClick={() => onSelect(item.id)}>
                                    <div className={'justify-content-between w-100 d-flex'}>
                                        <b>{item.display}</b>
                                    </div>
                                </div>
                            )
                        })}
                    </div> : null}
            </div>
        </div>
    )
}

const SelectJourneyTypeComponent = (props) => {
    let {label, style, journeyType, onSelect} = props;
    const [visibleTypehead, setVisibleTypehead] = useState(false);
    const containerRef = useRef(null);

    // Click outside handler
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleTypehead(false);
        }
    }, []);

    useEffect(() => {
        if (visibleTypehead) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visibleTypehead, handleClickOutside]);

    return (
        <div ref={containerRef} className="content" style={style} onClick={() => setVisibleTypehead(true)}>
            <span>Trip Type</span>
            <h5>{JourneyTypeObj[journeyType]}</h5>
            {visibleTypehead && (
                <JourneyPicker
                    placeholder={props.placeholder}
                    journeyType={journeyType}
                    onSelect={(value) => {
                        onSelect(value)
                        setTimeout(() => setVisibleTypehead(false), 100);
                    }}
                />
            )}
        </div>
    );
};

export default SelectJourneyTypeComponent;
