import React, {useEffect, useRef, useState} from 'react';
import {Card, Modal, notification, Popconfirm, Table, Tooltip} from '../../../components/Elements';
import {getTicketRequestFxn, updateRequestStatusFxn} from '../action';
import {useDispatch} from 'react-redux';
import moment from "moment";
import {CancellationRequest, GetStatusBox, RequestStatus} from "../../../components/utils/appUtils";

const ViewTicketDrawer = (props) => {
    const {visible, onclose, ticketId, bookingId, pnr} = props;
    const dispatch = useDispatch();
    const tableRef = useRef();
    const [requests, setRequests] = useState([]);

    const getRequestFromTicketId = async (ticketId) => {
        let response = await dispatch(getTicketRequestFxn({ticketId}));
        setRequests(response.data || []);
    };

    const updateRequestStatus = async (status, requestId) => {
        let response = await dispatch(updateRequestStatusFxn({status, requestId}));
        console.log(response)
        if (response.success) {
            getRequestFromTicketId(ticketId)
            setTimeout(() => {
                tableRef.current.reload()
            }, 500)
        }
    };

    useEffect(() => {
        if (ticketId) {
            getRequestFromTicketId(ticketId);
        }
    }, [ticketId]);

    const columns = [
        {
            title: 'Request Type',
            dataIndex: 'RequestType',
            key: 'RequestType',
            render: (item) => CancellationRequest[item],
        },
        {
            title: 'Passengers',
            dataIndex: 'PassengerNames',
            key: 'PassengerNames',
            render: (Passengers) =>
                Passengers?.map((id) => (
                    <div
                        key={id}
                        style={{
                            display: 'inline-block',
                            background: '#dbf1f1',
                            borderRadius: '8px',
                            padding: '4px 8px',
                            margin: '2px',
                            fontSize: '12px',
                        }}
                    >
                        {id}
                    </div>
                )),
        },
        {
            title: 'Sectors',
            dataIndex: 'Sectors',
            key: 'Sectors',
            render: (Sectors) =>
                Sectors?.map((sector) => (
                    <div
                        style={{
                            display: 'inline-block',
                            background: '#dbf1f1',
                            borderRadius: '8px',
                            padding: '4px 8px',
                            margin: '2px',
                            fontSize: '12px',
                        }}
                    >
                        {sector.Origin} - {sector.Destination}
                    </div>
                )),
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD-MM-YYYY HH:mm a'),
        },
        {
            title: 'Status',
            dataIndex: 'ResponseStatus',
            key: 'ResponseStatus',
            render: (ResponseStatus) => GetStatusBox(ResponseStatus),
        },
        {
            title: 'Remarks',
            dataIndex: 'Remarks',
            key: 'Remarks',
        },
        {
            title: 'Re-Issue Date',
            dataIndex: 'reissueStartDate',
            key: 'reissueStartDate',
            render: (reissueStartDate) => reissueStartDate && moment(reissueStartDate).format('DD-MM-YYYY HH:mm a'),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div>
                    <Tooltip title={'Change-Status'}>
                        <Popconfirm
                            title="Are you sure you want to Change Status?"
                            onConfirm={() => updateRequestStatus(record.ResponseStatus, record._id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <img
                                className={'ticketBackofficeIcons'}
                                src="/icons/reissue.png"
                                width="20"
                                alt="eye-icon"
                            />
                        </Popconfirm>

                    </Tooltip>
                </div>
            ),
        }
    ];

    return (
        <Modal
            width={'70%'}
            title={`Requests (${requests.length})`}
            visible={visible}
            onClose={onclose}
        >
            <Card bordered={false}>
                <div className={'requestDetails'}>BookingId - {bookingId}</div>
                <div className={'requestDetails'}>PNR - {pnr}</div>
                <br/>
                <Table
                    dataSource={requests}
                    columns={columns}
                    ref={tableRef}
                    rowKey="requestId"/>
            </Card>
        </Modal>
    );
};

export default ViewTicketDrawer;