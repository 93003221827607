import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom";
import {
    displayDate,
    displayTime,
    getTotalDurationSegments,
    minToHours
} from "../../components/utils/appUtils";
import {Popover} from "../../components/Elements";
import FlightImg from "../../components/flightImg";
import AllStopsDetailsPopover from "../flightSearchResult/components/AllStopsDetailsPopover";
import {getAirlineLogo} from "../../components/utils/appUtils";
import CheckoutWithStopComponent from "../flightSearchResult/flightListcomponents/checkoutWithStopComponent";
import NonStopComponent from "../flightSearchResult/flightListcomponents/nonStopComponent";
import WithStopComponent from "../flightSearchResult/flightListcomponents/withStopComponent";

const FlightAddress = ({data}) => {
    return (
        <>
            {data && data.Airport && data.Airport.AirportCode ? ` ${data.Airport.AirportCode}` : ""}
            <small className="d-block">
                {data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
            </small>
        </>
    )
}

const NonStopComponents = (props) => {
    let {data, allSegmentData} = props;
    let {Segments, FareRules: fareRules} = data;
    // let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>
            <div className={''}>
                <ul>
                    <li className="p-0">
                          <span className="logo">
                            <img src={getAirlineLogo(data.AirlineCode)} alt=""/>
                          </span>
                        <h5>
                            {segmentStarting && segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            {segmentStarting ? <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small> : null}
                            {/*<small className="d-block">*/}
                            {/*    {data['IsLCC'] ? "LCC" : "Non LCC"}*/}
                            {/*</small>*/}
                        </h5>
                        <div className="d-flex align-items-center" style={{gap: 30}}>
                            <h5>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h5>
                            <span><FlightImg/></span>
                            <h5>
                                <small className="d-block">
                                    {getTotalDurationSegments(allSegmentData)}
                                </small>
                                <small className={"d-block"}>
                                    <p className={'non-stop-p'}>Non-Stop</p>

                                </small>

                            </h5>
                            <span><FlightImg/></span>

                            <h5>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}
                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h5>
                            <h5>
                                {getTotalDurationSegments(allSegmentData)}
                                <small className="d-block">Economy Class</small>
                                <small className="d-block">
                                    {data.IsRefundable ? "Refundable" : "Non Refundable"}
                                </small>
                            </h5>
                        </div>
                    </li>
                    <p className="line">
                        Adult: Check-in (
                        <span style={{ color: segmentStarting?.Baggage === '0' ? 'red' : 'inherit' }}>
                            {segmentStarting?.Baggage}
                        </span>) + Cabin: {segmentStarting?.CabinBaggage}
                    </p>

                </ul>
            </div>
        </>
    )
}
const WithStopComponents = (props) => {
    let {data, allSegmentData} = props;
    let {Segments, FareRules: fareRules} = data;
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];

    const calculateLayoverTime = (arrivalTime, departureTime) => {
        const arr = new Date(arrivalTime);
        const dep = new Date(departureTime);
        const diffMinutes = Math.max(0, Math.floor((dep - arr) / 60000));
        return minToHours(diffMinutes);
    };


    return (
        <>
            {allSegmentData.map((segment, index) => {
                const isLastSegment = index === allSegmentData.length - 1;
                const nextSegment = allSegmentData[index + 1];
                const layoverTime = nextSegment
                    ? calculateLayoverTime(segment.Destination?.ArrTime, nextSegment.Origin?.DepTime)
                    : null;

                return (
                    <React.Fragment key={index}>
                        {/* Flight Segment */}
                        <div className="track-flights false mt-3">
                            <ul>
                                <li className="p-0">
                                      <span className="logo">
                                        <img src={getAirlineLogo(segment.Airline?.AirlineCode)} alt=""/>
                                      </span>
                                    <h6>
                                        {segment.Airline?.AirlineName}
                                        <small className="d-block">
                                            {segment.Airline?.AirlineCode} - {segment.Airline?.FlightNumber} {segment.Airline?.FareClass}
                                        </small>
                                        <small className="d-block">{data.IsLCC ? "LCC" : "Non LCC"}</small>
                                    </h6>

                                    <div className="d-flex align-items-center" style={{gap: "30px"}}>
                                        <h6>
                                            {segment.Origin?.DepTime && <>{displayDate(segment.Origin.DepTime)}</>}
                                            {segment.Origin && <FlightAddress data={segment.Origin}/>}
                                        </h6>

                                        {/* Flight Path with Stops */}
                                        <span>
                      <FlightImg/>
                    </span>
                                        <h6>


                                            <small className="d-block">
                                                {segment.Origin?.Airport?.AirportCode} &nbsp;-&nbsp; {segment.Destination?.Airport?.AirportCode}
                                            </small>
                                            <small className="d-block">
                                                {minToHours(segment?.Duration || "")}
                                            </small>

                                        </h6>
                                        <span>
                                            <FlightImg/>
                                        </span>

                                        <h6>
                                            {segment.Destination?.ArrTime && <>{displayDate(segment.Destination.ArrTime)}</>}
                                            {segment.Destination && <FlightAddress data={segment.Destination}/>}
                                        </h6>

                                        {allSegmentData.length === 1 && (
                                            <h6>
                                                {minToHours(segment?.Duration || "")}
                                                <small className="d-block">Economy Class</small>
                                                <small
                                                    className="d-block">{data.IsRefundable ? "Refundable" : "Non Refundable"}</small>
                                            </h6>
                                        )}
                                    </div>
                                </li>
                                <p className="line">
                                    Adult: Check-in (
                                    <span style={{ color: segment?.Baggage === '0' ? 'red' : 'inherit' }}>
                            {segment?.Baggage}
                        </span>) + Cabin: {segment?.CabinBaggage}
                                </p>

                            </ul>
                        </div>
                        {segment.IsTransitVisaRequired ?
                            <div className="transit-visa-required-box">
                                <div className="line"></div>
                                <div className="box">
                                    <span>Transit Visa Required</span>
                                    <small> You will require a transit visa</small>
                                </div>
                            </div> : null}

                        {/* Layover Time */}
                        {layoverTime && (
                            <p className="note layover-text">
                                <span
                                    className={'color-red'}> Require to change Plane - Layover Time: {layoverTime} </span>
                            </p>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};


const DomesticRoundTripFlightDetails = (props) => {
    let {data, journeyType, title, bookingType = "", sessionId = ""} = props;
    let {Segments} = data;
    let allSegmentData = Segments[0];
    let checkWhichProcess = journeyType == "2" ? bookingType == title : true;
    const compD = (
        allSegmentData.length > 1 ? <CheckoutWithStopComponent {...props} allSegmentData={allSegmentData}/> :
            <NonStopComponent {...props} allSegmentData={allSegmentData}/>
    )
    const navigate = useNavigate();

    const checkoutInBound = () => {
        navigate(`/checkout`, {state: {params: {bookingType: "Inbound", sessionId}}});
    }

    return (
        <div className={`flight-info-box`}>
            {!checkWhichProcess ? <div className={'disabled-block'}></div> : null}

            {sessionId && title == "Inbound" && !checkWhichProcess ?
                <div className={'book-flight-block'}>
                    <a onClick={checkoutInBound}>Click here to Inbound booking</a>
                </div> : null}

            <div className={`track-flights ${!checkWhichProcess && "opacity-75"}`}>
                <div>
                    <div className={'flight-type-box'}>
                        <h5>{title}</h5>
                        <span>
                            {checkWhichProcess ? "In Process" : ""}
                        </span>
                    </div>
                    {compD}
                </div>
            </div>
        </div>
    )
}

const OverseasRoundTripFlightDetails = (props) => {
    let {journeyType, title, bookingType = "", sessionId = "", allSegmentData} = props;
    let checkWhichProcess = journeyType == "2" ? bookingType == title : true;
    const compD = (
        allSegmentData.length > 1 ? <WithStopComponents {...props} allSegmentData={allSegmentData}/> :
            <NonStopComponents {...props} allSegmentData={allSegmentData}/>
    )
    const navigate = useNavigate();

    const checkoutInBound = () => {
        navigate(`/checkout`, {state: {params: {bookingType: "Inbound", sessionId}}});
    }

    return (
        <div className={`flight-info-box`}>
            <div className={`track-flights`}>
                {compD}
            </div>
        </div>
    )
}
export {DomesticRoundTripFlightDetails, OverseasRoundTripFlightDetails}
