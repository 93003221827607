import React from "react"
import emptyImg from "./empty_img.svg"
import "./index.css"

const Empty = (props) => {
    let {title = 'No Data'} = props;
    return (
        <div className={'empty_box'}>
            <img src={emptyImg}/>
            <div className={'message-info'}>
                {title}
            </div>
        </div>
    )
}
export default Empty
