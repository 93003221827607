import FlightImg from "../../../components/flightImg";
import {
    displayDate,
    getAirlineLogo,
    getTotalDurationSegments,
    minToHours
    ,
    getTotalDurationFromSegments
} from "../../../components/utils/appUtils";
import React from "react";
import AllStopsDetailsPopover from "../components/AllStopsDetailsPopover";

const FlightAddress = ({data}) => (
    <>
        <small className="d-block">
            {data?.Airport?.AirportName ? data.Airport.AirportName : ""}
            {data?.Airport?.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
        </small>
        <small className="d-block">
            {data?.Airport?.Terminal ? `${data.Airport.Terminal}, ` : ""}
            {data?.Airport?.CityName ? `${data.Airport.CityName} ` : ""}
        </small>

    </>
);

const CheckoutWithStopComponent = ({data}) => {
    const {Segments} = data;
    const allSegmentData = Segments[0];

    const calculateLayoverTime = (arrivalTime, departureTime) => {
        if (!arrivalTime || !departureTime) return "";
        const arr = new Date(arrivalTime);
        const dep = new Date(departureTime);
        return minToHours(Math.max(0, Math.floor((dep - arr) / 60000)));
    };

    return (
        <>
            {allSegmentData.map((segment, index) => {
                const isLastSegment = index === allSegmentData.length - 1;
                const nextSegment = allSegmentData[index + 1];
                const layoverTime = nextSegment
                    ? calculateLayoverTime(segment.Destination?.ArrTime, nextSegment.Origin?.DepTime)
                    : null;
                return (
                    <React.Fragment key={index}>
                        <div className="track-flights false mt-3">
                            <ul>
                                <li className="p-0">
                              <span className="logo">
                              <img src={getAirlineLogo(segment.Airline?.AirlineCode)} alt=""/>
                             </span>
                                    <h6>
                                        {segment.Airline?.AirlineName}
                                        <small className="d-block">
                                            {segment.Airline?.AirlineCode} - {segment.Airline?.FlightNumber} {segment.Airline?.FareClass}
                                        </small>
                                        <small className="d-block">{data.IsLCC ? "LCC" : "Non LCC"}</small>
                                    </h6>

                                    <div className="d-flex align-items-center" style={{gap: "30px"}}>
                                        <h6>
                                            {segment.Origin?.DepTime && <>{displayDate(segment.Origin.DepTime)}</>}
                                            {segment.Origin && <FlightAddress data={segment.Origin}/>}
                                        </h6>

                                        <span>
                                          <FlightImg/>
                                        </span>
                                        <h6>


                                            <small className="d-block">
                                                {segment.Origin?.Airport?.AirportCode} &nbsp;-&nbsp; {segment.Destination?.Airport?.AirportCode}
                                            </small>
                                            <small className="d-block">
                                                {minToHours(segment['Duration'])}
                                            </small>

                                        </h6>
                                        <span>
                                          <FlightImg/>
                                        </span>

                                        <h6>
                                            {segment.Destination?.ArrTime && <>{displayDate(segment.Destination.ArrTime)}</>}
                                            {segment.Destination &&
                                            <FlightAddress data={segment.Destination} segment={segment}/>}
                                        </h6>

                                        {allSegmentData.length === 1 && (
                                            <h6>
                                                {minToHours(segment.Duration)}
                                                <small className="d-block">Economy Class</small>
                                                <small
                                                    className="d-block">{data.IsRefundable ? "Refundable" : "Non Refundable"}</small>
                                            </h6>
                                        )}
                                    </div>
                                </li>
                                <p className="line">
                                    Adult: Check-in (
                                    <span style={{ color: segment?.Baggage === '0' ? 'red' : 'inherit' }}>
                            {segment?.Baggage}
                        </span>) + Cabin: {segment?.CabinBaggage}
                                </p>

                            </ul>
                        </div>
                        {segment.IsTransitVisaRequired ?
                            <div className="transit-visa-required-box">
                                <div className="line"></div>
                                <div className="box">
                                    <span>Transit Visa Required</span>
                                    <small> You will require a transit visa</small>
                                </div>
                            </div> : null}
                        {layoverTime && (
                            <p className="note layover-text">
                                <span
                                    className={'color-red'}>Require to change Plane - Layover Time: {layoverTime} </span>
                            </p>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default CheckoutWithStopComponent;
