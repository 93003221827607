import React, {useState} from "react"
import DownloadApplications from "../../../components/pageComponents/downloadApplications";
import {addAgent, listAllCountries} from "../action";
import CountryJson from "../../../assets/jsonFiles/countries.json"
import {LoadState} from "../../../components/utils/appUtils"
import {LoadCity} from "../../../components/utils/appUtils"
import _ from "lodash"
import RegisterBanner from "../../../assets/imgs/register.jpg"
import {useDispatch} from "react-redux";
import {Form, notification} from "../../../components/Elements";
import {hideLoader, showLoader} from "../../../actions/loader";

const initState = {

    loading: false,
    isUser: false,
    dontAskOtp: false,
    last2Digit: '',
    email: '',
    allCountry: [],
    allStates: [],
    allCities: [],
    cityName: '',
    companyName: '',
    name: '',
    pincode: '',
    address: '',
    website: '',
    stateName: '',
    countryName: '',
    referBy: '',
    mobile: '',
    countryISOCode: '',
    ids: localStorage.getItem('dontAskOtpForIds') ? JSON.parse(localStorage.getItem('dontAskOtpForIds')) : []
}
const Login = (props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initState);
    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name == 'name') {
            const filteredValue = value.replace(/[^a-zA-Z]/g, '');
            setState((prevState) => ({
                ...prevState,
                [name]: filteredValue,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };
    const events = {
        async loadCountry() {
            const {dispatch} = props
            let {data} = await dispatch(listAllCountries({results: 1000000}))
            setState({allCountry: data})
        },

        chooseCountry: (e) => {

            const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form
            let countryName = _.find(CountryJson, (item) => {
                return item.name == e.target.value
            })

            if (countryName) {
                setFieldsValue({
                    stateName: undefined,
                    cityName: undefined
                })
                setState((prevState) => ({
                    ...prevState,
                    countryName: countryName.name ? countryName.name : '',
                    stateName: '',
                    cityName: '',
                    allStates: countryName.id ? LoadState(countryName.id) : [],
                }));
            }
        },
        chooseState: (e) => {
            const {setFieldsValue} = props.form
            let stateName = _.find(state.allStates, (item) => {
                return item.name == e.target.value
            })

            setFieldsValue({
                cityName: undefined
            })
            if (stateName) {
                setState((prevState) => ({
                    ...prevState,
                    stateName: stateName.name ? stateName.name : '',
                    cityName: '',
                    allCities: stateName.id ? LoadCity(stateName.id) : [],
                }));
            }
        },
        chooseCity: (e) => {

            setState((prevState) => ({
                ...prevState,
                cityName: e.target.value ? e.target.value : '',
            }));
        },
        checkNumaric(val) {

            let reg = /^-?\d+\.?\d*$/
            if (val.companyName.match(reg)) {
                return {checkNum: true, inValid: 'Company Name'}
            }

            if (val.name.match(reg)) {
                return {checkNum: true, inValid: 'Contact Person Name'}
            }
            return {checkNum: false, inValid: ''}
        },

        handleSubmit: (e) => {
            e.preventDefault()
            props.form.validateFields(async (err, values) => {
                if (!err) {

                    let {checkNum, inValid} = events.checkNumaric(state)
                    if (checkNum) {
                        notification.warning({message: `${inValid} should not contain Number`})
                    } else {
                        state.type = 'x-travel'
                        dispatch(showLoader())
                        let fd = new FormData()
                        fd.append('obj', JSON.stringify(state))
                        let x = await dispatch(addAgent(fd))
                        dispatch(hideLoader())
                        if (x && x.success) {
                            setState(initState)
                            let message = 'Thank you for registering with us! Your request has been saved.Our representative will contact you within 24 to 48 hours.'
                            notification.success({
                                message: message || 'Error',
                            })

                        }
                    }
                    // }
                }
            })
        }
    }
    return (
        <>
            <section className="login-module">
                <div className="container">
                    <div className="main-head py-3 py-lg-5">
                        <h3>Welcome Back</h3>
                        <p className="m-0">Please login to your account</p>
                    </div>
                </div>
            </section>
            <section className="form-section">
                <img src={RegisterBanner} className="banner-login" style={{height: "auto"}}/>
                <div className="container">
                    <div className="row row-gap-3">
                        <div className="col-lg-7 me-auto">
                            <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5 mt-5 mb-5">
                                <Form onSubmit={events.handleSubmit}>
                                    <div className="row row-gap-3">
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Company Name *</label>
                                            <input type="text" className="form-control" required
                                                   value={state.companyName} name='companyName' onChange={(e) => {
                                                handleChange(e)
                                            }}
                                                   placeholder="Your Company Name"/>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Person Name *</label>
                                            <input type="text" className="form-control" required name='name'
                                                   value={state.name}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }}
                                                   placeholder="Your First Name"/>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Country *</label>
                                            <select
                                                className="form-control"
                                                onChange={(e) => events.chooseCountry(e)}
                                                required
                                                value={state.countryName}
                                            >
                                                <option value="" disabled selected>
                                                    Choose Country
                                                </option>
                                                {CountryJson && CountryJson.length > 0 ? (
                                                    CountryJson.map((item, key) => (
                                                        <option value={item.name} key={key}>
                                                            {item.name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option disabled>No countries available</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">State *</label>
                                            <select
                                                onChange={(e) => events.chooseState(e)}
                                                className="form-control"
                                                required
                                                value={state.stateName}
                                            >
                                                <option value="" disabled selected>
                                                    Choose State
                                                </option>
                                                {state.allStates ? state.allStates.map((item, key) => {
                                                    return (
                                                        <option value={item.name}
                                                                key={key}>{item.name}</option>
                                                    )
                                                }) : null}
                                            </select>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">City *</label>
                                            {state.countryName == 'India' ? (
                                                <select
                                                    onChange={(e) => events.chooseCity(e)}
                                                    className="form-control"
                                                    required
                                                    value={state.cityName}
                                                >
                                                    <option value="" disabled>
                                                        Choose City
                                                    </option>
                                                    {state.allCities && state.allCities.length > 0 ? (
                                                        state.allCities.map((item, key) => (
                                                            <option value={item.name} key={key}>
                                                                {item.name}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option disabled>No Cities available</option>
                                                    )}
                                                </select>
                                            ) : (
                                                <input
                                                    type="text"
                                                    required
                                                    className="form-control"
                                                    placeholder="Enter City"
                                                    value={state.cityName}
                                                    onChange={(e) => events.chooseCity(e)}
                                                />
                                            )}
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Postal Code *</label>
                                            <input type="number" className="form-control" required value={state.pincode}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }} name='pincode'
                                                   placeholder="Enter Postal Code"/>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Phone Number *</label>
                                            <input type="tel" className="form-control" required value={state.mobile}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }} name="mobile"
                                                   placeholder="Enter Phone Number"/>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Email *</label>
                                            <input type="email" className="form-control" required value={state.email}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }} name="email" placeholder="Enter Email"/>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Website</label>
                                            <input className="form-control" value={state.website}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }} name="website" placeholder="Enter Website (Optional)"/>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label">Referral Code</label>
                                            <input type="number" className="form-control" value={state.referBy}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }} name="referBy"
                                                   placeholder="Referral Code (Optional)"/>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label">Address *</label>
                                            <input type="text" className="form-control" required value={state.address}
                                                   onChange={(e) => {
                                                       handleChange(e)
                                                   }} name="address" placeholder="Complete Address"/>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DownloadApplications/>
        </>
    )
}
export default Form.create()(Login)
