import React from "react"
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import "./style.css"

const ContentComponent = (props) => {
    let {title, content} = props;
    return (
        <div>
            {title ? <div className={'popover-title'}>{title}</div> : null}
            <div className={'popover-content'}>
                {content}
            </div>
        </div>
    )
}

const PopoverComponent = (props) => {
    let {children, position = 'top', overlayClassName = ""} = props;
    return (
        <Tooltip overlay={<ContentComponent {...props}/>}
                 styles={{width: 800}}
                 placement={position}
                 trigger={'hover'}
                 overlayClassName={overlayClassName}
                 overlayStyle={{cursor: "pointer"}}>
            <div>
                {children}
            </div>
        </Tooltip>
    )
}
export default PopoverComponent
