import {Api_Url, Api_Url2} from "../../api";

export const loginSendOtpUrl = () => {
    return "/x-travel/login/send-otp"
}
export const loginSubmitLoginUrl = () => {
    return "/x-travel/login/login-by-otp"
}
export const resendOtpUrl = () => {
    return "/x-travel/resend-otp"
}
export const singleUserUrl = () => {
    return '/x-travel/get-user'
}
export const RecoverPasswordUrl = () => {
    return '/api-v2/recoverPassword'
}
export const ResetPasswordUrl = () => {
    return '/api-v2/resetPassword'
}
export const updateUserPasswordUrl = () => {
    return '/x-travel/update-password'
}
export const listCountriesUrl = () => {
    return '/country'
}
export const addAgentUrl = () => {
    return '/agents'
}
export const testingUrl = () => {
    return '/testing'
}

export const listAllBranchUrl = () => {
    return '/branch'
}

export const singleUserUpdateUrl = id => {
    return '/user/' + id
}

export const singleUserDataUrl = () => {
    return '/user/'
}