import React from "react"

const DownloadApplications = () => {
    return (
        <>
            <section className="download-application spacing-60">
                <div className="container">
                    <div className="card pt-3 pt-lg-5">
                        <div className="col-lg-11 col-11 mx-auto">
                            <div className="row row-gap-3">
                                <div className="col-lg-7 me-auto col-12">
                                    <div className="content">
                                        <h3 className="mb-2 mb-lg-4 color-white">
                                            Download Our Mobile Application
                                        </h3>
                                        <p>
                                            Experience seamless booking on the go with our user-friendly mobile app. Access exclusive deals, real-time updates, and manage your bookings anytime, anywhere!
                                        </p>
                                        <p>Enter your phone number to get download link</p>
                                        <div className="flex-box">
                                            <form>
                                                <div className="mb-2">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter Mobile Number"
                                                        id="exampleInputNumber"
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-secondary">
                                                    Send Download Link
                                                </button>
                                            </form>
                                            <span className="or">or</span>
                                            <div className="download-btns">
                                                <a href="#">
                                                    <img src="../images2/app-store.jpg" alt="app-store"/>
                                                </a>
                                                <a href="#">
                                                    <img
                                                        src="../images2/google-play.jpg"
                                                        alt="google-play"
                                                    />
                                                </a>
                                            </div>
                                            <div className="scaner">
                                                {/*<img src="images2/scaner.jpg" alt=""/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-lg-block d-none">
                                    <img
                                        src="../images2/mobile.png"
                                        alt="mobile"
                                        className="w-100"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DownloadApplications
