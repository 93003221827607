import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../components/Elements";
import {getUpdatedSingleTicketFxn, singleTicketFxn} from "../flightSearch/actions";
import {useLocation, useNavigate} from 'react-router-dom'
import {showLoader, hideLoader} from "../../actions/loader";
import SuccessPage from "./components/success";
import ErrorPage from "./components/error";
import moment from "moment";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY");
};

const TicketResponse = () => {
        const location = useLocation();
        const {params, data, passengerState} = location.state || {};
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get("sessionId");
    const [flightData, setFlightData] = useState({})
    const bookingType = searchParams.get("bookingType");
        let [response, setResponse] = useState({})
        let [passengers, setPassengers] = useState({})
        let [error, setError] = useState({})
        const {flightObj, traceId, selectedFlight, fareQuoteObj} = useSelector((state) => ({
            flightObj: state.flightsReducer.flightObj,
            traceId: state.flightsReducer.traceId,
            selectedFlight: state.flightsReducer.selectedFlight,
            // fareQuoteObj: state.flightsReducer.fareQuoteObj,
        }))

        // console.log(traceId, "traceid-------")
        let navigate = useNavigate()
        // console.log(passenger, "00000")
        let ticketId = window.location.pathname.split('/').pop();

        const dispatch = useDispatch();
        // console.log(sessionId, "sessionId")

        useEffect(() => {
            /*if (!data) {
                loadData();
            }*/
            loadData();

        }, []);

        const loadData = async () => {
            dispatch(showLoader())
            let {data, success, error} = await dispatch(getUpdatedSingleTicketFxn({ticketId}))
            setError(data.error)

            dispatch(hideLoader())
            if (data && data.responseStatus) {
                setPassengers(data['Passengers'])
                setResponse(data);
                setFlightData(data)
            } else if (data.bookingResponse) {
                setResponse(data.bookingResponse)
                setPassengers(data['Passengers'])
                setFlightData(data)
            } else if (data.error) {
                setPassengers(data?.Response?.FlightItinerary['Passengers'])
            } else if (data.Response) {
                setResponse(data.Response)
                setFlightData(data)
                setPassengers(data?.Response?.FlightItinerary['Passengers'])
            }
        };

        return (
            <>
                {data ? (
                    <>
                        <SuccessPage
                            selectedFlight={selectedFlight}
                            flightObj={flightObj}
                            response={data} passengers={data?.Response?.FlightItinerary?.Passenger} ticketId={ticketId}
                            sessionId={sessionId}
                            flightData={flightData}
                            bookingType={bookingType}/>
                    </>
                ) : (response['responseStatus'] === 1 || response['Status'] === 1 || response['responseStatus'] === 2 || response['TicketStatus'] === 1) ? (
                    <>

                        <SuccessPage
                            selectedFlight={selectedFlight}
                            flightObj={response['flightObj'] || {}}
                            response={response}
                            passengers={passengers}
                            ticketId={ticketId}
                            flightData={flightData}
                            sessionId={sessionId}
                            bookingType={bookingType}/>
                    </>
                ) : (
                    <div>
                        {response && response['ResponseStatus'] ?
                            <ErrorPage response={response} ticketId={ticketId}/> : null}
                        {error && error['ErrorCode'] ?
                            <ErrorPage response={response} ticketId={ticketId} error={error}/> : null}
                    </div>
                )}
            </>
        );
    }
;

export default TicketResponse
