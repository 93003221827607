import { Card } from "../../../components/Elements";
import React from "react";
import UserIcon from "../../../assets/img/user-icon.png";

const AncillaryServices = (props) => {
    let { adultPassenger, childPassenger, infantPassenger } = props;
    // console.log(adultPassenger, "aduu")

    const renderPassengerServices = (passenger) => {
        const baggage = passenger.Baggage
            ? Array.isArray(passenger.Baggage) && passenger.Baggage.length > 0
                ? passenger.Baggage.map((baggageItem, index) => (
                    <div key={index}>
                        {baggageItem?.Weight ? `${baggageItem.Weight} kg` : "--"}
                        {baggageItem?.Price ? (
                            <>
                                <br />
                                {baggageItem.Currency} {baggageItem.Price}
                            </>
                        ) : ""}
                    </div>
                ))
                : (
                    <div>
                        {passenger.Baggage.Weight ? `${passenger.Baggage.Weight} kg` : "--"}
                        {passenger.Baggage.Price ? (
                            <>
                                <br />
                                {passenger.Baggage.Currency} {passenger.Baggage.Price}
                            </>
                        ) : ""}
                    </div>
                )
            : "--";


        const seat = passenger.SeatDynamic?.length > 0
            ? passenger.SeatDynamic.map((seatItem, index) => (
                <div key={index}>
                    Seat: {seatItem?.Code || "--"} <br /> {seatItem?.Currency || "--"} {seatItem?.Price || "--"}
                </div>
            ))
            : "--";

        const meal = passenger.MealDynamic?.length > 0
            ? passenger.MealDynamic.map((mealItem, index) => (
                <div key={index}>
                    {mealItem?.AirlineDescription || "--"} <br /> {mealItem?.Currency || "--"} {mealItem?.Price || "--"}
                </div>
            ))
            : passenger.Meal?.Description
                ? passenger.Meal.Description
                : "--";


        const specialServices = (
            <>
                {passenger.SpecialServices?.length > 0
                    ? passenger.SpecialServices.map((service, index) => (
                        <div key={index}>
                            {service?.Text || "--"} - {service?.Currency || "--"} {service?.Price || "--"}
                        </div>
                    ))
                    : "--"}
                {passenger?.BagDynamic && Object.keys(passenger.BagDynamic).length > 0 && (
                    <div>
                        {passenger.BagDynamic.Text || "--"} - {passenger.BagDynamic.Currency || "--"} {passenger.BagDynamic.Price || "--"}
                    </div>
                )}
            </>
        );

        return (
            <tr key={passenger.firstName + passenger.lastName}>
                <td>{baggage}</td>
                <td>{seat}</td>
                <td>{meal}</td>
                <td>{specialServices}</td>
            </tr>
        );
    };


    const renderPassengerTable = (passengerArray, title, index) => {
        return (
            <div className="row mb-3" key={title}>
                <div className="col-md-3">
                    <p>
                        <img src={UserIcon} alt={'icon'} style={{ height: 16, marginRight: 5 }} />  <strong>{title} {index + 1}</strong>
                    </p>
                </div>
                <div className="col-md-8">
                    <table className="table table-bordered text-center">
                        <thead>
                        <tr>
                            <th>Baggage</th>
                            <th>Seat</th>
                            <th>Meal</th>
                            <th>Special Services</th>
                        </tr>
                        </thead>
                        <tbody style={{ minHeight: '85px' }}>
                        {passengerArray?.map(renderPassengerServices)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <>
            <Card title={'Ancillary Services'} round={12}>
                <div>
                    {adultPassenger?.map((passenger, index) => renderPassengerTable([passenger], 'ADULT', index))}
                    {childPassenger?.map((passenger, index) => renderPassengerTable([passenger], 'CHILD', index))}
                    {/*{infantPassenger?.map((passenger, index) => renderPassengerTable([passenger], 'Infant', index))} */}
                </div>
            </Card>
        </>
    );
};

export default AncillaryServices;
