import React from "react"

const ComingSoon = (props) => {
    return (
        <>
            <div className="row row-gap-3">
                <div className="col-lg-12">
                    <div className="ticket-list pe-3">
                        <div className={'coming-soon-text'}>
                            Coming Soon
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ComingSoon
