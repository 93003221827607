import _ from "lodash";
import {Input, notification, PhoneNo, Form, Popover, InputBox} from "../../../components/Elements";
import React, {useEffect, useState} from "react";
import {
    calculateAge, calculateAgeInDecimal,
    daysArr,
    genderArr, getTitleListFxn,
    monthArr,
    nationalityArr,
    passengerTypeObject,
    titleArr,
    yearArr,
    isValidEmail
} from "../../../components/utils/appUtils";
import {CustomFormFields} from "../../../components/utils/customFormUtils";
import ArrowUp from '../../../assets/img/arrowUp.png'
import AncillaryServiceForm from "./addAncillaryForm";
import {useLocation} from "react-router-dom";
import {
    getLastTenPassengersDetails,
    ticketBySessionIdFxn
} from "../../flightSearch/actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import SeatPreferences from "../../ModalComponent/seatPreferences";
import countryJson from '../../../components/Elements/PhoneInput/countryFlag.json'
import CountryNationalityJson from '../../../components/Elements/PhoneInput/countryNationality.json'
import OverseasAncillaryService from "./overseasAncillaryform";
import InfoIcon from '../../../assets/img/i_icon.png'

let initState = {
    title: undefined,
    firstName: "",
    lastName: "",
    gender: undefined,
    day: undefined,
    month: undefined,
    year: undefined,
    Nationality: "",
    PassportNo: "",
    PassportExpiry: "",
    PassportIssueCountry: "",
    PassportIssueDate: "",
    Baggage: [],
    MealDynamic: [],
    SeatDynamic: [],
    BagDynamic: {},
    SpecialServices: [],
    PassportIssueCountryCode: "",
    CellCountryCode: "",
    lastTenPassengers: [],
    selectedPassenger: {}
}

let initGstState = {
    "gstCompanyAddress": "",
    "gstCompanyContactNumber": "",
    "gstCompanyName": "",
    "gstNumber": "",
    "gstCompanyEmail": ""
}

const contactInitState = {
    email: "",
    contactNo: "",
    addressLine1: "",
    country: ""
}

class SinglePassengerComponent extends React.Component {
    passEvents = {
        setDefaultTitleArr: () => {
            let {paxType} = this.props.data;
            this.setState({
                titleArrList: getTitleListFxn(paxType)
            })
        },
        _updateState: (obj) => {
            this.setState({...obj}, () => {
                this.props.onChange(this.state);
            });
        },
        onChange: (dataObj, fieldName) => {
            if (fieldName === "title") {
                let findGender = _.find(this.state.titleArrList, (item) => item.name === dataObj["title"]);
                if (findGender) {
                    dataObj["gender"] = findGender.gender;
                }
            } else if (fieldName === "PassportIssueCountry") {
                let selectedCountry = _.find(countryJson, (item) => item.name === dataObj["PassportIssueCountry"]);
                if (selectedCountry) {
                    dataObj["PassportIssueCountryCode"] = selectedCountry.isoCode;
                    dataObj["CellCountryCode"] = selectedCountry.dialCode;
                }
            }
            this.passEvents._updateState(dataObj);
        },
        checkValidation: (item) => {
            let {state} = this;
            if (item.required) {
                if (state && state[item.key]) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        getLastTenPassengers: async () => {
            try {
                const {paxType} = this.props.data;
                const {data} = await getLastTenPassengersDetails({paxType});
                this.setState({lastTenPassengers: data});
            } catch (error) {
                console.error('Error fetching last ten passengers:', error);
            }
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            ...props.data,
            titleArrList: titleArr
        };
    }

    componentDidMount() {
        this.passEvents.setDefaultTitleArr()
        this.passEvents.getLastTenPassengers()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({...this.props.data});
        }
    }

    handleChange = (event) => {
        const selectedKey = event.target.value;

        if (!selectedKey) return;

        const selectedPassenger = this.state.lastTenPassengers.find(passenger =>
            `${passenger.firstName.toLowerCase()}_${passenger.lastName.toLowerCase()}` === selectedKey
        );

        if (selectedPassenger) {
            this.setState({
                lastTenPassengers: this.state.lastTenPassengers,
                selectedPassenger,
                title: selectedPassenger.title,
                firstName: selectedPassenger.firstName,
                lastName: selectedPassenger.lastName,
                gender: selectedPassenger.gender,
                day: selectedPassenger.day,
                month: selectedPassenger.month,
                year: selectedPassenger.year,
                Nationality: selectedPassenger.Nationality,
                PassportNo: selectedPassenger.PassportNo,
                PassportExpiry: selectedPassenger.PassportExpiry,
                PassportIssueCountry: selectedPassenger.PassportIssueCountry,
                PassportIssueDate: selectedPassenger.PassportIssueDate,
                PassportIssueCountryCode: selectedPassenger.PassportIssueCountryCode,
                CellCountryCode: selectedPassenger.CellCountryCode,
            });
        }
    };

    render() {
        let {fareObject, passengerType, index, bookingType, visiblePassportFields, showRequired} = this.props;
        let {state} = this;
        let ppRequire = fareObject && fareObject['IsPassportRequiredAtTicket']
        let ppFullRequire = fareObject && fareObject['IsPassportFullDetailRequiredAtBook']
        if (ppRequire) {
            visiblePassportFields = ppRequire;
        }
        const passengerField = [
            {
                key: "title",
                label: "Title",
                type: "select",
                placeholder: "Choose Title *",
                required: true,
                optionClass: "bold-500",
                options: state.titleArrList,
                span: "col-md-2",
                keyAccessor: (option) => option.name,
                valueAccessor: (option) => option.name,
            },
            {
                key: "firstName",
                label: "First Name",
                type: "customInput",
                placeholder: "First Name *",
                required: true,
                span: "col-md-5",
            },
            {
                key: "lastName",
                label: "Last Name",
                type: "customInput",
                placeholder: "Last Name *",
                required: true,
                span: "col-md-5",
            },
            {
                key: "gender",
                label: "Gender",
                type: "select",
                placeholder: "Gender *",
                required: true,
                options: genderArr,
                span: "col-md-6",
                keyAccessor: (option) => option.value,
                valueAccessor: (option) => option.name,
            },
            {
                key: "day",
                label: "",
                type: "select",
                placeholder: "Day *",
                required: true,
                span: "col-md-2",
                showSearch: true,
                optionClass: "bold-500",
                options: daysArr(),
                extra: "Date Of Birth"
            },
            {
                key: "month",
                label: "",
                emptyBlock: true,
                type: "select",
                placeholder: "Month *",
                required: true,
                span: "col-md-2",
                showSearch: true,
                options: monthArr,
                optionClass: "bold-500",
                keyAccessor: (option) => option.key,
                valueAccessor: (option) => option.name,
            },
            {
                key: "year",
                label: "",
                emptyBlock: true,
                type: "select",
                placeholder: "Year *",
                required: true,
                showSearch: true,
                span: "col-md-2",
                optionClass: "bold-500",
                options: yearArr(passengerType),
            },
            {
                key: "Nationality",
                label: "Nationality",
                placeholder: "Nationality *",
                type: "select",
                required: true,
                span: "col-md-6",
                options: CountryNationalityJson,
                showSearch: true,
                keyAccessor: (option) => option.alpha_2_code,
                valueAccessor: (option) => option.nationality,
                hidden: !(visiblePassportFields),
            },
            {
                key: "PassportNo",
                label: "Passport No",
                placeholder: `Passport Number ${ppRequire ? "*" : ""}`,
                type: "text",
                required: ppRequire,
                span: "col-md-6",
                hidden: !(visiblePassportFields),
            },
            {
                key: "PassportExpiry",
                label: "Passport Expiry",
                type: "dateOfBirth",
                placeholder: `Expiry Date ${ppRequire ? "*" : ""}`,
                required: ppRequire,
                span: "col-md-6",
                hidden: !(visiblePassportFields),
                // format: "YYYY-MM-DD",
            },
            {
                key: "PassportIssueCountry",
                label: "Passport Issue Country",
                placeholder: `Passport Country ${ppFullRequire ? "*" : ""}`,
                type: "select",
                required: ppFullRequire,
                span: "col-md-6",
                options: countryJson,
                showSearch: true,
                keyAccessor: (option) => option.name,
                valueAccessor: (option) => option.name,
                hidden: !(visiblePassportFields),
            },
            {
                key: "PassportIssueDate",
                label: "Passport Issue Date",
                type: "dateOfBirth",
                placeholder: `Passport Issue Date ${ppFullRequire ? "*" : ""}`,
                required: ppFullRequire,
                span: "col-md-6",
                hidden: !(visiblePassportFields),
                // format: "YYYY-MM-DD",
            }
        ];
        let customId = `${passengerType}-${index + 1}`;
        return (
            <div className="accordion-item" key={customId}>
                <h2 className="accordion-header" id={`${customId}-headingOne`}>
                    <ul>
                        <li className="p-3 head-accordion">
                            <h6 className={"text-capitalize"}>
                                {passengerType} {index + 1}
                            </h6>
                            {/*<select className="form-select" value={this.state.selectedPassenger}
                                    onChange={this.handleChange}
                                    style={{width: '400px'}}
                            >
                                <option value="">Select Last Added Passengers</option>
                                {this.state.lastTenPassengers.length > 0 ? (
                                    this.state.lastTenPassengers.map((passenger, index) => (
                                        <option key={index}
                                                value={`${passenger.firstName.toLowerCase()}_${passenger.lastName.toLowerCase()}`}>
                                            {passenger.firstName} {passenger.lastName}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No passengers found</option>
                                )}
                            </select>*/}
                            <a
                                href="#"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${customId}-collapseOne`}
                                aria-expanded="true"
                                aria-controls={`${customId}-collapseOne`}
                                className="btn p-0 collapsed">
                                <img src={ArrowUp} alt="arrow-up" width={28}/>
                            </a>
                        </li>
                    </ul>
                </h2>
                <div
                    id={`${customId}-collapseOne`}
                    className="accordion-collapse collapse show"
                    aria-labelledby={`${customId}-headingOne`}>
                    <div className="accordion-body">
                        <div className="row row-gap-3">
                            {passengerField.map((item) => {
                                let id = `${item.key}-${index}-${passengerType}`;
                                let requiredCls = this.passEvents.checkValidation(item) ? (showRequired && "red") : null;
                                let popoverContent = item.key === "firstName"
                                    ? fareObject?.FirstNameFormat
                                    : item.key === "lastName"
                                        ? fareObject?.LastNameFormat
                                        : "";

                                const popoverContentBody = (
                                    <div className="flight-layover-box p-3">
                                        <div className="flight-layover-popover"
                                             dangerouslySetInnerHTML={{__html: popoverContent}}/>
                                    </div>
                                );
                                return (
                                    !item.hidden ? (
                                        <div className={item.span} key={id}>

                                            {item.key === "firstName" || item.key === "lastName" ? (
                                                <div className="input-icon-container d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <CustomFormFields
                                                            {...this.props.form}
                                                            required={item.required}
                                                            className={requiredCls}
                                                            item={{
                                                                ...item,
                                                                passengerType: passengerType,
                                                                required: item.required,
                                                                id: id,
                                                                name: id,
                                                                disabled: bookingType == "Inbound",
                                                                value: state && state[item.key] ? state[item.key] : undefined,
                                                                size: "small",
                                                                onChange: (value) => {
                                                                    this.passEvents.onChange({[item.key]: value}, item.key);
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <Popover content={popoverContentBody}
                                                             overlayClassName={'stop-overlay-popover'}>
                                                        <img src={InfoIcon} alt={'icon'} width={20} className={'mt-2'}/>
                                                    </Popover>

                                                </div>
                                            ) : (
                                                <CustomFormFields
                                                    {...this.props.form}
                                                    required={item.required}
                                                    className={requiredCls}
                                                    item={{
                                                        ...item,
                                                        passengerType: passengerType,
                                                        required: item.required,
                                                        id: id,
                                                        name: id,
                                                        disabled: bookingType == "Inbound",
                                                        value: state && state[item.key] ? state[item.key] : undefined,
                                                        size: "small",
                                                        onChange: (value) => {
                                                            this.passEvents.onChange({[item.key]: value}, item.key);
                                                        },
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ) : null
                                );
                            })}

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


const Passengers = (props) => {
    let {
        flightObj,
        fareObject,
        callbackState,
        traceId,
        selectedFlight,
        bookingType,
        flightBookingType,
        visiblePassportFields,
        currentUser
    } = props;

    const [childPassengers, setChildPassengers] = useState([]);
    const [adultPassengers, setAdultPassengers] = useState([]);
    const [infantPassengers, setInfantPassengers] = useState([]);
    const [showRequired, setShowRequired] = useState(false);
    const [state, setState] = useState(contactInitState);
    let [gstState, setGstState] = useState(initGstState);
    const [showAncillaryModal, setShowAncillaryModal] = useState(false);
    const location = useLocation();
    const stateParams = location.state?.params;
    const dispatch = useDispatch();
    useEffect(() => {
        events.loadInvoiceData()
    }, [stateParams, traceId, bookingType])
    useEffect(() => {
        events.initPassenger()
    }, [])
    useEffect(() => {
        events.loadPassengerData()
    }, [flightObj.ticketData])
    const gstFields = [
        {
            key: "gstNumber",
            placeholder: "GST Number",
            required: fareObject?.isGstMandatory || false,
            span: "col-md-6",
        },
        {
            key: "gstCompanyName",
            placeholder: "Company Name",
            required: fareObject?.isGstMandatory || false,
            span: "col-md-6",
        },
        {
            key: "gstCompanyAddress",
            placeholder: "Company Address",
            required: fareObject?.isGstMandatory || false,
            span: "col-md-6",
        },
        {
            key: "gstCompanyContactNumber",
            placeholder: "Company Contact Number",
            required: fareObject?.isGstMandatory || false,
            span: "col-md-6",
        },
        {
            key: "gstCompanyEmail",
            placeholder: "Company Email",
            required: fareObject?.isGstMandatory || false,
            span: "col-md-6",
        },
    ]
    const contactFields = [
        {
            key: "contactNo",
            placeholder: "Contact No",
            required: true,
            span: "col-md-6",
        },
        {
            key: "email",
            placeholder: "Email",
            required: true,
            span: "col-md-6",
        }
    ];


    const events = {
        initPassenger: () => {
            let adultPassengers = []
            let childPassengers = []
            let infantPassengers = []
            _.times(flightObj.adultCount, (item) => {
                adultPassengers.push({...initState, paxType: 1})
            })
            _.times(flightObj.childCount, (item) => {
                childPassengers.push({...initState, paxType: 2})
            })
            _.times(flightObj.infantCount, (item) => {
                infantPassengers.push({...initState, paxType: 3})
            })
            setAdultPassengers(adultPassengers);
            setChildPassengers(childPassengers);
            setInfantPassengers(infantPassengers);
            let countryCode = currentUser.countryCode?.replace("+", "") || "91"
            setState((prevState) => {
                return {
                    ...prevState,
                    contactNo: `${countryCode}${currentUser.mobile}`,
                    email: currentUser.email,
                    addressLine1: currentUser.address,
                    country: currentUser.countryName,
                    city: currentUser.cityName,
                }
            })
        },
        updateAdultPassenger: (data, index) => {

            setAdultPassengers((prevPassengers) => {
                let cloneD = _.cloneDeep(prevPassengers);

                cloneD[index] = {
                    ...cloneD[index],
                    ...data,
                    // SeatDynamic: data.SeatDynamic !== undefined ? data.SeatDynamic : cloneD[index]?.SeatDynamic || []
                };
                return [...cloneD];
            });
        },

        updateChildPassenger: (data, index) => {
            setChildPassengers((prevPassengers) => {
                let cloneD = _.cloneDeep(prevPassengers);

                cloneD[index] = {
                    ...cloneD[index],
                    ...data,
                    // SeatDynamic: data.SeatDynamic !== undefined ? data.SeatDynamic : cloneD[index]?.SeatDynamic || []
                };

                return [...cloneD];
            });
        },

        updateInfantPassengers: (data, index) => {
            let cloneD = _.clone(infantPassengers);
            cloneD[index] = {...cloneD[index], ...data}
            setInfantPassengers(cloneD)
        },
        updateGstState: (data) => {
            setGstState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _updatePassenger: () => {
            props._updateSummary({
                childPassengers,
                adultPassengers,
                infantPassengers,
                state: state,
                gstState,
            });
        },
        sendForReview: (e, overrideAncillaryCheck = false) => {
            if (e) e.preventDefault();
            setShowRequired(true)
            let ppRequire = fareObject && fareObject['IsPassportRequiredAtTicket']
            let allPassenger = [...adultPassengers, ...childPassengers, ...infantPassengers];
            let findE = _.find(allPassenger, (item) => {
                let passFields = item.Nationality && item.PassportNo && item.PassportExpiry && item.PassportIssueCountry;
                let passportExpiryValid = true;
                if (item.PassportExpiry) {
                    let [day, month, year] = item.PassportExpiry.split("/");
                    let expiryDate = new Date(`${year}-${month}-${day}`);
                    if (flightObj.preferredDepartureTime) {
                        let departureDate = new Date(flightObj.preferredDepartureTime);
                        passportExpiryValid = expiryDate >= departureDate;
                    }
                }
                return (
                    !item.title ||
                    !item.firstName ||
                    !item.lastName ||
                    item.gender === undefined ||
                    item.day === undefined ||
                    item.month === undefined ||
                    item.year === undefined ||
                    (ppRequire && !passFields) ||
                    !passportExpiryValid
                );
            });


            if (findE) {
                // console.log(findE);
                let fullName = findE.firstName ? findE.firstName : ""
                if (findE.lastName) {
                    fullName = `${fullName} ${findE.lastName}`
                }
                if (findE.PassportExpiry) {
                    let [day, month, year] = findE.PassportExpiry.split("/");
                    let expiryDate = new Date(`${year}-${month}-${day}`);
                    if (flightObj.preferredDepartureTime) {
                        let departureDate = new Date(flightObj.preferredDepartureTime);
                        if (expiryDate < departureDate) {
                            notification.error({
                                message: `Passport expiry date for Passenger - ${fullName} cannot be earlier than the flight's departure date.`,
                            });
                            return;
                        }
                    }

                    if (flightObj.preferredReturnTime) {
                        let departureDate = new Date(flightObj.preferredReturnTime);
                        if (expiryDate < departureDate) {
                            notification.error({
                                message: `Passport expiry date for Passenger - ${fullName} cannot be earlier than the flight's return date.`,
                            });
                            return;
                        }
                    }
                }


                notification.error({message: `Please enter all required fields. Passenger - ${fullName ? fullName : passengerTypeObject[findE.paxType]}`});
                return;
            }
            let checkAge = _.find(allPassenger, (item) => {
                let age = calculateAge(item.year, item.month, item.day);
                if (item.paxType == 3) {
                    return age > 2
                } else if (item.paxType == 2) {
                    return age < 2 || age > 12
                } else {
                    return age <= 12
                }
            })
            if (checkAge) {
                let fullName = checkAge.firstName ? checkAge.firstName : ""
                if (checkAge.lastName) {
                    fullName = `${fullName} ${checkAge.lastName}`
                }
                let ageMsg = `Adult (12+ Yrs)`
                if (checkAge.paxType == 3) {
                    ageMsg = `Infant ( < 2 Yrs)`
                } else if (checkAge.paxType == 2) {
                    ageMsg = `Children (2-12 Yrs)`
                }
                notification.error({message: `Please enter valid age of Passenger - ${fullName} , ${ageMsg}`});
                return;
            }


            if (!state.contactNo) {
                notification.error({message: "Please enter contact no."});
                return;
            }
            if (!state.email) {
                notification.error({message: "Please enter a email address."});
                return;
            }
            if (!state.email || !isValidEmail(state.email)) {
                notification.error({message: "Please enter a valid email address."});
                return;
            }

            if (!state.addressLine1) {
                notification.error({message: "Please enter Billing Address."});
                return;
            }

            const requiredFields = fareObject && fareObject['RequiredFieldValidators'] || {};

            let missingFields = [];

            allPassenger.forEach((passenger, index) => {
                if (requiredFields.IsBaggageRequired && _.isEmpty(passenger.Baggage)) {
                    missingFields.push(`Baggage for Passenger ${index + 1}`);
                }
                if (requiredFields.IsMealRequired && _.isEmpty(passenger.MealDynamic)) {
                    missingFields.push(`Meal for Passenger ${index + 1}`);
                }
                // if (requiredFields.IsSeatRequired && _.isEmpty(passenger.SeatDynamic)) {
                //     missingFields.push(`Seat for Passenger ${index + 1}`);
                // }
            });

            if (missingFields.length > 0) {
                notification.error({
                    message: "Required Fields Missing",
                    description: `Please select: ${missingFields.join(", ")}`
                });
                return;
            }

            let ancillaryEmpty = allPassenger.every(passenger =>

                _.isEmpty(passenger.SeatDynamic)
            );

            if (ancillaryEmpty && !overrideAncillaryCheck && requiredFields.IsSeatRequired) {
                setShowAncillaryModal(true);
                return;
            }

            const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
            if (gstState.gstNumber && !gstRegex.test(gstState.gstNumber)) {
                notification.error({
                    message: "Invalid GST Number",
                    description: "Please enter a valid 15-character GST number."
                });
                return;
            }

            if (fareObject?.isGstMandatory) {
                let missingGstFields = gstFields.filter(field => !gstState[field.key]);
                if (missingGstFields.length > 0) {
                    notification.error({
                        message: "GST Details Required",
                        description: "Please fill in all required GST fields."
                    });
                    return;
                }
            }


            allPassenger.forEach((passenger, index) => {
                if (!passenger.firstName || passenger.firstName.length < 2) {
                    notification.error({message: `First Name for Passenger ${index + 1} must be at least 2 characters long.`});
                    return;
                }
                if (!passenger.lastName || passenger.lastName.length < 2) {
                    notification.error({message: `Last Name for Passenger ${index + 1} must be at least 2 characters long.`});
                    return;
                }
            });


            callbackState({
                childPassengers,
                adultPassengers,
                infantPassengers,
                state: state,
                gstState,
            });
        },
        loadInvoiceData: async () => {
            if (stateParams && stateParams.sessionId) {
                let {success, data} = await dispatch(ticketBySessionIdFxn({sessionId: stateParams.sessionId}));
                // console.log(data, "Datattt")
                if (success) {
                    let passengers = data['Passengers'];
                    let contactInfo = {}
                    let gstInfo = {}
                    let adultPass = []
                    let childPass = []
                    let infantPass = []

                    _.each(passengers, (item) => {
                        let obj = {}
                        obj.title = item['Title']
                        obj.firstName = item['FirstName']
                        obj.lastName = item['LastName']
                        obj.gender = item['Gender']
                        obj.paxType = item['PaxType']
                        if (item['DateOfBirth']) {
                            obj.day = moment(item['DateOfBirth']).date();
                            obj.month = moment(item['DateOfBirth']).month();
                            obj.year = moment(item['DateOfBirth']).year();
                        }
                        if (!contactInfo.email) {
                            contactInfo['email'] = item['Email']
                        }
                        if (!contactInfo.contactNo) {
                            contactInfo['contactNo'] = item['ContactNo']
                        }
                        if (!gstInfo.gstNumber) {
                            gstInfo['gstNumber'] = item['GSTNumber'];
                        }
                        if (!gstInfo.gstCompanyName) {
                            gstInfo['gstCompanyName'] = item['GSTCompanyName'];
                        }
                        if (!gstInfo.gstCompanyAddress) {
                            gstInfo['gstCompanyAddress'] = item['GSTCompanyAddress'];
                        }
                        if (!gstInfo.gstCompanyContactNumber) {
                            gstInfo['gstCompanyContactNumber'] = item['GSTCompanyContactNumber'];
                        }
                        if (!gstInfo.gstCompanyEmail) {
                            gstInfo['gstCompanyEmail'] = item['GSTCompanyEmail'];
                        }
                        obj.Baggage = [];
                        obj.MealDynamic = [];
                        obj.SeatDynamic = [];
                        obj.BagDynamic = {};
                        obj.SpecialServices = [];


                        if (item['PaxType'] == 1) {
                            adultPass.push(obj);
                        }
                        if (item['PaxType'] == 2) {
                            childPass.push(obj);
                        }
                        if (item['PaxType'] == 3) {
                            infantPass.push(obj);
                        }
                    })


                    setAdultPassengers(adultPass)
                    setChildPassengers(childPass)
                    setInfantPassengers(infantPass)
                    events._updateState(contactInfo);
                    events.updateGstState(gstInfo);
                }
            }
        },

        loadPassengerData: async () => {

            if (flightObj && flightObj.ticketData) {
                // console.log(flightObj?.ticketData?.FlightItinerary?.Passenger)
                let passengers = flightObj?.ticketData?.FlightItinerary?.Passenger
                let contactInfo = {}
                let gstInfo = {}
                let adultPass = []
                let childPass = []
                let infantPass = []

                _.each(passengers, (item) => {
                    let obj = {}
                    obj.title = item['Title']
                    obj.firstName = item['FirstName']
                    obj.lastName = item['LastName']
                    obj.gender = item['Gender']
                    obj.paxType = item['PaxType']
                    if (item['DateOfBirth']) {
                        obj.day = moment(item['DateOfBirth']).date();
                        obj.month = moment(item['DateOfBirth']).month();
                        obj.year = moment(item['DateOfBirth']).year();
                    }
                    if (!contactInfo.email) {
                        contactInfo['email'] = item['Email']
                    }
                    if (!contactInfo.contactNo) {
                        contactInfo['contactNo'] = item['ContactNo']
                    }
                    if (!gstInfo.gstNumber) {
                        gstInfo['gstNumber'] = item['GSTNumber'];
                    }
                    if (!gstInfo.gstCompanyName) {
                        gstInfo['gstCompanyName'] = item['GSTCompanyName'];
                    }
                    if (!gstInfo.gstCompanyAddress) {
                        gstInfo['gstCompanyAddress'] = item['GSTCompanyAddress'];
                    }
                    if (!gstInfo.gstCompanyContactNumber) {
                        gstInfo['gstCompanyContactNumber'] = item['GSTCompanyContactNumber'];
                    }
                    if (!gstInfo.gstCompanyEmail) {
                        gstInfo['gstCompanyEmail'] = item['GSTCompanyEmail'];
                    }
                    obj.Baggage = [];
                    obj.MealDynamic = [];
                    obj.SeatDynamic = [];
                    obj.BagDynamic = {};
                    obj.SpecialServices = [];


                    if (item['PaxType'] == 1) {
                        adultPass.push(obj);
                    }
                    if (item['PaxType'] == 2) {
                        childPass.push(obj);
                    }
                    if (item['PaxType'] == 3) {
                        infantPass.push(obj);
                    }
                })


                setAdultPassengers(adultPass)
                setChildPassengers(childPass)
                setInfantPassengers(infantPass)
                events._updateState(contactInfo);
                events.updateGstState(gstInfo);

            }
        },
        chooseAncillaryService: (data, type, index) => {
            if (type == "child") {
                events.updateChildPassenger(data, index)
            } else {
                events.updateAdultPassenger(data, index)
            }
            // events.sendForReview(null, false)
        },
        checkGstValidation: (item) => {
            if (item.required) {
                if (gstState && gstState[item.key]) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        checkContactValidation: (item) => {
            // console.log(state && state[item.key], item);
            if (state && state[item.key]) {
                return false
            } else {
                return true
            }
        },
    }

    useEffect(() => {
        events._updatePassenger();
    }, [childPassengers, adultPassengers, infantPassengers])


    const handleProceed = () => {
        setShowAncillaryModal(false);
        events.sendForReview(null, true);
    };

    // console.log(flightObj, "flightobj--")
    return (
        <>
            <form onSubmit={events.sendForReview} noValidate>
                <div className="passanger-details">
                    <div className={"track-flights"} style={{overFlow: 'hidden'}}>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <>
                                {adultPassengers && adultPassengers.length ? adultPassengers.map((item, adItem) => {
                                    return (
                                        <SinglePassengerComponent
                                            form={props.form}
                                            data={item}
                                            fareObject={fareObject}
                                            index={adItem}
                                            visiblePassportFields={visiblePassportFields}
                                            bookingType={bookingType}
                                            flightBookingType={flightBookingType}
                                            adultPassengers={adultPassengers}
                                            showRequired={showRequired}
                                            onChange={(data) => {
                                                events.updateAdultPassenger(data, adItem)
                                            }}
                                            passengerType={'adult'} key={`adult-${adItem}`}/>
                                    )
                                }) : null}
                            </>
                            <>
                                {childPassengers && childPassengers.length ? childPassengers.map((item, adItem) => {
                                    return (
                                        <SinglePassengerComponent
                                            data={item}
                                            fareObject={fareObject}
                                            visiblePassportFields={visiblePassportFields}
                                            childPassengers={childPassengers}
                                            index={adItem}
                                            bookingType={bookingType}
                                            flightBookingType={flightBookingType}
                                            showRequired={showRequired}
                                            onChange={(data) => {
                                                events.updateChildPassenger(data, adItem)
                                            }}
                                            passengerType={'child'} key={`child-${adItem}`}/>
                                    )
                                }) : null}
                            </>
                            <>
                                {infantPassengers && infantPassengers.length ? infantPassengers.map((item, adItem) => {
                                    return (
                                        <SinglePassengerComponent
                                            data={item}
                                            infantPassengers={infantPassengers}
                                            visiblePassportFields={visiblePassportFields}
                                            fareObject={fareObject}
                                            index={adItem}
                                            bookingType={bookingType}
                                            flightBookingType={flightBookingType}
                                            showRequired={showRequired}
                                            onChange={(data) => {
                                                events.updateInfantPassengers(data, adItem)
                                            }}
                                            passengerType={'infant'} key={`infant-${adItem}`}/>
                                    )
                                }) : null}
                            </>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>Contact Details</h6>
                                            <div className="d-flex gap-3">

                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseTwo"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp}
                                                         alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingTwo">
                                    <div className="accordion-body">

                                        <div className="row row-gap-3">
                                            <div className="col-lg-6">
                                                <PhoneNo
                                                    type={'number'}
                                                    size={'small'}
                                                    placeholder={'Contact No'}
                                                    required={true}
                                                    customClass={events.checkContactValidation({key: "contactNo"}) ? (showRequired && "red") : null}
                                                    disabled={bookingType == "Inbound"}
                                                    value={state.contactNo}
                                                    onChange={(value) => {
                                                        events._updateState({contactNo: value})
                                                    }}
                                                />
                                            </div>

                                            <div className="col-lg-6">
                                                <Input
                                                    type={'text'}
                                                    placeholder={'Email address'}
                                                    value={state.email}
                                                    size={'small'}
                                                    customClass={events.checkContactValidation({key: "email"}) ? (showRequired && "red") : null}
                                                    required={true}
                                                    disabled={bookingType == "Inbound"}
                                                    onChange={(value) => {
                                                        events._updateState({email: value})
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <InputBox title={'Billing Address'} required={true}>
                                                    <Input
                                                        type={'text'}
                                                        placeholder={'Billing Address'}
                                                        value={state.addressLine1}
                                                        size={'small'}
                                                        customClass={events.checkContactValidation({key: "addressLine1"}) ? (showRequired && "red") : null}
                                                        required={true}
                                                        disabled={bookingType == "Inbound"}
                                                        onChange={(value) => {
                                                            events._updateState({addressLine1: value})
                                                        }}
                                                    />
                                                </InputBox>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            {Object.keys(props.ssrResponse || {}).length > 0 && (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                        <ul>
                                            <li className="p-3 head-accordion">
                                                <h6>Ancillary Services</h6>
                                                <div className="d-flex gap-3">

                                                    <a
                                                        href=""
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#panelsStayOpen-collapseThree"
                                                        aria-expanded="false"
                                                        aria-controls="panelsStayOpen-collapseThree"
                                                        className="btn p-0 collapsed"
                                                    >
                                                        <img src={ArrowUp}
                                                             alt="arrow-up" width={28}/>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseThree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="panelsStayOpen-headingThree">
                                        <div className="accordion-body">
                                            {flightBookingType == 'overseasFlight' ?
                                                <OverseasAncillaryService
                                                    fareObj={fareObject}
                                                    adultPassengers={adultPassengers}
                                                    childPassengers={childPassengers}
                                                    infantPassengers={infantPassengers}
                                                    ssrResponse={props.ssrResponse}
                                                    selectedFlight={selectedFlight}
                                                    bookingType={bookingType}
                                                    updateAncillaryServices={events.chooseAncillaryService}
                                                /> :

                                                <AncillaryServiceForm
                                                    fareObj={fareObject}
                                                    adultPassengers={adultPassengers}
                                                    childPassengers={childPassengers}
                                                    ssrResponse={props.ssrResponse}
                                                    infantPassengers={infantPassengers}
                                                    selectedFlight={selectedFlight}
                                                    bookingType={bookingType}
                                                    updateAncillaryServices={events.chooseAncillaryService}
                                                />
                                            }

                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                    <ul>
                                        <li className="p-3 head-accordion">
                                            <h6>GST Number for Business Travel (Optional)</h6>
                                            <div className="d-flex gap-3">
                                                <a className="btn bg-transparent p-0 active">
                                                    Save GST Details
                                                </a>
                                                <a
                                                    href=""
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#panelsStayOpen-collapseFour"
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseFour"
                                                    className="btn p-0 collapsed"
                                                >
                                                    <img src={ArrowUp} alt="arrow-up" width={28}/>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="panelsStayOpen-headingFour">
                                    <div className="accordion-body">
                                        <div className="row row-gap-3">
                                            {gstFields.map((item) => {
                                                let requiredCls = events.checkGstValidation(item) ? "red" : null;
                                                return (
                                                    !item.hidden ? <div className={item.span} key={item.key}>
                                                        <CustomFormFields
                                                            {...props.form}
                                                            className={requiredCls}
                                                            item={{
                                                                size: 'small',
                                                                ...item,
                                                                value: gstState && gstState[item.key] ? gstState[item.key] : "",
                                                                disabled: bookingType === "Inbound",
                                                                onChange: (value) => {
                                                                    events.updateGstState({[item.key]: value})
                                                                }
                                                            }}/>
                                                    </div> : null
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button className="btn btn-primary w-100 mt-3">PROCEED TO REVIEW</button>
            </form>

            {showAncillaryModal && (
                <SeatPreferences
                    visible={showAncillaryModal}
                    onClose={() => setShowAncillaryModal(false)}
                    onProceed={handleProceed}
                />
            )}
        </>
    )
}
export default Passengers
