import React, {Component} from 'react'

class InputComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let {
            type = 'text',
            name = '', value = '', placeholder = "", key = '', onChange = () => {
            },
            size = 'normal',
            disabled = false,
            customClass = "",
            autoFocus = false
        } = this.props
        return (
            <input
                key={key}
                name={name}
                type={type}
                autoFocus={autoFocus}
                placeholder={placeholder || key}
                className={`form-control ${size !== "small" ? "custom-form-control" : ""} ${customClass}`}
                value={value}
                onChange={({target}) => {
                    onChange(target.value)
                }}
                disabled={disabled}

            />
        )
    }
}

export default InputComponent

