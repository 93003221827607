import React, {useEffect, useState} from "react";

import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

const PaginationComponent = (props) => {
    let {
        total,
        page,
        onSubmit,
        defaultPageSize = 10,
        pageSize = 10,
        showSizeChanger = false,
    } = props;
    return (
        <Pagination
            showSizeChanger={showSizeChanger}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            onChange={(item) => {
                onSubmit(item);
            }}
            current={page || 1}
            total={total}
        />
    )
}
export default PaginationComponent
