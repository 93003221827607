import React, {useState} from 'react';
import {Modal} from "../../../components/Elements";
import {numberToWords} from "../../../components/utils/appUtils";

const UpdateMarginCode = ({onClose, visible, convienceFeesCallback, AgentXTravelMarginAmount}) => {
    const [newAgentMargin, setNewAgentMargin] = useState(AgentXTravelMarginAmount || '');

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setNewAgentMargin(value);
        }
    };

    return (
        <Modal
            visible={visible}
            className="popup-confirm"
            width="30%"
            height="auto"
            onClose={onClose}
            footer={null}
        >
            <div className="modal-header">
                <h5>Update Agent Margin</h5>
            </div>
            <div style={{borderBottom: '1px solid #ccc', marginTop: '8px'}}/>

            <div className="modal-body">
                <div className="form-group mt-3">
                    <label htmlFor="agentMarginInput">New Agent Margin</label>

                    <div style={{position: 'relative'}}>
                        <input
                            id="agentMarginInput"
                            type="text"
                            className="form-control mt10"
                            value={newAgentMargin}
                            onChange={handleChange}
                            placeholder="Enter new margin"
                        />
                        {newAgentMargin && (
                            <button
                                type="button"
                                onClick={() => setNewAgentMargin('')}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    border: "none",
                                    background: "transparent",
                                    cursor: "pointer",
                                }}
                            >
                                ✖
                            </button>
                        )}
                    </div>

                    {newAgentMargin && (
                        <div className="margin-in-words ml10">
                            <p>
                                <span>Amount: </span>
                                <b>{numberToWords(parseFloat(newAgentMargin) || 0)}</b>
                            </p>
                        </div>
                    )}

                    <div className="d-flex justify-content-end mt-3">
                        <button
                            className="btn btn-primary updateMarginBtn"
                            onClick={() => convienceFeesCallback(parseFloat(newAgentMargin) || 0)}
                            disabled={!newAgentMargin}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateMarginCode;
