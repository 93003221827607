import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Header from "./header";
import Footer from "../footer";
import HomeBanner from "./homeBanner";

const Index = (props) => {
    let {pathname} = useLocation();
    let isHomePage = pathname == "/home" || pathname == "/" || pathname == "/flight-search"
    return (
        <div className={isHomePage ? "" : "auth-layout"}>
            <Header {...props}/>
            {isHomePage ? <HomeBanner/> : null}
            <main className="flex-shrink-0" style={{paddingTop: isHomePage && 0}}>
                <Outlet/>
            </main>
            <Footer/>
        </div>
    )
}

export default Index;
