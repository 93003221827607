import React, {useState} from 'react'
// import { Col, Form, notification, Card, Button, Drawer, Row } from 'antd'
import {Form, notification} from "../../../components/Elements"
import {connect, useDispatch} from 'react-redux'
import {hideLoader, showLoader} from "../../../actions/loader";
import {GetEachFormFields} from "../../../components/utils/formUtils";
import {CustomFormFields} from "../../../components/utils/customFormUtils";
import {recoverPassword, updateUserPassword} from "../action";


const changePasswordInitState = {
    oldPassword: "",
    password: "",
    confirmPassword: ""
}
const ChangePassword = (props) => {
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}} = props
    const dispatch = useDispatch()
    const [state, setState] = useState(changePasswordInitState);

    let inputField = {
        fields: [
            {
                label: 'Old Password',
                key: 'oldPassword',
                type: 'password',
                placeholder: 'Enter Old Password',
                required: true,
                autoComplete: 'off'
            },
            {
                label: 'New Password',
                key: 'password',
                type: 'password',
                placeholder: 'Minimum 6 letter password',
                required: true
            },
            {
                label: 'Confirm Password',
                key: 'confirmPassword',
                type: 'password',
                placeholder: 'Minimum 6 letter password',
                required: true
            }
        ]
    }
    const events = {
        updatePassState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
    }

    let handleSubmit = e => {
        const {form} = props
        e.preventDefault()
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!state.oldPassword) {
                    return notification.error({
                        message: 'Please add your current password'
                    })
                }
                if (state.password.length < 6) {
                    return notification.error({
                        message: 'Password too small'
                    })
                }
                if (state.password !== state.confirmPassword) {
                    return notification.error({
                        message: 'Password and Confirm password mismatch'
                    })
                }
                let resp = await dispatch(updateUserPassword(state))
                if (resp && resp.success) {
                    setState(changePasswordInitState)
                }
            }
        })
    }
    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <div className="form-box">

                        <div className="d-flex align-items-center mb-3">
                            <h5>Change Password</h5>
                        </div>

                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <Form onSubmit={handleSubmit}>
                                    {inputField.fields.map((item) => {
                                        return (
                                            <div className={item.span}>
                                                <CustomFormFields
                                                    item={{
                                                        ...item,
                                                        required: item.required,
                                                        value: state && state[item.key] ? state[item.key] : "",
                                                        onChange: (value) => {
                                                            events.updatePassState({[item.key]: value})
                                                        }
                                                    }}/>
                                            </div>
                                        )
                                    })}

                                    <Form.Item>
                                        <button type="submit" className="btn btn-primary">
                                            UPDATE
                                        </button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </div>
    )
}

const WrappedChangePasswordForm = Form.create()(ChangePassword)

export default WrappedChangePasswordForm

