import React, {useEffect, useState} from "react"
import {useSearchParams} from "react-router-dom";
import {ccAvenueSuccessFxn} from "./action";
import {useDispatch} from "react-redux";

const PaymentResponse = (props) => {
    let dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState("Processing...");
    useEffect(() => {
        const encResp = searchParams.get("encResp");
        const orderId = searchParams.get("orderId");

        console.log(encResp, '================, encResp', orderId);

        if (encResp) {
            getPaymentResp(encResp);
        }
    }, [searchParams]);

    const getPaymentResp = async (encResp) => {
        let resp = await dispatch(ccAvenueSuccessFxn({encResp}))
        if (resp && resp.data && resp.data.payment_status) {
            setStatus(resp.data.payment_status);
        }
    }


    return (
        <div>
            <h2>Payment Status</h2>
            <p>{status}</p>
        </div>
    );
}
export default PaymentResponse
