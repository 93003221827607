import React, {useEffect, useState} from "react";
import {Modal, Tooltip} from '../../components/Elements';
import _ from "lodash"

const OverseasSeatLayout = (props) => {
    const {
        visible,
        onClose,
        seatsObj,
        selectedFlight,
        onSeatConfirm,
        selectedSeats,
        seatSegmentList,
        adultPassengers,
        childPassengers
    } = props;
    const [allPass, setAllPass] = useState([])
    const [seats, setSeats] = useState([])
    let [segmentList, setSegmentList] = useState([])
    const [activePassenger, setActivePassenger] = useState({
        passengerKey: "",
        seat: {},
        passIndex: ""
    });
    let totalPass = (adultPassengers.length || 0) + (childPassengers.length || 0);

    useEffect(() => {
        eventsFxn.defaultSeatSetup()
    }, [])


    const eventsFxn = {
        defaultSeatSetup: () => {
            let arrList = [];
            let seats = [];

            // Loop through each list of segments
            _.each(selectedFlight?.Segments || [], (segmentGroup) => {
                _.each(segmentGroup, (item) => {
                    let { Origin: { Airport: originAirport }, Destination: { Airport: destinationAirport } } = item;

                    let findSeat = _.find(seatSegmentList, (passS) => {
                        return passS.origin == originAirport['CityCode'] && passS.destination == destinationAirport['CityCode'];
                    });

                    if (findSeat) {
                        seats = findSeat.seats || [];
                    }

                    let obj = {
                        origin: originAirport['CityCode'],
                        destination: destinationAirport['CityCode'],
                        seats,
                        totalPrice: 0
                    };

                    obj.key = `${obj.origin} - ${obj.destination}`;
                    arrList.push(obj);
                });
            });

            setSegmentList(arrList);
        },
        chooseSeat: (segmentIndex, seat, segmentKey, passengerIndex) => {
            let cloneSeat = _.cloneDeep(segmentList); // Use cloneDeep to avoid reference issues
            let findD = _.find(cloneSeat, (item) => {
                return item.key === segmentKey; // Use `===` for comparison
            });
            let passSeat = {};
            if (findD) {
                let findExistIndex = _.findIndex(findD.seats, (item) => item && item.Code === seat.Code);
                if (findExistIndex < 0) {
                    if (passengerIndex !== "") {
                        findD.seats[passengerIndex] = seat;
                        passSeat = seat // set passenger seat
                    } else {
                        let findEmptyIndex = _.findIndex(findD.seats, (item) => item && !item.Code);
                        if (findEmptyIndex >= 0) {
                            findD.seats[findEmptyIndex] = seat;
                            passSeat = seat // set passenger seat
                        } else {
                            findD.seats = [...findD.seats, seat]; // Append and slice
                            passSeat = seat // set passenger seat
                        }
                    }
                } else {
                    if (passengerIndex !== "") {
                        findD.seats[passengerIndex] = {};
                    } else {
                        findD.seats[findExistIndex] = {};
                    }
                    passSeat = {}
                }
                findD.seats = findD.seats.slice(-totalPass);
            }
            if (activePassenger.passengerKey) {
                setActivePassenger((prevState) => {
                    return {
                        ...prevState,
                        seat: passSeat
                    }
                })
            }
            setSegmentList(cloneSeat); // Update state
        },
        choosePassenger: (key, seat, passIndex) => {
            if (activePassenger.passengerKey == key) {
                setActivePassenger({
                    passengerKey: "",
                    seat: {},
                    passIndex: ""
                })
            } else {
                setActivePassenger({
                    passengerKey: key,
                    seat: seat,
                    passIndex
                })
            }
        }
    }

    const handleConfirmSeatSelection = () => {
        setSegmentList((prevSegmentList) => {
            // console.log(prevSegmentList, "seat===");
            onSeatConfirm(prevSegmentList);
            return prevSegmentList;
        });
        onClose();
    };


    const segments = selectedFlight?.Segments?.flat() || [];
    const [activeSegmentIndex, setActiveSegmentIndex] = useState(0);


    const activePassengerKey = `${activePassenger?.type}-${activePassenger?.index}`;
    // const activePassengerSeats = selectedSeats[activePassengerKey] || [];


    const renderPassengerCard = (passenger, type, index, onClick) => {
        const key = `${type}-${index}`;
        const isActive = activePassengerKey === key;
        return (
            <div
                key={key}
                className={`box seat-details-box passenger-card mb-2`}
                style={{
                    cursor: "pointer",
                    border: "1px solid #ccc",
                    padding: "0.5rem",
                }}>
                <h6 className="text-capitalize mb-0">{type} {index + 1}</h6>
                <div className={'row'} style={{gap: 7}}>
                    {segmentList && segmentList.length ? segmentList.map((segment, segmentIndex) => {
                        let currentSeat = segment.seats[index];
                        const uniqueKey = `${type}-${index}-${segment.origin}_${segment.destination}`;
                        return (
                            <div key={segmentIndex}  className={'col-md-4'} onClick={() => {
                                eventsFxn.choosePassenger(uniqueKey, currentSeat, index)
                                setActiveSegmentIndex(segmentIndex)
                            }}
                                 style={{
                                     padding: "0.5rem",
                                     border: `1px solid ${uniqueKey === activePassenger.passengerKey ? "blue" : "#ccc"}`,
                                     marginTop: "5px",
                                     flex: 1,
                                     lineHeight: "1.5"
                                 }}>
                                <strong className={'f-12 text-gray'}>
                                    {segment.key}
                                </strong>
                                <div className={'f-12'}>
                                    <strong>Seat No:</strong>{" "}
                                    <span
                                        className="selected-seat">{currentSeat && currentSeat['Code'] ? currentSeat['Code'] : "--"}</span>
                                </div>
                                <div className="d-flex justify-content-between f-12 text-gray">
                                    <strong>Total Price:</strong>{" "}
                                    <span>{currentSeat ? `${currentSeat['Price'] ? `${currentSeat['Price']} ${currentSeat.Currency}` : ""} ` : "N/A"}</span>
                                </div>
                            </div>
                        )
                    }) : null}


                </div>
            </div>
        );
    };


    const onSelectSeatFromDetails = (segmentIndex) => {
        setActiveSegmentIndex(segmentIndex);
        setActivePassenger({
            passengerKey: "",
            seat: {},
            passIndex: ""
        })
    };

    const seatLiComp = (seat, seatIndex, selectedSeatsArr, segmentIndex, segment) => {
        let passSeatArr = []
        if (activePassenger.passengerKey && activePassenger.seat && activePassenger.seat['Code']) {
            passSeatArr = [activePassenger.seat['Code']]
        }
        let seatNotAvailable = seat.AvailablityType === 3 || seat.AvailablityType === 5 || seat.AvailablityType === 4 || (selectedSeatsArr.includes(seat.Code) && activePassenger.passengerKey);
        let seatList = activePassenger.passengerKey ? passSeatArr : selectedSeatsArr;
        return (
            <li key={seatIndex}
                className={`seat ${(seatNotAvailable) ? "booked" : ""}`}
                onClick={() => {
                    if (!seatNotAvailable || passSeatArr.includes(seat.Code)) {
                        eventsFxn.chooseSeat(segmentIndex, seat, segment.key, activePassenger.passIndex)
                    }
                }}
                style={{pointerEvents: seat.AvailablityType === 3 || seat.AvailablityType === 5 || seat.AvailablityType === 4 ? "none" : "auto"}}>
                <Tooltip title={`Seat: ${seat.Code}, Price: ${seat.Price} ${seat.Currency}`}>
                    <span className={`seat-code ${seatList.includes(seat['Code']) ? "selected" : ""}`}>
                                 {seat.Code}
                   </span>
                </Tooltip>
            </li>
        )
    }


    return (
        <Modal visible={visible} onClose={onClose} width={"60%"} className={'seats-layout-modal'}>
            {seatsObj && (
                <>
                    <h5>Select Seats</h5>
                    <div className="seat-selection">
                        <div className="flightbox">
                            <div className="flightbody">
                                <div className="row">

                                    <div className="col-md-4 ">
                                        <div className={'pass-layout'}>
                                            {segmentList && segmentList.length ? segmentList.map((segment, segmentIndex) => {
                                                return (
                                                    <div className="box seat-details-box mt-2" key={segment.key}
                                                         onClick={() => onSelectSeatFromDetails(segmentIndex)}
                                                         style={{
                                                             cursor: "pointer",
                                                             border: activeSegmentIndex === segmentIndex ? "2px solid blue" : "1px solid #ccc"
                                                         }}>
                                                        <h6 className={'d-flex align-items-center'}>Seat Details
                                                            - &nbsp;
                                                            <small className="d-block">({segment.key})</small></h6>
                                                        <div className="seat-details">
                                                            {/*  <div>
                                                            <strong>Segment {segmentIndex + 1}:</strong>{" "}
                                                            {segment.origin} - {segment.destination}
                                                        </div>*/}
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <strong>Seat No:</strong>{" "}
                                                                    <span className="selected-seat">
                                                                    {_.times(totalPass, (index) => {
                                                                        let currentSeat = segment.seats[index];
                                                                        return (
                                                                            <>
                                                                                {currentSeat && currentSeat['Code'] ? currentSeat['Code'] : "-"} {index < (totalPass - 1) ? ", " : ""}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </span>
                                                                </div>
                                                                <a className="btn-link cursor-pointer"
                                                                   onClick={() => onSelectSeatFromDetails(segmentIndex)}>
                                                                    Select Seat
                                                                </a>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <strong>Total Price:</strong>{" "}
                                                                <span>
                                                                    {Object.values(selectedSeats).flat().reduce((total, seat) => total + (seat?.Price || 0), 0)}{" "}
                                                                    {Object.values(selectedSeats).flat()[0]?.Currency || ""}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : null}

                                            <div className={'mt-3'}>
                                                <div>
                                                    {adultPassengers?.map((passenger, index) => {
                                                        return renderPassengerCard(passenger, 'adult', index)
                                                    })}
                                                    {childPassengers?.map((passenger, indexB) => {
                                                        let index = indexB + adultPassengers.length;
                                                        return renderPassengerCard(passenger, 'child', index)
                                                    })}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="confirm-seat mt-3 align-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleConfirmSeatSelection}>
                                                Confirm
                                            </button>
                                        </div>

                                        <div className="hintbox">

                                            <div className="hintrow">
                                                <span className="hintseat">
                                                        <dfn className="seathint quitezone">
                                                            <span></span>
                                                        </dfn>
                                                </span>
                                                <span className="hinttext">Quiet zone</span>
                                            </div>
                                            <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint hintgood">
                                                <span></span>
                                            </dfn>
                                        </span>
                                                <span className="hinttext">Selected Seat</span>
                                            </div>
                                            <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint hintbooked">
                                                <span></span>
                                            </dfn>
                                        </span>
                                                <span className="hinttext">Occupied Seat</span>
                                            </div>
                                            <div className="hintrow">
                                        <span className="hintseat">
                                            <dfn className="seathint">
                                                <i></i>
                                            </dfn>
                                        </span>
                                                <span className="hinttext">Bulkhead Seat</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 seat-layout">
                                        <div className="content d-flex justify-content-center">
                                            <div className="seat-list">
                                                {segmentList && segmentList.length ? segmentList.map((segment, segmentIndex) => {
                                                    let shownDecks = new Set()
                                                    let selectedSeatsArr = _.map(segment.seats, 'Code');
                                                    let hasFrontEntrance = false;
                                                    let lastRowIndex = seatsObj?.[segmentIndex]?.RowSeats?.length - 1

                                                    let allExistingSeats = new Set();
                                                    seatsObj?.[segmentIndex]?.RowSeats?.forEach(row => {
                                                        row.Seats.forEach(seat => {
                                                            allExistingSeats.add(seat.SeatNo);
                                                        });
                                                    });


                                                    const hasWideBodySeats = ["G", "H", "I", "J", "K"].some(seat => allExistingSeats.has(seat));

                                                    return (
                                                        <div key={segmentIndex} className={`segment-rows ${activeSegmentIndex === segmentIndex ? "active" : "hidden"}`}>
                                                            <h6 className="header-title">{segment.key}</h6>
                                                            {seatsObj?.[segmentIndex]?.RowSeats?.map((row, rowIndex) => {
                                                                let showDeckLabel = false;
                                                                let deckNumber = null;


                                                                row.Seats.forEach(seat => {
                                                                    if (seat.Deck >= 1 && !shownDecks.has(seat.Deck)) {
                                                                        showDeckLabel = true;
                                                                        deckNumber = seat.Deck;
                                                                        shownDecks.add(seat.Deck);
                                                                    }
                                                                });
                                                                const leftSide = ["A", "B", "C"].filter(seat => allExistingSeats.has(seat));
                                                                const middle = (hasWideBodySeats ? ["D", "E", "F", "G"] : ["D", "E", "F"]).filter(seat => allExistingSeats.has(seat));
                                                                const rightSide = (hasWideBodySeats ? ["H", "I", "J", "K"] : []).filter(seat => allExistingSeats.has(seat));

                                                                const seatMap = row.Seats.reduce((acc, seat) => {
                                                                    acc[seat.SeatNo] = seat;
                                                                    return acc;
                                                                }, {});
                                                                const isExitRow = row.Seats.some((seat) =>
                                                                    [6, 12, 18, 8, 9, 14, 20, 21, 22, 23, 24, 43, 45, 47, 56, 58, 59].includes(seat?.SeatType)
                                                                );
                                                                return (
                                                                    <div key={rowIndex} className="row-wrapper">
                                                                        {!hasFrontEntrance && showDeckLabel && deckNumber === 1 && (
                                                                            <div className="deck-label">
                                                                                <strong>Front Entrance</strong>
                                                                            </div>
                                                                        )}
                                                                        {showDeckLabel && (
                                                                            <div className="deck-label">
                                                                                <strong>Deck {deckNumber}</strong>
                                                                            </div>
                                                                        )}
                                                                        {isExitRow && (
                                                                            <div className="emergency-exit-label">
                                                                                <strong>Emergency Door</strong>
                                                                            </div>
                                                                        )}


                                                                        <div className="row" style={{flexWrap: 'nowrap'}}>

                                                                            {leftSide.length > 0 && (
                                                                                <div className={`seatrobox col-md-${hasWideBodySeats ? "4" : "6"}`}>
                                                                                    <ul
                                                                                        className="seatrow"
                                                                                        style={{
                                                                                            gridTemplateColumns: `repeat(${leftSide.length}, 1fr)`
                                                                                        }}
                                                                                    >
                                                                                        {leftSide.map((seatNo, seatIndex) => (
                                                                                            <li key={seatIndex} className={`seat-item ${seatMap[seatNo] ? "" : "empty-seat"}`}>
                                                                                                {seatMap[seatNo] ? seatLiComp(seatMap[seatNo], seatIndex, selectedSeatsArr, segmentIndex, segment) : ""}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            )}

                                                                            {middle.length > 0 && (
                                                                                <div className={`seatrobox col-md-${hasWideBodySeats ? "4" : "6"}`}>
                                                                                    <ul
                                                                                        className="seatrow seatrow2"
                                                                                        style={{
                                                                                            gridTemplateColumns: `repeat(${middle.length}, 1fr)`
                                                                                        }}
                                                                                    >
                                                                                        {middle.map((seatNo, seatIndex) => (
                                                                                            <li key={seatIndex} className={`seat-item ${seatMap[seatNo] ? "" : "empty-seat"}`}>
                                                                                                {seatMap[seatNo] ? seatLiComp(seatMap[seatNo], seatIndex, selectedSeatsArr, segmentIndex, segment) : ""}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            )}

                                                                            {hasWideBodySeats && rightSide.length > 0 && (
                                                                                <div className="seatrobox col-md-4" style={{paddingLeft: '32px'}}>
                                                                                    <ul
                                                                                        className="seatrow"
                                                                                        style={{
                                                                                            gridTemplateColumns: `repeat(${rightSide.length}, 1fr)`
                                                                                        }}
                                                                                    >
                                                                                        {rightSide.map((seatNo, seatIndex) => (
                                                                                            <li key={seatIndex} className={`seat-item ${seatMap[seatNo] ? "" : "empty-seat"}`}>
                                                                                                {seatMap[seatNo] ? seatLiComp(seatMap[seatNo], seatIndex, selectedSeatsArr, segmentIndex, segment) : ""}
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                </div>
                                                                            )}


                                                                        </div>
                                                                        {rowIndex === lastRowIndex && (
                                                                            <div className="deck-label">
                                                                                <strong>Back Entrance</strong>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                );
                                                            })}
                                                        </div>

                                                    );
                                                }) : null}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


        </Modal>
    );
};

export default OverseasSeatLayout;
