import React, {useState, useRef, useEffect, useCallback} from "react";
import _ from "lodash";
import {CabinObject, CabinTypeOptions} from "../../../components/utils/appUtils";
import TravellersPickerComponent from "../commonComponents/travellersPicker";


const TravellersComponent = (props) => {
    let {state, onChange} = props;
    const [visibleTypehead, setVisibleTypehead] = useState(false);
    const containerRef = useRef(null);

    // Click outside handler
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleTypehead(false);
        }
    }, []);

    useEffect(() => {
        if (visibleTypehead) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visibleTypehead, handleClickOutside]);

    const getNoOfPass = () => {
        let total = (state.adultCount || 0) + (state.childCount || 0) + (state.infantCount || 0)
        return total
    }

    return (
        <div ref={containerRef} className="content" onClick={() => setVisibleTypehead(true)}>
            <span>Travellers & Class</span>
            <h5>{getNoOfPass()} Travellers / {CabinObject[state.cabinClass]}</h5>

            {visibleTypehead && (
                <TravellersPickerComponent
                    state={state}
                    onConfirm={(obj) => {
                        onChange(obj)
                        setTimeout(() => setVisibleTypehead(false), 100);
                    }}
                />
            )}
        </div>
    );
};

export default TravellersComponent;
