import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const AboutUs = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <section className="about-banner">
                <div className="spacing-60">
                    <div className="container">
                        <div className="col-lg-10 mx-auto col-12">
                            <div className="row align-items-center">
                                <div className="col-lg-5 me-auto col-12">
                                    <h2 className="m-0">Redefining B2B Travel Solutions Since 2024</h2>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <p className="m-0">Discover the X Travel World difference—where your success is our priority.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="../images/about-banner.jpg" className="w-100"/>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">Delivering Innovative Travel Solutions for B2B Professionals</h3>
                </div>
            </section>

            <section className="agent-with-us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div id="container" className="text-center">
                                <div className="item">
                                    <img src="../images/logo-blue.png" alt="Logo"/>
                                </div>
                                <div className="circle"></div>
                                <div className="circle" style={{animationDelay: "0s"}}></div>
                                <div className="circle" style={{animationDelay: "1s"}}></div>
                                <div className="circle" style={{animationDelay: "2s"}}></div>
                                <div className="circle" style={{animationDelay: "3s"}}></div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="head text-start">
                                <h5 className="mb-3 fw-600">Our Journey</h5>
                                <p className="m-0">At X Travel World, we are committed to delivering innovative and reliable travel solutions exclusively for B2B clients. With years of experience in the travel industry, our goal is to empower travel agents, tour operators, and businesses with cutting-edge tools and unparalleled deals on flights and hotels.
                                <br/><br/>Our vision is to build long-term partnerships with travel professionals worldwide by providing a platform that simplifies operations and enhances profitability.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bonus spacing-60 pt-lg-0">
                <div className="container">
                    <div className="box position-relative text-center py-lg-5 py-3">
                        <img
                            src="https://images.pexels.com/photos/7683897/pexels-photo-7683897.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt="Travel"/>
                        <div className="overlay"></div>
                        <div className="row row-gap-3">
                            <div className="col-lg-8 mx-auto col-md-12">
                                <div className="position-relative z-1">
                                    <h5 className={'white'}>Simplifying Travel with Innovative Solutions</h5>
                                    <p className="m-0">At <b style={{fontWeight: 'bold'}}>X Travel World,</b> we are committed to revolutionizing the way travel professionals book flights and hotels. Our cutting-edge platform provides agents with real-time access to thousands of global flights, ensuring you always have the most up-to-date options available. With our strong partnerships with leading airlines and hotel chains, we guarantee the best deals and instant confirmations, allowing you to offer your clients unparalleled service and convenience.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-values">
                <div className="container">
                    <div className="head text-center mb-5">
                        <h3>Our Values</h3>
                    </div>

                    <div className="row row-gap-3">
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <h5>Innovation </h5>
                                <p className="m-0">Pioneering solutions to meet evolving industry needs.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <h5>Integrity</h5>
                                <p className="m-0">Building trust with transparent and reliable services.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="card border-0 text-center p-lg-5 p-4">
                                <span>icon</span>
                                <h5>Excellence</h5>
                                <p className="m-0">Delivering exceptional experiences at every touchpoint.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default AboutUs;
