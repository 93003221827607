import React, {useState, useEffect} from "react"
import FlightInfoComponent from "../../checkout/components/FlightInfoComponent";
import {useNavigate} from "react-router-dom";
import {
    bookSeatsFxn,
    singleTicketFxn,
    ticketListFxn,
    getBookingDetailsFxn,
    releasePnrFxn
} from "../../ticketList/action";
import {useDispatch, useSelector} from "react-redux";
import {Card, notification, Tooltip} from "../../../components/Elements";
import moment from "moment";
import ResponseFlightSummary from "../../checkout/components/responseFlightSummary";
import ResponseFlightSummaryNew from "../../checkout/components/responseFlightSummaryNew";
import HoldPaymentComponent from "../../checkout/components/holdPaymemtComponent";
import _ from "lodash";
import {flightsFearQuoteFxn, getWalletBalanceFxn, lccFlightTicketFxn} from "../../flightSearch/actions";
import {calculateNewFareAllMargins, updateSingleObjectPriceUpdate} from "../../../components/utils/appUtils";
import ChangePrice from "../../ModalComponent/changePrice";
import ChangeBookPrice from "../../ModalComponent/changeBookPrice";
import {FlightSummary} from "../../checkout/components/summary";

const ContactDetailsComponent = (props) => {
    const {passengers, ticketState} = props;
    // console.log(passengers, "passengerss---")
    const getPassengerDetails = (passenger) => {
        if (passenger) {
            const {
                FirstName: firstName,
                LastName: lastName,
                Gender: gender,
                DateOfBirth: dateOfBirth,
                Title,
                ContactNo,
                Email,
                PassportNo,
                PassportIssueDate,
                PassportExpiry,
                PassportIssueCountry,
                Nationality
            } = passenger;
            return (
                <Card className={"mb-2"}>
                    <div className={'row'}>
                        <div className={'col-md-4'}>
                            <b className={'fw-semibold'}>First
                                Name</b>: {Title ? `${Title}. ` : ""}{firstName ? `${firstName}` : ""}<br/>
                            <b className={'fw-semibold'}>Last Name</b>: {lastName ? `${lastName}` : ""}<br/>
                            <b className={'fw-semibold'}>Gender</b>: {gender === 2 ? "Female" : "Male"}<br/>
                            {ContactNo ? <>
                                <b className={'fw-semibold'}>Contact Number</b>: {ContactNo}<br/>
                            </> : null}
                            {PassportNo ? <>
                                <b className={'fw-semibold'}>D.O.B</b>: {moment(dateOfBirth).format("DD-MMM-YYYY")}<br/>
                            </> : null}
                            {Nationality ? <>
                                <b className={'fw-semibold'}>Nationality</b>: {Nationality}<br/>
                            </> : null}
                        </div>
                        <div className={'col-md-5'}>
                            {!PassportNo ? <>
                                <b className={'fw-semibold'}>D.O.B</b>: {moment(dateOfBirth).format("DD-MMM-YYYY")}<br/>
                            </> : null}
                            {Email ? <>
                                <b className={'fw-semibold'}>Email ID</b>: {Email}<br/>
                            </> : null}
                            {PassportNo ? <>
                                <b className={'fw-semibold'}>Passport Number</b>: {PassportNo}<br/>
                            </> : null}
                            {PassportIssueDate ? <>
                                <b className={'fw-semibold'}>Passport
                                    Issue</b>: {moment(PassportIssueDate).format('DD-MM-YYYY')}<br/>
                            </> : null}
                            {PassportExpiry ? <>
                                <b className={'fw-semibold'}>Passport
                                    Expiry</b>: {moment(PassportExpiry).format('DD-MM-YYYY')}<br/>
                            </> : null}
                            {PassportIssueCountry ? <>
                                <b className={'fw-semibold'}>Issue Country</b>: {PassportIssueCountry}<br/>
                            </> : null}
                        </div>
                        <div className="col-md-3 d-flex justify-content-center align-items-center">
                            {ticketState?.response?.FlightItinerary?.InvoiceCreatedOn ? (
                                <a
                                    href={`/ticket-details?ticketId=${ticketState._id}&firstName=${firstName}&lastName=${lastName}`}
                                    target="_blank"
                                    className="passengersStyle"
                                >
                                    <small className="d-block">
                                        <Tooltip title={`View Ticket - ${firstName} ${lastName}`}>
                                            <button className="customYesBtn">View Ticket</button>
                                        </Tooltip>
                                    </small>
                                </a>
                            ) : null}
                        </div>

                    </div>
                </Card>
            );
        }
    };

    return (
        <>
            <Card title={'Contact Details'} round={8}>
                {passengers && passengers.length ? passengers.map((item) => {
                    return getPassengerDetails(item)
                }) : null}
            </Card>
        </>
    );
};
const Success = (props) => {
    let {ticketId, sessionId, passengers, bookingType, selectedFlight, flightObj: oFlightObj, flightData} = props;

    const [ticketState, setTicketState] = useState({});
    let [response, setResponse] = useState({})
    const [fareRules, setFareRules] = useState({});
    const [fareQuote, setFareQuote] = useState({});
    const [flightObj, setFlightObj] = useState({});
    const [showHoldPayment, setShowHoldPayment] = useState(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0)
    const [responseData, setResponseData] = useState({})
    const [xTravelMargin, setXTravelMargin] = useState({})
    const [fareObject, setFareObject] = useState({})
    const [showPriceModal, setShowPriceModal] = useState(false)
    const [fareState, setFareState] = useState({
        oldFare: {},
        newFare: {},
        quoteFare: {},
        visible: false
    })
    let navigate = useNavigate()
    let dispatch = useDispatch()
    const {traceId, fareQuoteObj} = useSelector((state) => ({
        traceId: state.flightsReducer.traceId,
        fareQuoteObj: state.flightsReducer.fareQuoteObj
    }))

    const ShowInvoice = () => {
        navigate(`/booking-details?ticketId=${ticketId}`)
    }
    const handleBookTicketClick = () => {
        setShowHoldPayment(true);
    };
    const events = {
        singleTicket: async () => {
            let {data} = await dispatch(singleTicketFxn({ticketId: ticketId}))
            console.log(data, "dtata")
            setResponseData(data)
            setXTravelMargin(data.xtravelMarginData)
            setFareQuote(data.fareQuote)
            setFlightObj(data.flightObj || oFlightObj)
            if (data && data.response && data.response['PNR']) {
                setResponse(data.response)
            } else if (data && data.bookingResponse && data.bookingResponse['PNR']) {
                setResponse(data?.bookingResponse)
            }
            if (data && data.response && data.response['FlightItinerary']) {
                setFareRules(data.response['FlightItinerary'])
            } else if (data && data.bookingResponse && data.bookingResponse['FlightItinerary']) {
                setFareRules(data?.bookingResponse['FlightItinerary'])
            }

            setTicketState(data);
        },
        calculateNewMargin: async (newFare) => {
            let marginData = {
                xTravelExchangeMargin: responseData?.fareQuote?.xTravelExchangeMargin,
                agentXTravelMargin: responseData?.fareQuote?.agentXTravelMargin
            };
            let resp = calculateNewFareAllMargins(newFare?.Fare, marginData);

            let updatedXTravelMargin = {
                commissionEarnedNew: resp?.commissionEarnedNew || 0,
                amountBeforeDiscount: resp?.fareWithoutGst || 0,
                amountAfterDiscount: resp?.fareWithoutGst || 0,
                xTravelMargin: resp?.xTravelMargin || 0,
                gstOnXTravelMargin: resp?.gstOnXTravelMargin || 0,
                TotalMarginWithAgent: resp?.xTravelMargin || 0,
                totalGST: resp?.totalGST || 0,
                netAmount: resp?.newFare || 0
            };
            console.log(updatedXTravelMargin, "inside fxn")

           return updatedXTravelMargin;

        },
        bookTicket: async (priceAccept = false, newFare, fareMargin) => {

            // let updatedXTravelMargin = {};
            // if (priceAccept) {
            //     let marginData = {
            //         xTravelExchangeMargin: responseData?.fareQuote?.xTravelExchangeMargin,
            //         agentXTravelMargin: responseData?.fareQuote?.agentXTravelMargin
            //     };
            //     let resp = calculateNewFareAllMargins(newFare?.Fare, marginData);
            //
            //     let updatedXTravelMargin = {
            //         commissionEarnedNew: resp?.commissionEarnedNew || 0,
            //         amountBeforeDiscount: resp?.fareWithoutGst,
            //         amountAfterDiscount: resp?.fareWithoutGst,
            //         xTravelMargin: resp?.xTravelMargin,
            //         gstOnXTravelMargin: resp?.gstOnXTravelMargin,
            //         TotalMarginWithAgent: resp?.xTravelMargin,
            //         totalGST: resp?.totalGST,
            //         netAmount: resp?.newFare
            //     };
            //
            //     setXTravelMargin(updatedXTravelMargin);
            //     console.log(updatedXTravelMargin, "Updated Margin Data in fxn");
            // }
            // console.log(updatedXTravelMargin, "Updated Margin Data outside");

           let newUpdatedMargins = priceAccept ? await events.calculateNewMargin(newFare) : xTravelMargin
            console.log(newUpdatedMargins,"updated margin")
            let obj = {
                traceId: responseData?.TraceId,
                isLcc: responseData?.fareQuote?.IsLCC,
                totalPaidAmount: newFare?.Fare?.newFare || totalPaidAmount,
                fareQuote: newFare,
                xtravelMarginData: newUpdatedMargins,
                convinceFees: 0,
                bookingId: responseData?.bookingResponse?.BookingId,
                pnr: responseData?.bookingResponse?.PNR,
                ticketId: responseData?._id,
                IsPriceChangeAccepted: priceAccept
            };
            let requiredBalance = fareMargin || totalPaidAmount;
            console.log(requiredBalance);
            console.log(obj, '-----obj');
            // return;
            let {xTravelWallet} = await dispatch(getWalletBalanceFxn());
            if (requiredBalance > xTravelWallet) {
                notification.warning({
                    message: `Insufficient  wallet balance. Required -  ${requiredBalance}`
                })
                return
            }
            obj.requiredBalance = requiredBalance;
            // console.log(obj)


            let {ticketId, sessionId, success, message, data} = await dispatch(lccFlightTicketFxn(obj));
            // console.log(data, "dattat0000")

            if (message?.toLowerCase().includes("timeout")) {
                notification.error({message: "Request Timed Out", description: "Please try again."});
                navigate("/flight-search");
                return;
            }

            if (success) {
                if (data?.Response?.IsPriceChanged && priceAccept !== true) {
                    console.log('======================');
                    let marginData = {
                        xTravelExchangeMargin: responseData?.fareQuote?.xTravelExchangeMargin,
                        agentXTravelMargin: responseData?.fareQuote?.agentXTravelMargin
                    }
                    let marginNew = await updateSingleObjectPriceUpdate(data.Response?.FlightItinerary, marginData)
                    let marginOld = await updateSingleObjectPriceUpdate(responseData.bookingResponse?.FlightItinerary, marginData)
                    // console.log(marginNew, marginOld, "-------")
                    if (responseData?.bookingResponse && data?.Response) {
                        setFareState({
                            visible: true,
                            newFare: marginNew,
                            oldFare: marginOld,
                            quoteFare: marginNew
                        })

                    } else {
                        window.location.reload();
                    }
                } else {
                    window.location.reload()
                    // setTicketId(ticketId);
                    // dispatch({type: "UPDATE_SESSION_ID", sessionId: sessionId})
                    // // navigate(`/response/${ticketId}`);
                    // navigate(`/response/${ticketId}`, {state: {params: {sessionId}, data}});
                }

            } else {
                notification.error({message: message})
                // navigate(`/response/${ticketId}`, { state: { params: { sessionId }, data } })
            }
        },
        releasePnr: async () => {
            let {BookingId} = response;
            let {TraceId, TokenId, _id} = responseData
            let resp = await dispatch(releasePnrFxn({
                bookingId: BookingId,
                traceId: TraceId,
                source: response?.FlightItinerary?.Source,
                tokenId: TokenId,
                ticketId: _id
            }))
            if (resp.success) {
                window.location.reload()
            }
        }
    };


    useEffect(() => {
        events.singleTicket();

    }, []);


    return (
        <section className="agent-with-us">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-9">
                        {selectedFlight && sessionId ? (
                            sessionId ? (
                                <FlightInfoComponent
                                    page="response"
                                    flightObj={flightObj}
                                    selectedFlight={selectedFlight}
                                    traceId={traceId}
                                    sessionId={sessionId}
                                    bookingType={bookingType}
                                />
                            ) : (
                                <FlightInfoComponent
                                    page="response"
                                    flightObj={flightObj}
                                    selectedFlight={selectedFlight}
                                    traceId={traceId}
                                />
                            )
                        ) : null}


                        <div className="head text-start">
                            <div className="card mt-4 mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">Booking Details</h5>
                                    <div className="row mt-4">
                                        <div className="col-md-5">
                                            <div>{ticketState && ticketState.userId && ticketState.userId.companyName ? ticketState.userId.companyName : ""}</div>
                                            <hr style={{margin: "0.5rem 0", width: "95%"}}/>
                                            <div><strong>Address: </strong>
                                                {ticketState && ticketState.userId && ticketState.userId.address ? ticketState.userId.address : ""}
                                                {ticketState && ticketState.userId && ticketState.userId.cityName ? `${ticketState.userId.cityName}, ` : ""}
                                                {ticketState && ticketState.userId && ticketState.userId.stateName ? `${ticketState.userId.stateName}, ` : ""}
                                                {ticketState && ticketState.userId && ticketState.userId.pincode ? `${ticketState.userId.pincode} ` : ""}
                                                {ticketState && ticketState.userId && ticketState.userId.countryName ? ` (${ticketState.userId.countryName}) ` : ""}

                                            </div>
                                            <div><strong>Phone: </strong>
                                                {ticketState && ticketState.userId && ticketState.userId.countryCode ? `${ticketState.userId.countryCode} ` : ""}
                                                {ticketState && ticketState.userId && ticketState.userId.mobile ? ticketState.userId.mobile : ""}
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            {response?.PNR ?
                                                <>
                                                    <div><strong>PNR No.:</strong> {response?.PNR}
                                                    </div>
                                                    <div><strong>Travel
                                                        Date:</strong>
                                                        {flightData && flightData.flightObj && flightData.flightObj.preferredDepartureTime ?
                                                            new Date(flightData.flightObj.preferredDepartureTime).toLocaleDateString() :
                                                            "N/A"
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div><strong>PNR No.:</strong> {response?.PNR || "N/A"}</div>
                                                    <div><strong>Booked
                                                        Date:</strong> {new Date(response?.FlightItinerary?.InvoiceCreatedOn).toLocaleDateString() || "N/A"}
                                                    </div>
                                                    <div><strong>Travel
                                                        Date:</strong> {new Date(response?.FlightItinerary?.Segments?.[0]?.Origin?.DepTime).toLocaleDateString() || "N/A"}
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        {response?.FlightItinerary?.InvoiceCreatedOn ?
                                            <div className="col-md-2">
                                                <a className="btn btn-primary text-nowrap mt-auto"
                                                   href={`/booking-details?ticketId=${ticketId}`}
                                                   target="_blank">
                                                    View Invoice
                                                </a>
                                            </div>
                                            : null}

                                    </div>

                                    <div className="mt-3">
                                        <label
                                            className="label label-success label-bold mt-2 ticketBtn align-center p-2"
                                            style={{borderRadius: 5}}>
                                            Status: {ticketState?.releaseStatus === 1
                                            ? "Released"
                                            : response?.TicketStatus === 1
                                                ? "Ticketed"
                                                : responseData?.responseStatus === 2 ? 'Ready'
                                                    : "Hold"}
                                        </label>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {fareQuote && fareQuote.ResultIndex && flightObj && flightObj.origin ?
                            <>
                                <FlightInfoComponent selectedFlight={fareQuote} flightObj={flightObj}/>
                            </> : null
                        }


                        {/*                        {sessionId ?
                            <FlightInfoComponent selectedFlight={selectedFlight} flightObj={flightObj}/> : null}*/}

                        <div className="flight-accordion-details mt-3">
                            <ContactDetailsComponent
                                passengers={passengers}
                                ticketState={ticketState}
                            />
                        </div>

                        <div className="text-end">
                            {ticketState?.releaseStatus !== 1 && response?.TicketStatus !== 1 && response?.TicketStatus !== 4 ? (
                                <button
                                    className="btn btn-primary text-nowrap mt-3"
                                    onClick={handleBookTicketClick}
                                >
                                    Book Ticket
                                </button>
                            ) : null}
                        </div>

                        {showHoldPayment && <HoldPaymentComponent totalPaidAmount={totalPaidAmount}
                                                                  callbackState={events.bookTicket}
                        />}

                        <div className="flight-accordion-details mt-3">
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header d-flex align-items-center"
                                        id="panelsStayOpen-headingOne">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="fare-rules-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#fare-rules-tab-pane"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="fare-rules-tab-pane"
                                                    aria-selected="true"
                                                >
                                                    Fare Rules
                                                </button>
                                            </li>
                                        </ul>

                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="panelsStayOpen-headingOne"
                                    >

                                        <div className="accordion-body">
                                            <div className="tab-content" id="myTabContent">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="fare-rules-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="fare-rules-tab"
                                                    tabIndex="0">
                                                    <div className={'fare_rule_pop p-2'}>
                                                        <div className={'airline_info'}>
                                                            <div className={'airline_info_details'}>
                                                                <div className="flight-custom-table">
                                                                    <div style={{
                                                                        maxHeight: "400px",
                                                                        overflowY: "scroll"
                                                                    }}>
                                                                        * To view charges, see the fee sections below.
                                                                        <br/>


                                                                        {fareRules?.FareRules?.map((rule, index) => (
                                                                            <div
                                                                                key={index}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: rule.FareRuleDetail.replace(/\n/g, "<br />")
                                                                                }}
                                                                                style={{listStyleType: "disc"}}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-3 mt-4">
                        {/* <ResponseFlightSummary
                            data={response}
                            flightData={flightData}
                            response={responseData}
                            xtravelMarginData={xTravelMargin}
                            callBack={(totalPaidAmount) => {
                                setTotalPaidAmount(totalPaidAmount)
                            }}
                            convinceAmount={responseData.convinceFees}
                            xtravelMarginDataCallback={(marginData) => {
                                setXTravelMargin(marginData)
                            }}
                        />*/}
                        <ResponseFlightSummary
                            data={response}
                            flightData={flightData}
                            response={responseData}
                            xtravelMarginData={xTravelMargin}
                            callBack={(totalPaidAmount) => {
                                setTotalPaidAmount(totalPaidAmount)
                            }}
                            convinceAmount={responseData.convinceFees}
                            xtravelMarginDataCallback={(marginData) => {
                                setXTravelMargin(marginData)
                            }}
                        />
                    </div>
                </div>
            </div>

            {fareState.visible ?
                <ChangeBookPrice
                    {...fareState}
                    xtravelMarginData={xTravelMargin}
                    xtravelMarginDataCallback={(marginData) => {
                        setXTravelMargin(marginData)
                    }}
                    visible={fareState.visible}
                    onSubmit={(resp, newFare, fareMargin) =>
                        events.bookTicket(resp, newFare, fareMargin)
                    }
                    onClose={() => setFareState({
                        oldFare: {},
                        newFare: {},
                        quoteFare: {},
                        visible: false,
                    })}
                    onRelease={events.releasePnr}
                />
                : null
            }

            {/*{fareState.visible ?*/}
            {/*    <ChangePrice {...fareState}*/}
            {/*                 onSubmit={events.submitChangePriceModal}*/}
            {/*                 onClose={events.closeChangePriceModal}/> : null}*/}
        </section>
    )
}
export default Success
