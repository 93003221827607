import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const Events = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <>
            <section className="about-banner">
                <div className="spacing-60">
                    <div className="container">
                        <div className="col-lg-10 mx-auto col-12">
                            <div className="row row-gap-3 align-items-center">
                                <div className="col-lg-5 me-auto col-12">
                                    <h2 className="m-0">Stay Ahead with X Travel World’s Exclusive Events</h2>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <p className="m-0">Stay connected—explore opportunities with X Travel World at our upcoming events</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="/images/event-banner.jpg" className="w-100"/>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">We actively participate in events that bring together the best in the travel industry</h3>
                </div>
            </section>

            <section className="events spacing-60 pb-0">
                <div className="container">
                    <div className="head text-center mb-5">
                        <h3>Our Events</h3>
                    </div>

                    <div className="row row-gap-4">
                        {/* Event Card */}
                        {[...Array(9)].map((_, index) => (
                            <div className="col-lg-4 col-12" key={index}>
                                <div className="card border-0 p-0">
                                        <span>
                                            <img
                                                src="https://images.pexels.com/photos/716276/pexels-photo-716276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                alt={`Event ${index + 1}`}
                                            />
                                        </span>
                                    <h5>
                                        <a href="#">Event Name {index + 1}</a>
                                    </h5>
                                    <p className="m-0">
                                        <img src="../images/calendar-icon.png" alt="calendar icon"/> 23rd September,
                                        2024
                                    </p>
                                    <p className="m-0">
                                        <img src="../images/time-icon.png" alt="time icon"/> 10:00 AM onwards
                                    </p>
                                    <p className="m-0">
                                        <img src="../images/location-icon.png" alt="location icon"/> London, UK
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default Events;
