import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const PrivacyPolicy = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <section className="about-banner">

                <img src="../images/about-banner.jpg" className="w-100"/>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">At X Travel World, we are committed to protecting your privacy and ensuring that your personal and business information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our platform and services.</h3>
                </div>
            </section>




            <section className="our-values">
                <div className="container">


                    <div className="row row-gap-3 mt-5">
                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">1. Information We Collect</h5>
                                <p className="mb-0">We collect the following types of information:</p>
                                <ul className="ps-3 text-secondary listing">

                                    <li > <strong>Personal Information:  </strong> Name, business name, contact details (email, phone number), address, and payment information.</li>
                                    <li > <strong>Business Information:  </strong> Business type, industry, and registration details for agent verification purposes.</li>
                                    <li > <strong>Usage Data:  </strong> Information about how you interact with our platform, including IP address, browser type, device type, and usage patterns.</li>



                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">2. How We Use Your Information</h5>
                                <p className="mb-0">We use the information collected for the following purposes:</p>
                                <ul className="ps-3 text-secondary listing">

                                    <li>To provide you with access to our platform and services.</li>
                                    <li>To process and confirm bookings for flights and hotels.</li>
                                    <li>To communicate important updates, promotions, or changes related to your bookings.</li>
                                    <li>To improve our platform and services by analyzing user feedback and usage data.</li>
                                    <li>To comply with legal and regulatory requirements.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">3. How We Protect Your Information</h5>
                                <ul className="ps-3 text-secondary">
                                    <li>We implement industry-standard security measures to protect your personal and business information from unauthorized access, loss, or disclosure. This includes encryption, secure servers, and regular security audits. While we take all reasonable steps to protect your data, no method of transmission over the internet is 100% secure.</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">4. Sharing Your Information</h5>
                                <p className="mb-0">We do not sell, rent, or trade your personal information to third parties. However, we may share your information with:</p>
                                <ul className="ps-3 text-secondary listing">

                                    <li><strong>Service Providers: </strong>  Third-party companies that assist us in delivering our services, such as payment processors, airlines, and hotels.</li>

                                    <li ><strong>Legal Compliance: </strong>  If required by law, we may disclose your information to comply with legal obligations, regulations, or government requests.</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">5. Cookies and Tracking Technologies</h5>
                                <ul className="ps-3 text-secondary ">
                                    <li>Our website uses cookies to improve user experience, track website usage, and personalize content. You can adjust your browser settings to block cookies or alert you when cookies are being sent, but this may affect your ability to use some features of the platform.</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">6. Data Retention</h5>
                                <ul className="ps-3 text-secondary ">
                                    <li>We retain your personal and business information only for as long as necessary to provide you with our services, fulfill legal obligations, and resolve disputes. Once your data is no longer needed, we will securely dispose of it.</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">7. Your Rights</h5>
                                <p className="mb-0">You have the right to:</p>
                                <ul className="ps-3 text-secondary listing">

                                    <li>Access and update your personal information.</li>
                                    <li>Request the deletion of your data (subject to any legal obligations we may have to retain it).</li>
                                    <li>Opt-out of receiving marketing communications from us at any time.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark ">8. Third-Party Websites</h5>
                                <ul className="ps-3 text-secondary ">
                                    <li>Our platform may contain links to third-party websites that are not operated by <strong>X Travel World</strong>. We are not responsible for the privacy practices of these websites and encourage you to review their privacy policies.</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark ">9. Children’s Privacy</h5>
                                <ul className="ps-3 text-secondary ">
                                    <li>Our platform is not intended for individuals under the age of 18, and we do not knowingly collect personal information from children. If we learn that we have inadvertently collected data from a child, we will take steps to delete such data.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">10. Changes to This Privacy Policy</h5>
                                <ul className="ps-3 text-secondary ">
                                    <li><strong>X Travel World</strong> reserves the right to update or change this Privacy Policy at any time. Any changes will be posted on this page, and we will notify you via email or a notice on our platform.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">11. Contact Us</h5>
                                <p className="mb-0">If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
                                <ul className="ps-3 text-secondary listing">

                                    <li><strong>Email:</strong> enquiry@xtravelworld.com</li>
                                    <li ><strong>Phone:</strong> 1800-102-0340</li>
                                    <li ><strong>Address:</strong> SCO 178, 2nd floor, Sector 38C, Chandigarh, 160036
                                        140306 (INDIA)</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default PrivacyPolicy;
