import React, {useEffect, useState} from "react"
import {useSearchParams, useLocation} from "react-router-dom";
import {ccAvenueCancelFxn} from "./action";
import {useDispatch} from "react-redux";

const PaymentCancel = (props) => {
    let dispatch = useDispatch()
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState("Processing...");
    useEffect(() => {
        const searchParams1 = new URLSearchParams(location.search);
        const encResp1 = searchParams1.get("encResp");
        const encResp = searchParams.get("encResp");
        const orderId = searchParams.get("orderId");
        if (encResp) {
            getPaymentResp(encResp);
        }
    }, [searchParams]);

    const getPaymentResp = async (encResp) => {
        let resp = await dispatch(ccAvenueCancelFxn({encResp}))
        if (resp && resp.data && resp.data.payment_status) {
            setStatus(resp.data.payment_status);
        }
    }


    return (
        <div>
            <h2>Payment Status</h2>
            <p>{status}</p>
        </div>
    );
}
export default PaymentCancel
