const initialState = {
    currentUser: {},
    isLoading: false,
    activePackages: [],
    isBalanceLoading: false
}

export default (state = initialState, action) => {
    switch (action && action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.user
            }

        case 'LOGOUT':
            return {
                ...state,
                currentUser: {},
                activePackages: []
            }
        case 'SHOW_LOADER': {
            return {
                ...state,
                isLoading: true
            }
        }
        case 'HIDE_LOADER': {
            return {
                ...state,
                isLoading: false
            }
        }
        case 'RIGHTS': {
            return {
                ...state,
                rights: action.rights
            }
        }
        case 'LOAD_USER_BALANCE': {
            return {
                ...state,
                isBalanceLoading: action.value
            }
        }
        case "SYNC_STORAGE":
            return {...state}; // ✅ Forces a re-render when storage changes
        default:
            return state
    }
}
