import React from "react";
import Slider from "react-slick";
import RegisterBanner from "../components/pageComponents/registerBanner";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";

const RefundPolicy = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <section className="about-banner">

                <img src="../images/about-banner.jpg" className="w-100"/>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">At X Travel World, we strive to provide excellent service and ensure that our B2B clients have a seamless experience when booking flights and hotels. However, we understand that sometimes cancellations and refunds may be necessary. Below are the details of our refund policy:

                    </h3>
                </div>
            </section>




            <section className="our-values">
                <div className="container">


                    <div className="row row-gap-3 mt-5">
                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">1. Booking Cancellation</h5>
                                <ul className="ps-3 text-secondary listing">

                                    <li>Cancellation requests must be made in accordance with the terms set by the airline, hotel, or service provider at the time of booking.</li>

                                    <li>The eligibility for a refund and the refund amount depends on the cancellation policy of the specific flight or hotel provider.</li>

                                    <li><strong>X Travel World</strong> will assist in processing the cancellation and refund request, but we are not responsible for delays or discrepancies resulting from the policies of the service providers.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">2. Refund Eligibility</h5>
                                <ul className="ps-3 text-secondary listing">
                                    <li ><strong>Refundable Bookings: </strong> Some bookings may be eligible for a full or partial refund based on the provider’s cancellation and refund policy.</li>

                                    <li ><strong>Non-Refundable Bookings: </strong> Certain flights or hotel bookings may be non-refundable, depending on the terms set by the airline or hotel.</li>
                                    <li ><strong>Refund Requests:</strong>  Refund requests should be made within the time frame specified by the service provider to ensure eligibility. We encourage you to review each provider’s refund policy during the booking process. </li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">3. Processing Time</h5>
                                <ul className="ps-3 text-secondary listing">

                                    <li>Refunds are typically processed within 7 to 14 business days, depending on the service provider and payment method used.</li>
                                    <li>Please note that it may take additional time for the funds to reflect in your account due to processing by financial institutions.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">4. Refund Method</h5>
                                <ul className="ps-3 text-secondary listing">
                                    <li>Refunds will be credited to the original payment method used for the booking, unless otherwise specified.</li>
                                    <li>In the case of a booking made using a third-party payment provider, the refund may be processed according to their terms.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark ">5. Service Fees</h5>
                                <ul className="ps-3 text-secondary listing">
                                    <li>Service fees paid for booking through <strong>X Travel World</strong> are non-refundable, unless the booking is canceled due to an error on our part or the service provider.</li>
                                    <li>If a refund is issued for a booking, the service fee will not be refunded.</li>

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">6. Special Circumstances</h5>
                                <ul className="ps-3 text-secondary listing">

                                    <li><strong>Force Majeure:</strong> In case of unforeseen circumstances such as natural disasters, pandemics, or government travel restrictions, <strong>X Travel World</strong> will assist in obtaining a refund or credit for affected bookings, subject to airline or hotel policies.</li>
                                    <li ><strong>Booking Errors:</strong> If a booking was made in error or if you encounter issues related to incorrect booking information, please contact us immediately. We will work with you to resolve the issue with the service provider.</li>


                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">7. Contacting Us for Refunds</h5>

                                <ul className="ps-3 text-secondary listing">
                                    <li>To initiate a refund request, please contact our support team at:</li>
                                    <li><strong>Email:</strong> enquiry@xtravelworld.com</li>
                                    <li ><strong>Phone:</strong> 1800-102-0340</li>
                                    <li ><strong>Address:</strong> SCO 178, 2nd floor, Sector 38C, Chandigarh, 160036
                                        140306 (INDIA)</li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="card border-0 shadow-sm rounded-4 p-4">
                                <h5 className="fw-bold text-dark">8. Refund Denial</h5>
                                <ul className="ps-3 text-secondary listing">
                                    <li>Refund requests may be denied if the booking is found to have been made outside of the specified cancellation window, or if it falls under non-refundable categories as outlined by the airline or hotel.</li>

                                </ul>
                            </div>
                        </div>



                    </div>
                </div>
            </section>


            <DownloadApplications/>
            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default RefundPolicy;
