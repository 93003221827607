import React, {useEffect, useState, useCallback} from "react";
import {useDispatch} from "react-redux";
import {singleTicketFxn} from "../flightSearch/actions";
import {useLocation} from "react-router-dom";
import {showLoader, hideLoader} from "../../actions/loader";
import {displayDate, getTotalDurationSegments, passengerTypeObject} from "../../components/utils/appUtils";
import moment from "moment";
import AllStopsDetailsPopover from "../flightSearchResult/components/AllStopsDetailsPopover";
import FlightImg from "../../components/flightImg";
import {Card} from "../../components/Elements";

const ErrorPage = () => {
    const location = useLocation();
    const {data} = location.state || {};
    const dispatch = useDispatch();

    let ticketId = window.location.pathname.split("/").pop();

    const [response, setResponse] = useState({});
    const [quoteFare, setQuoteFare] = useState({});
    const [segments, setSegments] = useState([]);
    const [passengers, setPassengers] = useState([]);

    const loadData = useCallback(async () => {
        try {
            dispatch(showLoader());
            let {data} = await dispatch(singleTicketFxn({ticketId}));
            dispatch(hideLoader());
            if (data && (data.bookingStatus || data.responseStatus)) {
                let {fareQuote} = data;
                setPassengers(data["Passengers"] || []);
                setResponse(data);
                setQuoteFare(fareQuote)
                if (fareQuote && fareQuote.Segments && fareQuote.Segments.length) {
                    if (data.bookingType == "Inbound") {
                        setSegments(fareQuote.Segments[1] || []);
                    } else {
                        setSegments(fareQuote.Segments[0]);
                    }
                }
            }
        } catch (error) {
            console.error("Error loading data:", error);
            dispatch(hideLoader());
        }
    }, []);

    useEffect(() => {
        if (!data) {
            loadData();
        }
    }, [data, loadData]);


    const FlightAddress = ({data}) => {
        return (
            <>
                <small className="d-block">
                    {data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}
                    {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
                </small>
                <small className="d-block">
                    {data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}
                    {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                </small>
            </>
        )
    }

    const NonStopComponent = (props) => {
        let {data} = props;
        let {Segments, FareRules: fareRules} = data;
        let allSegmentData = Segments[0];
        let segmentStarting = allSegmentData[0];
        let segmentEnding = allSegmentData[allSegmentData.length - 1];
        return (
            <>
                <div className={''}>
                    <ul>
                        <li className="p-0">
                          <span className="logo">
                            <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                          </span>
                            <h6>
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                                <small className="d-block">
                                    {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                                </small>
                                <small className="d-block">
                                    {data['IsLCC'] ? "LCC" : "Non LCC"}
                                </small>
                            </h6>
                            <div className="d-flex align-items-center" style={{gap: 30}}>
                                <h6>
                                    {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                        {displayDate(segmentStarting.Origin.DepTime)}
                                    </> : null}
                                    {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                                </h6>
                                <span style={{width: 100}}>
                                    <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                                        <p className={'non-stop-p'}>Non-Stop</p>
                                        <br/>
                                    </AllStopsDetailsPopover>
                                    <FlightImg/>
                            </span>
                                <h6>
                                    {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                        {displayDate(segmentEnding.Destination.ArrTime)}
                                    </> : null}
                                    {segmentEnding.Destination ?
                                        <FlightAddress data={segmentEnding.Destination}/> : null}
                                </h6>
                                <h6>
                                    {getTotalDurationSegments(allSegmentData)}
                                    <small className="d-block">Economy Class</small>
                                    <small className="d-block">
                                        {data.IsRefundable ? "Refundable" : "Non Refundable"}
                                    </small>
                                </h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const WithStopComponent = (props) => {
        let {data} = props;
        let {Segments, FareRules: fareRules} = data;
        let allSegmentData = Segments[0];
        let segmentStarting = allSegmentData[0];
        let segmentEnding = allSegmentData[allSegmentData.length - 1];
        let originObj = fareRules[0];
        let destinationObj = fareRules[fareRules.length - 1];

        let stopsComponent = (
            <>
                <span><FlightImg/></span>
                <h6>
                    <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                        {allSegmentData.length - 1} Stop(s)
                    </AllStopsDetailsPopover>
                    <small className="d-block">
                        {originObj && originObj.Origin ? originObj.Origin : ""}
                        &nbsp;-&nbsp;
                        {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                    </small>
                    <small className="d-block">
                        {getTotalDurationSegments(allSegmentData)}
                    </small>
                </h6>
                <span><FlightImg/></span>
            </>
        )

        return (
            <>

                <ul>
                    <li className="p-0">
                       <span className="logo">
                            <img src={`../airlineLogos/${data.AirlineCode}.gif`} alt=""/>
                        </span>
                        <h6>{segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small>
                            <small className="d-block">
                                {data['IsLCC'] ? "LCC" : "Non LCC"}

                            </small>
                        </h6>

                        <div
                            className="d-flex align-items-center"
                            style={{gap: '30px'}}>
                            <h6>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h6>

                            {stopsComponent}

                            <h6>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}

                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h6>


                            {allSegmentData.length == 1 ? <h6>
                                {getTotalDurationSegments(allSegmentData)}
                                <small
                                    className="d-block">Economy
                                    Class</small>
                                <small className="d-block">
                                    {data.IsRefundable ? "Refundable" : "Non Refundable"}

                                </small>
                            </h6> : null}
                        </div>
                    </li>
                </ul>


            </>
        )
    }

    const ContactDetailsComponent = (props) => {
        const {passengers} = props;
        console.log(passengers, 'passenger')
        const getPassengerDetails = (passenger) => {
            if (passenger) {
                const {
                    FirstName: firstName,
                    LastName: lastName,
                    Gender: gender,
                    DateOfBirth: dateOfBirth,
                    ContactNo,
                    Email, PaxType,
                    MealDynamic, Baggage, SeatDynamic
                } = passenger;
                return (
                    <Card className={"mb-2"}>
                        <div className={'row'}>
                            <div className={'col-md-4'}>
                                <b>Name</b>: {firstName && lastName ? `${firstName} ${lastName}` : ""}<br/>
                                <b>Gender</b>: {gender === 2 ? "Female" : "Male"}<br/>
                                <b>D.O.B</b>: {moment(dateOfBirth).format("DD-MMM-YYYY")}<br/>
                            </div>
                            <div className={'col-md-5'}>
                                {PaxType ? <>
                                    <b>
                                        Type </b>: {passengerTypeObject[PaxType]}<br/>
                                </> : null}
                                {ContactNo ? <>
                                    <b>Contact No</b>: {ContactNo}<br/>
                                </> : null}
                                {Email ? <>
                                    <b>Email ID</b>: {Email}<br/>
                                </> : null}
                            </div>
                            {
                                ((MealDynamic && MealDynamic.length > 0) || (Baggage && Baggage.length > 0) || (SeatDynamic && SeatDynamic.length > 0)) ?
                                    <div className={'col-md-12 mt10'}>
                                        <h5>Extra services - </h5>
                                        {MealDynamic && MealDynamic.length > 0 ? (
                                            <>
                                                <b className={'fw-bold'}>Meal</b>: {MealDynamic.map(item => `${item.AirlineDescription}kg (Rs. ${item.Price})`).join(", ")}<br/>
                                            </>
                                        ) : null}
                                        {Baggage && Baggage.length > 0 ? (
                                            <>
                                                <b className={'fw-bold'}>Baggage</b>: {Baggage.map(item => `${item.Weight}kg (Rs. ${item.Price})`).join(", ")}<br/>
                                            </>
                                        ) : null}
                                        {SeatDynamic && SeatDynamic.length > 0 ? (
                                            <>
                                                <b className={'fw-bold'}>Seat
                                                    number</b>: {SeatDynamic.map(item => `${item.RowNo}${item.SeatNo} (Rs. ${item.Price})`).join(", ")}<br/>
                                            </>
                                        ) : null}
                                    </div> :
                                    <div className={'col-md-12 mt10 d-flex'}>
                                        <h5>Extra services - <span className="text-danger">No Extra Services</span></h5>
                                    </div>
                            }
                        </div>
                    </Card>
                );
            }
        };

        return (
            <>
                <Card title={'Passengers'} round={12}>
                    {passengers && passengers.length ? passengers.map((item) => {
                        return getPassengerDetails(item)
                    }) : null}
                </Card>
            </>
        );
    };


    return (
        <>
            <section className="agent-with-us">
                <div className="container mt-5 mb-5">
                    <div className="card mt-4 mb-3">
                        <div className="card-body">

                            {/* Error Message */}
                            <div className="">
                                <h5 className="text-primary">Error Response</h5>
                                <div className={'d-flex mt-4 fw-bold'}>
                                    <img src="../images2/delete.png"
                                         className=" errorPageIcons ml10 mr10 mb-4"
                                         alt="Error"
                                    />
                                    <h5 className="color-red">
                                        {response?.error?.ErrorMessage || "Error"}
                                    </h5>
                                </div>
                            </div>

                            {/* Flight Details Block */}
                            <div className="mb-4">
                                <h5 className="text-primary">Flight Details</h5>
                                <div className="track-flights">
                                    {segments && segments.length ?
                                        <>
                                            {segments.length > 1 ? <WithStopComponent data={quoteFare}/> :
                                                <NonStopComponent data={quoteFare}/>}
                                        </> : null
                                    }
                                </div>
                            </div>

                            {/* Passengers Details */}
                            <div className="mt-4">
                                {passengers.length > 0 ? (
                                    <div className="flight-accordion-details mt-3">
                                        <ContactDetailsComponent
                                            passengers={passengers}
                                        />
                                    </div>
                                ) : (
                                    <p>
                                        <img src="../images2/delete.png"
                                             className=" errorPageIcons ml10 mr10 mb-4"
                                             alt="Error"
                                        />No passengers found.</p>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ErrorPage;
