import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {Form, InputBox, notification} from "../../components/Elements";
import moment from "moment";
import DownloadApplications from "../../components/pageComponents/downloadApplications";
import Reviews from "../../components/pageComponents/reviews";
import RegisterBanner from "../../components/pageComponents/registerBanner";
import {checkCertificationToken, uploadCertificate} from "./actions";
import {push} from "connected-react-router";

const initState = {
    certificate: {},
    certificateToken: '',
    profileKey: moment()
}
const UploadCertificate = (props) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(initState);
    console.log(state, 'state')

    useEffect(() => {
        const checkIsLoggedIn = async () => {
            let {pathname} = window.location;
            let token = pathname.split("/").pop();
            let resp = await dispatch(checkCertificationToken({certificateToken: token}));
            if (resp && resp.success) {
                setState((prevState) => ({
                    ...prevState,
                    certificateToken: token
                }));
            } else {
                alert(resp.message);
                dispatch(push("/login"));
            }
        };

        checkIsLoggedIn();
    }, [dispatch]);

    const chooseProfileDocument = (e) => {
        let {files} = e.target;
        if (files && files.length) {
            setState((prevState) => ({
                ...prevState,
                certificate: files[0],  // Ensure proper merging
                profileKey: moment()  // Reset key to allow re-selection
            }));
        }
    };

    const handleSubmit = async () => {
        let {certificate, certificateToken} = state
        if (!certificate || (certificate && !certificate.name)) {
            notification.warning({
                message: 'Required',
                description: 'Please choose certificate.'
            })
            return
        }
        let fd = new FormData()
        let obj = {certificateToken}
        fd.append('obj', JSON.stringify(obj))
        console.log(certificate, 'certificate')
        console.log(certificate.name, ' certificate.name')
        if (certificate && certificate.name) {
            console.log('============', certificate)
            fd.append('certificate', certificate)
        }
        let resp = await dispatch(uploadCertificate(fd))
        if (resp && resp.success) {
            alert(resp.message)
            setState({
                certificate: {},
                certificateToken: '',
                profileKey: moment()
            });
            window.location.reload()
        }
    }


    return (
        <>
            {/* Begin page content */}
            <section className="about-banner">
                <div className="spacing-60">
                    <div className="container">
                        <div className="col-lg-10 mx-auto col-12">
                            <div className="row row-gap-3 align-items-center">
                                <div className="col-lg-6 col-12">
                                    <h2 className="m-0">
                                        Verify Your Credentials & Unlock Opportunities
                                    </h2>
                                </div>
                                <div className="col-lg-5 ms-auto col-12">
                                    <p className="m-0">
                                        Upload your certification to get started and access exclusive benefits.
                                        Designed for agents and partners looking to expand their business with verified
                                        credentials.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="form-section">
                <img
                    src="/images/agent-banner.jpg"
                    className="banner-login"
                />
                <div className="container">
                    <div className="row row-gap-3">
                        <div className="col-lg-5 col-md-6 col-12 me-auto">
                            <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                <div className="main-head mb-3">
                                    <h5 style={{color: "#d71a21"}}>Upload Certificate</h5>
                                </div>
                                <Form>
                                    <InputBox title={`Certificate`}>
                                        <input type={'file'}
                                               className={'form-control'}
                                               name={'certificate'}
                                               id={'certificate'}
                                               key={state.profileKey}
                                               onChange={(e) => {
                                                   chooseProfileDocument(e)
                                               }}/>
                                    </InputBox>
                                    <Form.Item>
                                        <button className={'btn btn-primary mt10  font12'}
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                        >
                                            Upload Certificate
                                        </button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DownloadApplications/>

            <Reviews/>

            <RegisterBanner/>
        </>

    )
}
export default Form.create()(UploadCertificate)
