import React, {useEffect, useState} from "react"
import {Card, Input, InputBox, notification} from "../../components/Elements";
import {useDispatch} from "react-redux";
import {getUser} from "../authications/action";
import {addPaymentFxn, ccAvenuePaymentFxn} from "../payment/action";
import ReactHtmlParser from "react-html-parser";
import {ccAvenueIcon} from "../../components/imgComp"
import {fixed2Digit} from "../../components/utils/appUtils";

const ViewInfo = (props) => {
    let {title, value} = props;
    return (
        <div className={'mb-3'}>
            <span className={'text-gray font12'}>{title}</span>
            <div className={'f-16'}>
                <b>{value}</b>
            </div>
        </div>
    )
}

let initState = {
    amount: null,
    paymentBy: "CCAvenue"
}

const AddDeposit = (props) => {
    let [userData, setUserData] = useState({})

    let dispatch = useDispatch()
    useEffect(() => {
        loadUserData()
    }, [])
    let loadUserData = async () => {
        let {data} = await dispatch(getUser());
        setUserData(data)
    }
    let [state, setState] = useState(initState);
    let [ccForm, setCcForm] = useState("")

    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        addPayment: async (e) => {
            e.preventDefault();
            if (!state.amount) {
                notification.warning({message: "Please enter amount"});
                return
            }
            let obj = {
                ...state,
                email: userData.email,
                name: userData.name,
                phoneNo: `${userData.countryCode} ${userData.mobile}`,
                paymentFrom: "deposit"
            }
            let resp = await dispatch(addPaymentFxn(obj));
            if (resp && resp.data) {
                events.loadPayment(resp.data._id)
            }
        },
        loadPayment: async (id) => {
            let respForm = await dispatch(ccAvenuePaymentFxn(id));
            setCcForm(respForm)
        }
    }

    useEffect(() => {
        if (ccForm) {
            const form = document.getElementById("nonseamless");
            form.submit()
        }
    }, [ccForm])

    return (
        <>

            <div className={'m-4'}>
                <h4 className={'title'}>Add Deposit</h4>
                {userData && userData._id ?
                    <div className={'row'}>
                        <div className={'col-md-4'}>
                            <Card>

                                <div className={'subTypeWrapper'}>
                                    Your Balance :
                                    <h6>₹ {fixed2Digit(userData && userData.xTravelWallet ? userData.xTravelWallet : 0)}</h6>
                                </div>
                                 <hr/>
                                <div className={'subTypeWrapper'}>
                                    <p className={'subTypeWrapper__text'}>
                                        {/*Please Select Preferred Option to Proceed*/}
                                        Please Select Preferred Option to Deposit Amount
                                    </p>
                                    <div className={'subTypeWrapper__box paySelect__selected'}>
                                        <div className={'paySelect'}>
                                            <input type="radio" id="paymentMedium_0" name="mediumRadio"
                                                   checked={state.paymentBy === "CCAvenue"}
                                                   className="paySelect__input" value="CCAvenue"/>
                                            <label className={'paySelect__label'}>
                                                <img src={ccAvenueIcon} className={'paySelect__img'}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <InputBox title={'Enter Deposit Amount'}>
                                    <Input size={'small'} placeholder={'Enter deposit amount'}
                                           value={state.amount}
                                           onChange={(value) => {
                                               events._updateState({amount: value})
                                           }}
                                    />
                                </InputBox>
                                <a className="btn btn-primary mt-3 w-100 search-btn" onClick={events.addPayment}>
                                    Submit
                                </a>
                                <br/>
                                {ccForm ? ReactHtmlParser(ccForm) : null}
                            </Card>
                        </div>

                        <div className={'col-md-7 ms-3'}>
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <div className="card shadow-sm">
                                        <div className="card-body font12">
                                            <ViewInfo title={'Name'} value={userData.name}/>
                                        </div>
                                        </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className="card shadow-sm">
                                        <div className="card-body font12">
                                    <ViewInfo title={'Company Name'} value={userData.companyName}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className="card shadow-sm">
                                        <div className="card-body font12">
                                    <ViewInfo title={'Email'} value={userData.email}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className="card shadow-sm">
                                        <div className="card-body font12">
                                    <ViewInfo title={'Mobile no'} value={`${userData.countryCode} ${userData.mobile}`}/>
                                        </div>
                                    </div>
                                        </div>
                                <div className={'col-md-12'}>
                                    <div className="card shadow-sm">
                                        <div className="card-body font12">
                                    <ViewInfo title={'Address'} value={userData.address}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}
            </div>
        </>
    )
}
export default AddDeposit
