import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {ccAvenueSuccessFxn, singlePaymentFxn} from "./action";
import {useDispatch, useSelector} from "react-redux";
import Background from "../../assets/searchImg/search-pattern.png";
import Approved from "../../assets/img/approved.png";
import Delete from "../../assets/img/delete.png"
import {getWalletBalanceFxn} from "../flightSearch/actions";
import $ from "jquery";
import {Socket2} from "../../socket";
import {fixed2Digit} from "../../components/utils/appUtils";

const PaymentResponse = (props) => {
    let {user} = props;
    let dispatch = useDispatch();
    const location = useLocation();


    let [state, setState] = useState({});
    let [walletBalance, setWalletBalance] = useState(0)
    const events = {
        loadBalance: async () => {
            let {success, xTravelWallet, data} = await dispatch(getWalletBalanceFxn());
            if (success) {
                setWalletBalance(xTravelWallet);
            }
        },
    }


    useEffect(() => {
        getPaymentEvent();
        events.loadBalance()
    }, [location]);

    const getPaymentEvent = async () => {
        let {pathname} = window.location;
        let paymentId = pathname.split("/").pop();
        let {data, success} = await dispatch(singlePaymentFxn({paymentId}));
        if (success && data && data.status == "Paid") {
            Socket2.emit('refresh_balance', {userId: state.userId})
            events.loadBalance()
            setTimeout(() => {
                window.close();
            }, 2000)
        } else {
            Socket2.emit('refresh_balance', {userId: state.userId})
            setTimeout(() => {
                window.close();
            }, 2000)
        }
        setState(data);
    };


    const closeWindowEvent = () => {
        window.close();
    }


    useEffect(() => {
        if (state.paymentFrom == "ticketing") {
            $("header").hide(); // Hides the header when component mounts
            $("footer").hide(); // Hides the header when component mounts
            // $(".custom-pay-card").css({marginTop: 0});
            $("main").css({paddingTop: 50});
            return () => {
                $("header").show(); // Ensures the header reappears when the component unmounts
                $("footer").show(); // Ensures the header reappears when the component unmounts
                $("main").css({paddingTop: 120});
            };
        }
    }, [state.paymentFrom]);

    let paymentMessage, paymentImage, messageColor;
    if (state.paymentStatus === "Success") {
        paymentMessage = "Payment Successful";
        paymentImage = Approved;
        messageColor = "#08aa08";
    } else if (state.paymentStatus === "failed") {
        paymentMessage = "Payment Failed";
        paymentImage = Delete;
        messageColor = "#aa0808";
    } else {
        paymentMessage = "Payment Pending";
        paymentImage = Delete;
        messageColor = "#aaaa08";
    }
    return (
        <div>
            <div style={{backgroundImage: `url(${Background})`}}>
                <div className={'custom-pay-card'}>
                    <div className="row row-gap-3">
                        <div className="col-lg-12 col-12 text-center">
                            {state.trackingId ?
                                <img src={paymentImage} alt={paymentMessage} width={140}/> : null}
                            <h3 className={'mt-4 mb-3'} style={{color: messageColor}}>
                                <strong>{paymentMessage}</strong>
                            </h3>
                            <div style={{marginTop: 100}}>
                                <h6>Deposited Amount</h6>
                                <h5><b>Rs. {fixed2Digit(state.amount)} </b></h5>
                                <div className="payment-info-card">
                                    <div><strong>Transaction ID:</strong><span>{state.trackingId}</span></div>
                                    <div><strong>Agent Name:</strong> <span>{state.name}</span></div>
                                    <div><strong>Phone Number:</strong> <span>{state.phoneNo}</span></div>
                                    <div><strong>Email:</strong> <span>{state.email}</span></div>
                                    <div><strong>Date:</strong>
                                        <span>{new Date(state.date).toLocaleString()}</span>
                                    </div>
                                </div>
                                {state.paymentFrom == "ticketing" ?
                                    <div className="d-flex justify-content-center gap-3 mt-4 button-card">
                                        <a className="btn btn-primary" onClick={closeWindowEvent}>Close</a>
                                    </div> :
                                    <div className="d-flex justify-content-center gap-3 mt-4 button-card">
                                        <NavLink to={'/ticket-list'} className="btn btn-primary">Dashboard</NavLink>
                                        <NavLink to={'/flight-search'} className="btn btn-primary">Search
                                            Again</NavLink>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentResponse;
