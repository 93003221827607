import React from "react"
import {Modal} from "../../components/Elements";
import {useSelector} from "react-redux";
import AirportImg from "../../assets/img/airport.png";
import FlightInfoComponent from "../checkout/components/FlightInfoComponent";
import {
    displayDate,
    fixed2Digit,
    getAirlineLogo,
    getTotalDurationSegments,
    minToHours
} from "../../components/utils/appUtils";
import moment from "moment";
import {DomesticRoundTripFlightDetails} from "../checkout/roundTripFlightDetails";
import OneWayFlightInfoBox from "../flightSearchResult/oneWayFlightInfoBox";
import FlightImg from "../../components/flightImg";
import AllStopsDetailsPopover from "../flightSearchResult/components/AllStopsDetailsPopover";
import LccStatus from "../flightSearchResult/components/LccStatus";

const flightDate = (date) => {
    return moment(date).format("dddd, MMMM, Do, YYYY")
}

const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}*/}

            </small>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}*/}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}

                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}

const FlightDestination = (props) => {
    let {flightObj, allSegmentData, totalPaidAmount, segments} = props;
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let stopCount = allSegmentData.length - 1;

    let stopCities = allSegmentData
        .slice(0, stopCount)
        .map(segment => segment.Destination.Airport.CityName)
        .join(", ");

    const getStopLine = (numStops) => {
        return (
            <div className="stop-line">
                <div className="red-line">
                    {Array.from({ length: numStops }).map((_, index) => (
                        <span key={index} className="stop-dot"></span>
                    ))}
                </div>
            </div>
        );
    };
    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h5>
                <small className="d-block">
                    {getTotalDurationSegments(allSegmentData)}
                </small>
                {getStopLine(stopCount)}
                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                    {allSegmentData.length - 1 > 0 ?
                        <small className={'d-block'}>{allSegmentData.length - 1} stop via</small> :
                        <small className={'d-block'}> Non Stop</small>
                    }

                </AllStopsDetailsPopover>
                <small className="d-block">
                    {stopCities}
                    {/*&nbsp;,&nbsp;*/}
                    {/*{destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}*/}
                </small>

            </h5>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>
            <div className="flight-destimation-time">
                <div className="row row-gap-3 align-items-center">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center"
                             style={{gap: '30px'}}>
                            <h6 className="m-0 white">{flightObj.origin}</h6>
                            <span><img src="../images2/plane.png" width="22"
                                       alt="plane"/></span>
                            <h6 className="m-0 white">{flightObj.destination}</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <h6 className="m-0 white">on {flightDate(flightObj.preferredDepartureTime)}</h6>
                    </div>
                    <div className="col-lg-4">
                        {getTotalDurationSegments(allSegmentData)}
                    </div>
                </div>
            </div>


                    <div className="track-flights false mt-3">

                    <ul>
                        <li className="p-0">
                       <span className="logo">
                            <img src={getAirlineLogo(segmentStarting.Airline.AirlineCode)} alt=""/>
                        </span>
                            <h5 style={{whiteSpace: 'normal'}}>{segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                                <small className="d-block">
                                    {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                    {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                                </small>

                            </h5>

                            <div
                                className="d-flex align-items-center"
                                style={{gap: '30px'}}>
                                <h5 style={{whiteSpace: 'normal'}}>
                                    {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                        {displayDate(segmentStarting.Origin.DepTime)}
                                    </> : null}
                                    {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                                </h5>

                                {stopsComponent}

                                <h5 style={{whiteSpace: 'normal'}}>
                                    {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                        {displayDate(segmentEnding.Destination.ArrTime)}
                                    </> : null}

                                    {segmentEnding.Destination ?
                                        <FlightAddress data={segmentEnding.Destination}/> : null}
                                </h5>
                                <div className="card-body">
                                    <h6>Total Amount</h6><br/>
                                    <span className="text-success fw-bold">₹ {fixed2Digit(totalPaidAmount)}</span>
                                </div>


                            </div>
                        </li>

                    </ul>

                </div>

        </>
    )
}

const ConfirmationModal = (props) => {
    const {onYesClick, onNoClick, visible, onClose, bookingType, totalPaidAmount, fareObject, passengerState} = props;
    const {flightObj, traceId, selectedFlight} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        selectedFlight: state.flightsReducer.selectedFlight,
        traceId: state.flightsReducer.traceId,
    }))

    return (
        <Modal visible={visible} width={'47%'} onClose={onClose}

               title={''}>
            <div className={'mt-1 alert-popup'}>
                {/*<div className={'mb-4'}>*/}
                {/*    <img src={AirportImg} alt={'airportimg'} width={'30%'}/>*/}
                {/*</div>*/}
                <p className={'mb-4'}>
                    <div className={'accordion-flight-track'}>
                        {flightObj.journeyType == 2 && selectedFlight.mappedInBoundFlights ?
                            <div className={'row'}>
                                {selectedFlight.mappedInBoundFlights || selectedFlight.mappedOutBoundFlights ? (
                                    <>
                                        <div className={'col-md-12'}>
                                            {selectedFlight.mappedOutBoundFlights && bookingType == 'Outbound' && (
                                                <>
                                                    <FlightDestination
                                                        flightObj={flightObj}
                                                        segments={selectedFlight.mappedOutBoundFlights['Segments']}
                                                        allSegmentData={selectedFlight.mappedOutBoundFlights['Segments'][0]}
                                                        totalPaidAmount={totalPaidAmount}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div className={'col-md-12 mt-3'}>

                                            {selectedFlight.mappedInBoundFlights && bookingType == 'Inbound' && (
                                                <>
                                                    <FlightDestination
                                                        flightObj={flightObj}
                                                        segments={selectedFlight.mappedInBoundFlights['Segments']}
                                                        allSegmentData={selectedFlight.mappedInBoundFlights['Segments'][0]}
                                                        totalPaidAmount={totalPaidAmount}
                                                    />


                                                </>
                                            )}
                                        </div>
                                    </>
                                ) : null}
                            </div> :
                            <>
                                <FlightDestination
                                    flightObj={flightObj}
                                    segments={selectedFlight['Segments']}
                                    allSegmentData={selectedFlight['Segments'][0]}
                                    totalPaidAmount={totalPaidAmount}
                                />
                            </>
                        }
                    </div>

                </p>
                <h3 className={'mb-4'}>Do you wish to continue booking?</h3>
                <div className="d-flex justify-content-center gap-3 mb-2">
                    <button
                        className="customYesBtn"
                        onClick={onYesClick}
                    >
                        Confirm
                    </button>
                    <button
                        className="customNoBtn"
                        onClick={onClose}
                    >
                        Cancel
                    </button>

                </div>
            </div>

        </Modal>
    )
}
export default ConfirmationModal
