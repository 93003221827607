import {
    ticketListUrl,
    cancelChargesUrl,
    getBookingDetailsUrl,
    bookSeatsUrl,
    singleTicketUrl,
    releasePnrUrl,
    cancelRequestUrl,
    getTicketListBackofficeUrl,
    getTicketRequestUrl,
    reissueTicketUrl, updateRequestStatusUrl, getAndUpdateTicketDetailsUrl, calendarFareUrl
} from "./api";
import {customAxios as axios} from "../../request";
import {hideLoader, showLoader} from "../../actions/loader";
import {flightsFearQuoteUrl} from "../flightSearch/apis";
import {notification} from "../../components/Elements";

export const ticketListFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(ticketListUrl(), config)
    dispatch(hideLoader())
    return data.data;
}

export const getBookingDetailsFxn = (valData) => async (dispatch) => {
    let config = {
        params: {...valData}
    }
    dispatch(showLoader());
    let {data} = await axios.get(getBookingDetailsUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const bookSeatsFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(bookSeatsUrl(), valData)
    console.log(data, '-----req ssr');
    dispatch(hideLoader())
    if (!data.error) {
        // notification.success({
        //     message: data.message || 'Success Book Seat'
        // })
    } else {
        // notification.error({
        //           message: data.message || 'Error'
        //       })
    }
    return data;
}

export const singleTicketFxn = (valData) => async (dispatch) => {
    let config = {
        params: {...valData}
    }
    dispatch(showLoader());
    let {data} = await axios.get(singleTicketUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const releasePnrFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(releasePnrUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const cancelRequestFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(cancelRequestUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}
export const ticketListBackofficeFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(getTicketListBackofficeUrl(), config)
    dispatch(hideLoader())
    return data.data;
}

export const cancelChargesFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(cancelChargesUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    } else {
        // notification.error({
        //     message: data.message || 'Error'
        // })
    }
    return data;
}
export const getTicketRequestFxn = (valData) => async (dispatch) => {
    let config = {
        params: {...valData}
    }
    dispatch(showLoader());
    let {data} = await axios.get(getTicketRequestUrl(), config)
    dispatch(hideLoader())
    return data;
}

export const reissueTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(reissueTicketUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    } else {
        // notification.error({
        //     message: data.message || 'Error'
        // })
    }
    return data;
}

export const updateRequestStatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateRequestStatusUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const getAndUpdateTicketDetailsFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(getAndUpdateTicketDetailsUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const calendarFareFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(calendarFareUrl(), valData)
    dispatch(hideLoader())
    // if (!data.error) {
    //     notification.success({
    //         message: data.message || 'Success'
    //     })
    // } else {
    //     notification.error({
    //         message: data.message || 'Error'
    //     })
    // }
    return data
}




