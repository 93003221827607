import React from "react"

const Error = (props) => {
    let {response, error} = props;
    return (
        <section className="agent-with-us">

        <div className="container mt-5 mb-5">
                <div className="card mt-4 mb-3">
                    <div className="card-body">
                        <div className={'align-center'}>
                            <img src={'../images2/delete.png'} className={'mt-4 mb-4'}/>
                            <div className={'mt-4'}>
                                <h5 className={'color-red'}>
                                    {response && response.error && response.error.ErrorMessage ? response.error.ErrorMessage : (error && error['ErrorMessage'] ? error['ErrorMessage'] : "")}</h5>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </section>
    )
}
export default Error
