import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {DatePicker, Table, Tooltip} from "../../components/Elements";
import Popconfirm from "../../components/Elements/Popconfirm";
import {displayDate, displayDateOnly, longDisplayDate} from "../../components/utils/appUtils";
import {approveAgentFxn, assignedAgentFxn} from "./actions";
import Card from "../../components/Elements/Card";

const AssignedAgentList = () => {
    let currentUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const dispatch = useDispatch();
    let tableRef = useRef()
    let initialFilter = {
        pnr: "",
        bookingDate: "",
        name: '',
        agentName: "",
        ticketId: '',
        flightDate: '',
        status: '',
        fromAirport: '',
        toAirport: '',
        requestId: ''
    }
    const [filters, setFilters] = useState(initialFilter);
    const [totalItems, setTotalItems] = useState(0);
    let initialState = {
        visible: false,
        ticketId: '',
        bookingId: '',
        pnr: '',
    }
    const [requestDrawer, setRequestDrawer] = useState(initialState);

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let countryName = searchParams.get('countryName')
            let stateName = searchParams.get('stateName')
            let cityName = searchParams.get('cityName')
            let pincode = searchParams.get('pincode')
            let name = searchParams.get('name')
            let companyName = searchParams.get('companyName')
            let email = searchParams.get('email')

            let obj = {}
            if (countryName) {
                obj.countryName = countryName
            }
            if (stateName) {
                obj.stateName = stateName
            }
            if (cityName) {
                obj.cityName = cityName
            }
            if (pincode) {
                obj.pincode = pincode
            }
            if (name) {
                obj.name = name
            }
            if (companyName) {
                obj.companyName = companyName
            }
            if (email) {
                obj.email = email
            }
            resolve(obj)
        })
    }


    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.sortField = 'createdAt'
            params.sortOrder = 'decent'
            // let {time} = this.state

            let resp1 = await getParamsForApplicationList()

            if (resp1.countryName) {
                params.countryName = resp1.countryName
            }
            if (resp1.stateName) {
                params.stateName = resp1.stateName
            }
            if (resp1.cityName) {
                params.cityName = resp1.cityName
            }
            if (resp1.pincode) {
                params.pincode = resp1.pincode
            }
            if (resp1.name) {
                params.name = resp1.name
            }
            if (resp1.email) {
                params.email = resp1.email
            }
            if (resp1.companyName) {
                params.companyName = resp1.companyName
            }

            let resp = await dispatch(assignedAgentFxn({
                ...params,
                regExFilters: ['name', 'address', 'mobile', 'email', 'companyName', 'cityName', 'stateName', 'countryName', 'pincode']
            }))
            setTotalItems(resp.total)
            resolve(resp)
        })
    }


    const handleFilterChange = (e) => {
        let {name, value} = e.target;
        if (name == 'pnr') {
            value = value.toUpperCase()
        }
        setFilters((prev) => ({...prev, [name]: value}));
    };

    const applyFilters = () => {
        tableRef.current.reload()
    };

    const clearFilters = () => {
        setFilters(initialFilter)
        setTimeout(() => {
            tableRef.current.reload()
        }, 200)
    };


    const columns = [
        {
            title: '#',
            key: '_id',
            dataIndex: '_id',
            width: 50,
            render: (val, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Created At',
            key: 'createdAt',
            // searchDateName: 'createdAt',
            width: 100,
            render: (value) => {
                return value ? displayDateOnly(value) : ""
            }
        },
        {
            title: 'Company Name',
            key: 'companyName',
            dataIndex: 'companyName',
            width: 150,
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 110,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 120,
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile',
            key: 'mobile',
            width: 110,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 150,
        },
        {
            title: 'State',
            dataIndex: 'stateName',
            key: 'stateName',
            width: 100,
        },
        {
            title: 'City',
            dataIndex: 'cityName',
            key: 'cityName',
            width: 100,

        },
        {
            title: 'Country',
            key: 'countryName',
            width: 100,
        },

        {
            title: 'Status',
            key: 'block',
            width: 100,
            render: (item, record) => {
                return (
                    <>
                        {item ? <label className='label label-danger sm'>Block</label> : null}
                        {!item ? <label className='label label-success-green sm'>Active</label> : null}
                    </>
                )
            }
        },

        {
            title: 'Action',
            key: '_id',
            dataIndex: '_id',
            width: 120,
            render: (val, record) => {
                let {deleted, referBy} = record;
                return (
                    <React.Fragment>

                    </React.Fragment>
                )
            }
        }
    ]

    return (
        <>
            <Card title={`Assigned Agents List(${totalItems ? totalItems : 0})`}>
                <div className="row">
                    <div className="col-lg-12">
                        {/*<div className="mb-4 col-lg-12 d-flex flex-wrap">
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="name"
                            className="form-control font12"
                            value={filters.name}
                            onChange={handleFilterChange}
                            placeholder="Filter by first name of passengers"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="agentName"
                            className="form-control font12"
                            value={filters.agentName}
                            onChange={handleFilterChange}
                            placeholder="Filter by Agent Name"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="fromAirport"
                            className="form-control font12"
                            value={filters.fromAirport}
                            onChange={handleFilterChange}
                            placeholder="Filter by From Airport"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="toAirport"
                            className="form-control font12"
                            value={filters.toAirport}
                            onChange={handleFilterChange}
                            placeholder="Filter by To Airport"
                        />
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <select
                            name="status"
                            className="form-control font12"
                            value={filters.status}
                            onChange={handleFilterChange}
                            style={{height: '38px'}}
                        >
                            <option value="">Status</option>
                            <option value={5}>Ticketed</option>
                            <option value="failed">Failed</option>
                        </select>
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <DatePicker
                            placeholder={'Filter by Date'}
                            value={filters.date}
                            onChange={(value) => {
                                setFilters({...filters, date: value})
                            }}/>
                    </div>
                    <div className={"col-2 ms-2 mb-2"}>
                        <input
                            style={{height: '38px'}}
                            type="text"
                            name="requestId"
                            className="form-control font12"
                            value={filters.requestId}
                            onChange={handleFilterChange}
                            placeholder="Filter by RequestId"
                        />
                    </div>
                    <div className={"col-4 mb-2"}>
                        <button
                            className="btn btn-primary ms-2 font12"
                            onClick={applyFilters}
                            style={{height: '38px'}}
                        >
                            Apply Filters
                        </button>
                        <button
                            className="btn btn-primary ms-2 font12"
                            onClick={clearFilters}
                            style={{height: '38px'}}
                        >
                            Clear Filters
                        </button>
                    </div>
                </div>*/}
                        <Table
                            columns={columns}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            defaultSortOrder='descend'
                        />
                    </div>
                </div>
            </Card>
        </>

    )
        ;
};

export default AssignedAgentList;
