import React from "react";
import {Outlet, Navigate} from "react-router-dom";

const FullPageLayout = (props) => {
    let {currentUser} = props;
    return (
        <Outlet/>
    );
}

export default FullPageLayout;
