import React, {Suspense} from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes, Navigate
} from "react-router-dom";

import MainLayout from "../layout/mainLayout"
import MainInnerLayout from "../layout/mainLayout/innerLayout"
import FrontLayout from "../layout/frontLayout"
import AuthLayout from "../layout/authLayout"
import FullPgeLayouts from "../layout/authLayout"
import {history} from "../reducers/store";
import {frontMenu, menu, authMenu, innerMenu, fullPageMenu} from "./routeMenus";
import {connect} from "react-redux";
import ScrollToTop from "../web/scrollTopComponent";
import FullPageLayout from "../layout/fullPageLayout";
import {SocketComponent} from "../socket";
const RouteList = (props) => {
    let {currentUser = {}} = props;
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    return (
        <>
            <Router history={history}>
                <ScrollToTop/>
                <Routes>
                    <Route path={"/"} element={<FrontLayout  {...props}/>}>
                        {frontMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component user={currentUser}/>} key={item.key}/>
                            )
                        })}
                    </Route>
                    <Route path={"/"} element={<MainLayout  {...props}/>}>
                        {menu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component user={currentUser}/>} key={item.key}/>
                            )
                        })}
                    </Route>
                    <Route path={"/"} element={<MainInnerLayout  {...props}/>}>
                        {innerMenu.map((item) => {
                            let {component: Component, path} = item
                            if (!item.subMenu && user) {
                                let routePath = (
                                    <Route path={path} element={<Component user={currentUser}/>} key={item.key}/>
                                )
                                return (
                                    item.authority && item.authority.length ? item.authority.includes(user.userType) ? routePath : null : routePath
                                )
                            }
                        })}
                    </Route>
                    <Route path={"/"} element={<MainInnerLayout  {...props}/>}>
                        {innerMenu.map((item) => {
                            if (item.subMenu && item.subMenu.length && user) {
                                return item.subMenu.map((child, k) => {
                                    let {component: Component} = child;
                                    let routePath = (
                                        <Route exact path={child.path} key={child.key}
                                               element={<Component user={currentUser}/>}/>
                                    )
                                    return (
                                        item.authority && item.authority.length ? item.authority.includes(user.userType) ? routePath : null : routePath
                                    )
                                })
                            }
                        })}
                    </Route>


                    <Route path={"/"} element={<AuthLayout  {...props}/>}>
                        {authMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component user={currentUser}/>} key={item.key}/>
                            )
                        })}
                    </Route>

                    <Route path={"/"} element={<FullPageLayout  {...props}/>}>
                        {fullPageMenu.map((item) => {
                            let {component: Component, path} = item
                            return (
                                <Route path={path} element={<Component user={currentUser}/>} key={item.key}/>
                            )
                        })}
                    </Route>
                    <Route path="*" element={<Navigate replace to="/login"/>}/>

                </Routes>
            </Router>
            <Suspense fallback={<></>}>
                <SocketComponent/>
            </Suspense>


        </>

    );
};

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    rights: global.rights
})
export default connect(
    mapStateToProps,
    null
)(RouteList)
