import {unizAxios, xTravelAxios} from "../../../request";
import {showLoader, hideLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements";
import {
    activeAgentsUrl,
    assignedAgentUrl,
    checkCertificationTokenUrl,
    regAgentUrl,
    uploadCertificateUrl
} from "../apis";

export const approveAgentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await xTravelAxios.post(activeAgentsUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}


export const regAgentFxn = filters => async dispatch => {
    let config = {
        params: {...filters}
    }
    let {data} = await xTravelAxios.get(regAgentUrl(), config)
    return data.data
}

export const uploadCertificate = valData => async dispatch => {
    dispatch(showLoader())
    let {data} = await xTravelAxios.post(uploadCertificateUrl(), valData, {
        headers: {'Content-Type': 'multipart/form-data'}
    })
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const checkCertificationToken = valData => async dispatch => {
    dispatch(showLoader())
    let {data} = await xTravelAxios.post(
        checkCertificationTokenUrl(),
        valData,
    )
    dispatch(hideLoader())
    return data
}

export const assignedAgentFxn = filters => async dispatch => {
    let config = {
        params: {...filters}
    }
    let {data} = await xTravelAxios.get(assignedAgentUrl(), config)
    return data.data
}
