import React, {useEffect, useState, useCallback} from "react";
import {flightsCodeFxn, flightsSearchFxn} from "../actions";
import _ from "lodash";
import {Input} from "../../../components/Elements";
import {debounce} from "lodash";
import BookImg from '../../../assets/img/planeIcon.png'
import PlaneSmall from "../../../assets/imgs/plane-small.png"

const RowComponent = ({item, chooseCity}) => {
    return (
        <div className="city_row">
            {/* City header row */}
            <div className="city-header" onClick={() => chooseCity({...item, value: item.airportCode})}>
                <div className="justify-content-between w-100 d-flex">
                    <b>{item.cityName}</b>
                    <b>{item.airportCode}</b>
                </div>
                <div className="d-flex">
                    <small>{item.countryName} - </small>
                    <small style={{fontWeight: 500, color: "#b78713"}}>
                        &nbsp;{item.airportName}
                    </small>
                </div>
                {item.nearest && item.nearest.length > 0 && (
                    <div className="d-flex">
                        <small className={'section-title2'}>All Airports</small>
                        {/*<hr/>*/}
                    </div>
                )}
            </div>
        </div>
    );
};
const RowNearestComponentOld = ({item, chooseCity}) => {
    return (
        <div className="city_row">
            <div className="nested-airports">
                {item.map((nItem, index) => (
                    <div
                        key={index}
                        className="city_row p-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            chooseCity({
                                airportName: nItem?.airportName,
                                airportCode: nItem?.airportCode,
                                cityName: nItem?.cityName,
                                countryName: nItem?.countryName,
                                value: nItem?.airportCode,
                            });
                        }}
                        style={{cursor: "pointer"}}>
                        <div className={'justify-content-between w-100 d-flex'}>
                            <b>
                                {nItem.airportName ||
                                nItem.airportId?.airportName ||
                                nItem.airportId?.cityName}
                            </b>
                            <b>{nItem.airportId?.airportCode}</b>

                        </div>
                        <div className="section-title pt-1">
                            <img src={BookImg} alt={'icon'} width={18}/>
                            <span className={'pe-2'}> {nItem.details}</span></div>
                    </div>
                ))}
            </div>
        </div>
    );
};
const RowNearestComponent = ({airport, chooseCity}) => {
    let {airportId: item, details} = airport;
    return (
        <div className="city_row_nearest">
            {/* City header row */}
            <div className="city-header" onClick={() => chooseCity({...item, value: item.airportCode})}>
                <div className={'d-flex'}>
                    <img src={PlaneSmall} height={22} style={{marginRight: 10}}/>
                    <div className={'w-100'}>
                        <div className="justify-content-between w-100 d-flex">
                            <b>{item.airportName || item.cityName}</b>
                            <b>{item.airportCode}</b>
                        </div>
                        <div className="d-flex">
                            {/*<small>{item.countryName} - </small>*/}
                            <small style={{fontWeight: 500, color: "#b78713"}}>
                                {details}
                            </small>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

const CityPicker = (props) => {
    let {onSelect, placeholder} = props;
    let [state, setState] = useState({})
    let [cityName, setCityName] = useState("")
    let [cityId, setCityId] = useState("")
    let [arrList, setArrList] = useState([])
    let [recentList, setRecentList] = useState([])
    let [popularList, setPopularList] = useState([])

    useEffect(() => {
        events.defaultHistoryData();
        events.fetchPopularCities();
    }, [])

    const events = {
        defaultHistoryData: () => {
            const listData = localStorage.getItem('cities') && JSON.parse(localStorage.getItem('cities')) || []
            setRecentList(listData);
        },
        fetchPopularCities: async () => {
            let {data} = await flightsCodeFxn({results: 10, count: 10});
            let popularCities = [];

            if (data && data.length) {
                popularCities = data
                    .filter(item => item.popular)
                    .map(item => ({
                        text: `${item.airportName} (${item.airportCode}) ${item.cityName}, ${item.countryName}`,
                        value: item.airportCode,
                        airportCode: item.airportCode,
                        airportName: item.airportName,
                        cityCode: item.cityCode,
                        cityName: item.cityName,
                        countryCode: item.countryCode,
                        countryName: item.countryName,
                        popular: true,
                        nearest: item.nearest
                    }));
            }

            setPopularList(popularCities);
            setArrList([...recentList, ...popularCities]);
        },
        fetchFlights: async (cityName) => {
            setArrList([]);
            let obj = {
                results: 30,
                count: 30,
                cityName: cityName,
                regExFilters: ['cityName']
            };
            if (cityId && !cityName) {
                obj.airportCode = cityId;
            }
            if ((cityName && cityName.length > 2) || cityId) {
                let {data} = await flightsSearchFxn(obj);
                let otherCities = [];

                if (data && data.length) {
                    otherCities = data.map(item => ({
                        text: `${item.airportName} (${item.airportCode}) ${item.cityName}, ${item.countryName}`,
                        value: item.airportCode,
                        airportCode: item.airportCode,
                        airportName: item.airportName,
                        cityCode: item.cityCode,
                        cityName: item.cityName,
                        countryCode: item.countryCode,
                        countryName: item.countryName,
                        popular: item.popular || false,
                        nearest: item.nearest
                    }));
                }

                setArrList(otherCities);
            }
        },
        handleChange: value => {
            setArrList([]);
            onSelect(value);
            events.addItemInHistory(value);
        },
        addItemInHistory: (data) => {
            const listData = localStorage.getItem('cities') && JSON.parse(localStorage.getItem('cities')) || []
            let findD = _.find(listData, (item) => {
                return item && item?.value == data?.value
            })
            if (!findD) {
                listData.push({...data})
                localStorage.setItem("cities", JSON.stringify(listData));
                setRecentList(listData); // Update state to show immediately
            }
        }
    }

    const debouncedFetchFlights = useCallback(debounce((cityName) => events.fetchFlights(cityName), 500), []);

    useEffect(() => {
        if (cityName) {
            debouncedFetchFlights(cityName);
        } else {
            setArrList([...recentList, ...popularList]);
        }
    }, [cityName, recentList, popularList]);

    return (
        <div className={'city_picker'}>
            <Input
                placeholder={placeholder}
                value={cityName}
                autoFocus={true}
                onChange={(value) => {
                    setCityName(value);
                }}
            />
            <div key={arrList.length}>
                <div className={'city_list'}>

                    {cityName.length > 0 && arrList.length > 0 ? (
                        <>
                            <div className="section-title pt-2">Suggestions</div>
                            {arrList.map(d => d.value ?
                                <div key={d}>
                                    <RowComponent item={d} chooseCity={events.handleChange}/>
                                    {d.nearest && d.nearest.length > 0 ?
                                        <>
                                            {d.nearest.map((item) => {
                                                return (
                                                    <RowNearestComponent airport={item}
                                                                         chooseCity={events.handleChange}/>
                                                )
                                            })}
                                        </>
                                        : null}
                                </div>
                                : null)}
                        </>
                    ) : (
                        <>
                            {recentList.length > 0 && (
                                <>
                                    <div className="section-title pt-2">Recent Searches</div>
                                    {recentList.map(d => d.value ?
                                        <RowComponent item={d} chooseCity={events.handleChange}/> : null)}
                                </>
                            )}
                            {popularList.length > 0 && (
                                <>
                                    <div className="section-title pt-2">Popular Cities</div>
                                    {popularList.map(d => d.value ?
                                        <RowComponent item={d} chooseCity={events.handleChange}/> : null)}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CityPicker
