const initialState = {
    tokenId: "",
    traceId: "",
    resultIndex: "",
    flightObj: {
        adultCount: "",
        childCount: "",
        infantCount: "",
        directFlight: "",
        oneStopFlight: "",
        journeyType: "",
        preferredAirlines: "",
        origin: "",
        destination: "",
        preferredDepartureTime: "",
        preferredArrivalTime: "",
        preferredReturnTime: "",
    },
    selectedFlight: {},
    sessionId: "",
    fareQuoteObj: {},
    flightBookingType: "",
    ssrResponse: {},
}

export default (state = initialState, action) => {
    switch (action && action.type) {
        case 'SEARCH_FLIGHT':
            return {
                ...state,
                flightObj: action.flightObj
            }

        case 'UPDATE_RESULT_INDEX':
            return {
                ...state,
                traceId: action.traceId,
                selectedFlight: action.selectedFlight,
                flightBookingType: action.flightBookingType
            }
        case 'RESET_FLIGHT':
            return {
                ...state,
                tokenId: "",
                traceId: "",
                resultIndex: "",
                flightObj: {},
                selectedFlight: {},
                fareQuoteObj: {},
                ssrResponse: {},
                flightBookingType: ""
            }
        case 'UPDATE_FARE_QUOTE':
            return {
                ...state,
                fareQuoteObj: action.fareQuoteObj
            }

        case 'UPDATE_SESSION_ID':
            return {
                ...state,
                sessionId: action.sessionId
            }

        case 'SET_SSR_RESPONSE':
            return {
                ...state,
                ssrResponse: action.ssrResponse
            }
        case 'RESET_SSR_RESPONSE':
            return {
                ...state,
                ssrResponse: {}
            }


        default:
            return state
    }
}
