import React, {useEffect, useState} from "react"
import FlightImg from "../../../components/flightImg";
import {
    CabinObject,
    displayDate, getTotalDuration,
    getTotalDurationSegments
} from "../../../components/utils/appUtils";
import FlightFareIconsComponent from "../components/flightFareIconsComponent";
import AllStopsDetailsPopover from "../components/AllStopsDetailsPopover";
import {getAirlineLogo} from "../../../components/utils/appUtils";

const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
            </small>
            {/*       <small className="d-block">
                {data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
            </small>*/}
        </>
    )
}

const WithStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];

    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h6>
                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                    {allSegmentData.length - 1} Stop(s)
                </AllStopsDetailsPopover>
                <small className="d-block">
                    {originObj && originObj.Origin ? originObj.Origin : ""}
                    &nbsp;-&nbsp;
                    {destinationObj && destinationObj.Destination ? destinationObj.Destination : ""}
                </small>
                <small className="d-block">
                    {getTotalDuration(allSegmentData)}
                </small>
            </h6>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>

            <ul>
                <li className="p-0">


                    <div
                        className="d-flex align-items-center"
                        style={{gap: '30px'}}>
                        <h6>
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                {displayDate(segmentStarting.Origin.DepTime, "hh:mm A")}
                            </> : null}
                            <br/>
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                <small className={'f-12'}>
                                    {displayDate(segmentStarting.Origin.DepTime, 'Do MMM, ddd')}
                                </small>
                            </> : null}
                            {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            {segmentStarting.IsTransitVisaRequired ?
                                <div className={'notify-msg'}>
                                    Transit Visa
                                </div> : null}
                        </h6>

                        {stopsComponent}

                        <h6>
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                {displayDate(segmentEnding.Destination.ArrTime, "hh:mm A")}
                            </> : null}
                            <br/>
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                <small className={'f-12'}>
                                    {displayDate(segmentEnding.Destination.ArrTime, 'Do MMM, ddd')}
                                </small>
                            </> : null}

                            {segmentEnding.Destination ?
                                <FlightAddress data={segmentEnding.Destination}/> : null}

                            {segmentEnding.IsTransitVisaRequired ?
                                <div className={'notify-msg'}>
                                    Transit Visa
                                </div> : null}
                        </h6>


                        {allSegmentData.length == 1 ? <h6>
                            {getTotalDuration(allSegmentData)}
                            <small
                                className="d-block">Economy
                                Class</small>
                            <small className="d-block">
                                {data.IsRefundable ? "Refundable" : "Non Refundable"}

                            </small>
                        </h6> : null}
                    </div>
                </li>
            </ul>


        </>
    )
}

const NonStopComponent = (props) => {
    let {data} = props;
    let {Segments, FareRules: fareRules} = data;
    let allSegmentData = Segments[0];
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>

            <div className={'mt-2'}>
                <ul>
                    <li className={'p-0'}>
                        <div className="d-flex align-items-center" style={{gap: 30}}>
                            <h6>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime, "hh:mm A")}
                                </> : null}
                                <br/>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    <small className={'f-12'}>
                                        {displayDate(segmentStarting.Origin.DepTime, 'Do MMM, ddd')}
                                    </small>
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h6>

                            <span>
                                    <FlightImg/>
                                    </span>
                            <span>
                                                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                                                    <p className={'non-stop-p'}
                                                       style={{left: 0, marginTop: '31px'}}>Non-Stop </p>
                                                </AllStopsDetailsPopover>
                                   <small className="d-block">
                                {getTotalDurationSegments(allSegmentData)}
                                   </small>
                                        </span>
                            <span>
                                    <FlightImg/>
                                    </span>

                            <h6>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime, "hh:mm A")}
                                </> : null}
                                <br/>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    <small className={'f-12'}>
                                        {displayDate(segmentEnding.Destination.ArrTime, 'Do MMM, ddd')}
                                    </small>
                                </> : null}

                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h6>

                        </div>
                    </li>
                </ul>

            </div>
        </>
    )
}

const RoundFlightDetailsBox = (props) => {
    let {data, onFlightSelect, flightData, type, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    let defaultFlightSegment = data[0].Segments[0]
    const firstSegment = defaultFlightSegment[0];
    const lastSegment = defaultFlightSegment[defaultFlightSegment.length - 1];
    useEffect(() => {
        setSelectedFlight(flightData)
    }, [flightData])
    const handleFlightSelection = (flight) => {
        setSelectedFlight(flight);
        onFlightSelect(flight);
    };

    return (
        data && data[0] && data[0]['Segments'] && data[0]['Segments'].length ? <>
            <div className="book-flight mb-3">
                <div className="track-flights">
                    <ul>
                        <li className="p-0 flex-wrap">
                            <div className="d-flex w-100 gap-3">
                                <span className="logo">
                                  <img src={getAirlineLogo(data[0].AirlineCode)} alt=""/>
                                </span>
                                <h6>
                                    {firstSegment?.Airline?.AirlineName}
                                    <small
                                        className="d-block">{firstSegment?.Airline?.AirlineCode}-{firstSegment?.Airline?.FlightNumber}</small>
                                </h6>
                                <h6>
                                    {firstSegment?.Origin?.Airport?.CityCode} - {lastSegment?.Destination?.Airport?.CityCode}

                                </h6>
                            </div>
                            {data[0]['Segments'][0].length > 1 ?
                                <WithStopComponent data={data[0]}/> :
                                <NonStopComponent data={data[0]}/>}
                        </li>
                    </ul>
                </div>
                <div className="class-price mt-2">
                    <div className="row">
                        <div className="row row-gap-3">
                            {data.map((item) => {
                                let firstSegment = item['Segments'][0][0]

                                return (
                                    <div className="col-lg-6">
                                        <div className="main-box">
                                            <input type="radio" name={`class-price-${type}`}
                                                   className="class-price"
                                                   checked={item['ResultIndex'] == flightData['ResultIndex']}
                                                   onChange={() => handleFlightSelection(item)}
                                            />
                                            <div className="box">
                                                <span></span>
                                                <div>
                                                    {
                                                        item['FareClassification'] &&
                                                        <div className="fare-classification">
                                                            {item['FareClassification']?.Type}
                                                        </div>
                                                    }
                                                    <small>{CabinObject[firstSegment['CabinClass']] || 'Economy'}
                                                    </small>
                                                    <h5>Rs. {item['Fare'] && item['Fare'].newFare ? item['Fare'].newFare : ""}</h5>
                                                </div>
                                            </div>

                                        </div>
                                        <FlightFareIconsComponent flight={item}
                                                                  bookingType={type == "outBound" ? "Outbound" : "Inbound"}
                                                                  traceId={flightInfo.traceId}/>

                                    </div>
                                )
                            })}
                        </div>

                        <div className="details d-flex gap-3 flex-wrap align-items-center mt-3">
                            {selectedFlight['Segments'] && selectedFlight['Segments'][0] && selectedFlight['Segments'][0][0].NoOfSeatAvailable ?
                                <p className="m-0" style={{fontSize: '14px'}}>
                                    {/*Flight Arrives after 1 Day(s)*/}
                                    <b className="ms-2"
                                       style={{color: 'red'}}>
                                        Seat(s)
                                        left: {selectedFlight['Segments'][0][0].NoOfSeatAvailable}</b>
                                </p> : null}
                        </div>
                    </div>
                </div>
            </div>

        </> : null
    )
}
export default RoundFlightDetailsBox
