import React from "react"
import "./index.css"

const Skeleton = (props) => {
    let {rows = 4} = props;
    return (
        <>
            <div className="skeleton skeleton-text" style={{width: "40%"}}></div>
            {Array.from({length: rows}).map((_, index) => (
                <div key={index} className="skeleton skeleton-text"></div>
            ))}
            <div className="skeleton skeleton-text" style={{width: "60%"}}></div>
        </>
    )
}
export default Skeleton
