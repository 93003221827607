import React, {useState, useRef, useEffect, useCallback} from "react";
import CityPicker from "../commonComponents/cityPicker";
const SelectCityComponent = (props) => {
    let {label, style, cityId, cityName, airportName, onSelect} = props;
    const [visibleTypehead, setVisibleTypehead] = useState(false);
    const containerRef = useRef(null);

    // Click outside handler
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleTypehead(false);
        }
    }, []);

    useEffect(() => {
        if (visibleTypehead) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visibleTypehead, handleClickOutside]);

    return (
        <div ref={containerRef} className="content" style={style} onClick={() => setVisibleTypehead(true)}>
            <span>{label}</span>
            <h5>{cityName}</h5>
            {visibleTypehead && (
                <CityPicker
                    placeholder={props.placeholder}
                    cityId={cityId}
                    onSelect={(value) => {
                        // Delay closing to prevent event conflicts
                        onSelect(value)
                        setTimeout(() => setVisibleTypehead(false), 100);
                    }}
                />
            )}
        </div>
    );
};

export default SelectCityComponent;
