import React, {useEffect, useState} from "react";
import {ticketListFxn} from "./action";
import moment from "moment";
import {useDispatch} from "react-redux";
import {Card, Empty, Tooltip, Pagination} from "../../components/Elements";
import {CabinObject, getAirlineLogo, TicketStatusObject} from "../../components/utils/appUtils";
import currencyList from "../../assets/currencyList.json";
import PromotionalModal from "../ModalComponent/promotionalModal";
import CancelRequestModal from "../ModalComponent/cancelRequestModal";
import TicketRowComponent from "./components/ticketRowComponent";

const TicketListComp = () => {
    const dispatch = useDispatch()
    let pageSize = 10
    let [page, setPage] = useState(1);
    let [total, setTotal] = useState(0)
    const [ticketList, setTicketList] = useState([]);
    const [showModal, setShowModal] = useState({
        visible: false,
        ticket: {}
    })
    const events = {
        ticketList: async () => {
            let obj = {
                results: pageSize,
                count: pageSize,
                page: page,
                type: "ticketed"
            }
            let {data, total} = await dispatch(ticketListFxn(obj));
            setTotal(total)
            setTicketList(data);
        },
        showCancelModal: (ticket) => {
            setShowModal({
                visible: true,
                ticket: ticket
            })
        },
        hideCancelModal: () => {
            setShowModal({
                visible: false,
                ticket: {}
            })
        }
    };

    useEffect(() => {
        events.ticketList();
    }, [page]);
    useEffect(() => {
        dispatch({type: "RESET_FLIGHT"})
    }, [])

    return (
        <div className="row row-gap-3">
            <div className="col-lg-12">
                <div className={'d-flex align-items-center justify-content-between'}>
                    <h4 className="mb-4 ">Manage Bookings - ({total})</h4>
                    <Pagination
                        page={page}
                        total={total}
                        pageSize={pageSize}
                        onSubmit={(page) => {
                            setPage(page)
                        }}/>
                </div>
                <div className="ticket-list pe-3">
                    {ticketList && ticketList.length ?
                        <>
                            <div className={'mt-2'}>
                                {ticketList.map((ticket, index) => {
                                    return (
                                        <>
                                            {/* <>
                                    <div className="track-flights" key={index}>
                                        <div className="accordion"
                                             id={`accordionPanelsStayOpenExample${index}`}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header"
                                                    id={`panelsStayOpen-heading${index}`}>
                                                    <ul className={'d-flex flex-wrap'}>
                                                        <li className="p-3 head-accordion manageBookingList">
                                                            <h6 className="pnr">
                                                                <div className={'d-flex flex-wrap'}>
                                                                    <div>
                                                                        <div className={'fw-semibold'}>
                                                                            <Tooltip title={`View Invoice`}>
                                                                                PNR:&nbsp;
                                                                                {ticket &&
                                                                                ticket.response &&
                                                                                ticket.response.PNR}
                                                                            </Tooltip>
                                                                        </div>
                                                                        <small className={'d-block'}>
                                                                            Booking Date:&nbsp;
                                                                            {ticket &&
                                                                            ticket.response &&
                                                                            ticket.response.FlightItinerary &&
                                                                            ticket.response.FlightItinerary.InvoiceCreatedOn &&
                                                                            moment(ticket.response.FlightItinerary.InvoiceCreatedOn).format('DD/MMM/YY')}
                                                                        </small>
                                                                    </div>
                                                                    <div className={'mt10'}>
                                                                        <a
                                                                            href={`/response/${ticket?._id}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className={'ms-3 viewButtonDark'}>
                                                                            <img
                                                                                src="/images2/eye.png"
                                                                                width="20"
                                                                                alt="eye-icon"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </h6>
                                                            <div className={'d-flex flex-wrap airLogoDiv'}>
                                                                <img className="airlineLogoSize"
                                                                     src={ticket &&
                                                                     ticket.response &&
                                                                     ticket.response.FlightItinerary &&
                                                                     ticket.response.FlightItinerary.Segments &&
                                                                     ticket.response.FlightItinerary.Segments.length > 0 &&
                                                                     ticket.response.FlightItinerary.Segments[0].Airline &&
                                                                     ticket.response.FlightItinerary.Segments[0].Airline.AirlineCode &&
                                                                     getAirlineLogo(ticket.response.FlightItinerary.Segments[0].Airline.AirlineCode)
                                                                     } alt=""/>
                                                                <h6 className="name mt10">
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.FlightItinerary &&
                                                                    ticket.response.FlightItinerary.Segments &&
                                                                    ticket.response.FlightItinerary.Segments.length > 0 &&
                                                                    ticket.response.FlightItinerary.Segments[0].Airline &&
                                                                    ticket.response.FlightItinerary.Segments[0].Airline.AirlineName &&
                                                                    ticket.response.FlightItinerary.Segments[0].Airline.AirlineName}
                                                                </h6>

                                                            </div>
                                                            <ul className={'cabinClassDiv'}>
                                                                <div>
                                                                    <h6>
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary.Segments &&
                                                                        ticket.response.FlightItinerary.Segments.length > 0 &&
                                                                        ticket.response.FlightItinerary.Segments[0].CabinClass &&
                                                                        CabinObject[ticket.response.FlightItinerary.Segments[0].CabinClass]}
                                                                        &nbsp; Class,
                                                                        <small className={'d-block fw-bold'}>
                                                                            {ticket &&
                                                                            ticket.response &&
                                                                            ticket.response &&
                                                                            ticket.response.FlightItinerary &&
                                                                            ticket.response.FlightItinerary.Segments &&
                                                                            ticket.response.FlightItinerary.Segments.length > 0 &&
                                                                            ticket.response.FlightItinerary.Segments[0].Origin &&
                                                                            ticket.response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                            moment(ticket.response.FlightItinerary.Segments[0].Origin.DepTime).format('DD MMMM, YYYY')}
                                                                        </small>
                                                                    </h6>
                                                                </div>
                                                            </ul>
                                                            <div className="d-flex flex-wrap align-items-center">
                                                                <h6 style={{
                                                                    justifyContent: "center",
                                                                    textAlign: "center",
                                                                }}>
                                                                    <small className="d-block">
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary.Origin &&
                                                                        ticket.response.FlightItinerary.Origin}
                                                                        <br/>
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary.Segments &&
                                                                        ticket.response.FlightItinerary.Segments.length > 0 &&
                                                                        ticket.response.FlightItinerary.Segments[0].Origin &&
                                                                        ticket.response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                        moment(ticket.response.FlightItinerary.Segments[0].Origin.DepTime).format('HH:MM A')}
                                                                    </small>
                                                                </h6>
                                                                <span className="mx-4">
                                                                <img src="images2/plane.png" alt="plane"/>
                                                              </span>
                                                                <h6
                                                                    style={{
                                                                        justifyContent: "center",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    <small className="d-block me-3">
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary.Destination &&
                                                                        ticket.response.FlightItinerary.Destination}
                                                                        <br/>
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary.Segments &&
                                                                        ticket.response.FlightItinerary.Segments.length > 0 &&
                                                                        ticket.response.FlightItinerary.Segments[0].Origin &&
                                                                        ticket.response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                                        moment(ticket.response.FlightItinerary.Segments[0].Origin.ArrTime).format('HH:MM A')}
                                                                    </small>
                                                                </h6>
                                                            </div>
                                                            <h5 className={'ConfirmedStyle'}>
                                                                {ticket &&
                                                                ticket.response &&
                                                                ticket.response.TicketStatus &&
                                                                (TicketStatusObject[ticket.response.TicketStatus]).toUpperCase()}
                                                            </h5>
                                                        </li>
                                                    </ul>
                                                    <ul className={'lowerButtonUl flex-wrap'}>
                                                        <li className="p-3 head-accordion manageBookingList ">
                                                            <ul>
                                                                <div
                                                                    className="d-flex flex-wrap gradient-button justify-content-between downloadInvoiceIcon">
                                                                    <a
                                                                        href={`/booking-details?ticketId=${ticket?._id}`}
                                                                        target="_blank"
                                                                        className="fw-semibold downloadDiv downloadButtonStyle buttonFontSize"
                                                                    >
                                                                        Download Invoice
                                                                    </a>
                                                                    <div>
                                                                        <a
                                                                            href={`/booking-details?ticketId=${ticket?._id}`}
                                                                            target="_blank"
                                                                            className="btn btn-primary downloadIcon"
                                                                        >
                                                                            <img
                                                                                className="iconheight2"
                                                                                src="/images2/download.png"
                                                                                width="20"
                                                                                alt="eye-icon"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                            <div className={'d-flex gap-4'}>
                                                                <ul>
                                                                    <div>
                                                                        <a
                                                                            className={'btn btn-primary fw-semibold buttonFontSize gradient-button lg'}
                                                                            href="#"
                                                                            role="button"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false">
                                                                            <div>
                                                                                {ticket && ticket.Passengers && ticket.Passengers.length && ticket.Passengers[0]['FirstName'] || 0}
                                                                                &nbsp; - &nbsp;{ticket && ticket.Passengers && ticket.Passengers.length || 0}&nbsp; Passenger(s)
                                                                                &nbsp;
                                                                                <i className={'bx bx-down-arrow-alt bx-sm'}/>
                                                                            </div>


                                                                        </a>
                                                                        <div className={'dropdown-menu p-3'}>
                                                                            {ticket &&
                                                                            ticket.Passengers &&
                                                                            ticket.Passengers.map((passenger, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className="mt-2 d-flex flex-wrap justify-content-between passengersPopup">

                                                                                            <div
                                                                                                className={'d-flex flex-wrap'}>
                                                                                                <div className={'p-2'}>
                                                                                                    <img
                                                                                                        className={'iconheight'}
                                                                                                        src="/images2/avatar.png"
                                                                                                        width="20"
                                                                                                        alt="eye-icon"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mt-2 p-1">
                                                                                                    {passenger.FirstName} {passenger.LastName}
                                                                                                </div>
                                                                                            </div>
                                                                                            <a
                                                                                                href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                                                target="_blank"
                                                                                                key={index}
                                                                                                className="btn btn-primary ms-4"
                                                                                            >
                                                                                                <Tooltip
                                                                                                    title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>
                                                                                                    <img
                                                                                                        className={'iconheight'}
                                                                                                        src="/images2/download.png"
                                                                                                        width="20"
                                                                                                        alt="eye-icon"
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            </a>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                            <div>
                                                                                   <button
                                                                            className={'btn btn-primary fw-semibold w-100 gradient-button mt-2'}>
                                                                            Download All E-Tickets
                                                                        </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                                           <ul>
                                                            <div>
                                                                <div>
                                                                    <a
                                                                        className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}
                                                                        href="#"
                                                                        role="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        Download All E-Tickets
                                                                    </a>
                                                                    <div className={'dropdown-menu p-3'}>
                                                                        {ticket &&
                                                                        ticket.Passengers &&
                                                                        ticket.Passengers.map((passenger, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        className="mt-2 d-flex flex-wrap justify-content-between passengersPopup">

                                                                                        <div className={'d-flex flex-wrap'}>
                                                                                            <div className={'p-2'}>
                                                                                                <img
                                                                                                    className={'iconheight'}
                                                                                                    src="/images2/avatar.png"
                                                                                                    width="20"
                                                                                                    alt="eye-icon"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                className="mt-2 p-1">
                                                                                                {passenger.FirstName} {passenger.LastName}
                                                                                            </div>
                                                                                        </div>
                                                                                        <a
                                                                                            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                                            target="_blank"
                                                                                            key={index}
                                                                                            className="btn btn-primary ms-4"
                                                                                        >
                                                                                            <Tooltip
                                                                                                title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>
                                                                                                <img
                                                                                                    className={'iconheight'}
                                                                                                    src="/images2/download.png"
                                                                                                    width="20"
                                                                                                    alt="eye-icon"
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </a>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <div>
                                                                            <button
                                                                                className={'btn btn-primary fw-semibold w-100 gradient-button mt-2'}>
                                                                                Download All E-Tickets
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ul>
                                                        <ul>
                                                            <div>
                                                                <button
                                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}>
                                                                    Edit Booking
                                                                </button>
                                                            </div>
                                                        </ul>
                                                                <ul>
                                                                    <div>
                                                                        <button
                                                                            className={'btn btn-primary fw-semibold buttonFontSize gradient-button lg'}
                                                                            onClick={() => events.showCancelModal(ticket)}
                                                                        >
                                                                            Change Request
                                                                        </button>
                                                                    </div>
                                                                </ul>
                                                                 <ul>
                                                            <div>
                                                                <button
                                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}>
                                                                    Share
                                                                </button>
                                                            </div>
                                                        </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </h2>
                                                <div
                                                    id={`panelsStayOpen-collapse${index}`}
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby={`panelsStayOpen-heading${index}`}
                                                >
                                                    <div className="accordion-body">
                                                        <ul>
                                                            <li className="manageBookingList">
                                                                <h6>
                                                                    {ticket &&
                                                                    ticket.Passengers &&
                                                                    ticket.Passengers.length
                                                                        ? ticket.Passengers.length
                                                                        : 0}{" "}
                                                                    <small className="d-block">Passengers</small>
                                                                </h6>
                                                                <h6>
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.FlightItinerary &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments[0] &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments[0].Origin &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments[0].Origin.DepTime &&
                                                                    moment(
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Origin.DepTime
                                                                    ).format("DD MMM, YY")}
                                                                    <small className="d-block">Departure</small>
                                                                </h6>
                                                                <h6>
                                                                    Departure Time{" "}
                                                                    <small className="d-block">
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0] &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Origin &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Origin.DepTime &&
                                                                        moment(
                                                                            ticket.response.FlightItinerary
                                                                                .Segments[0].Origin.DepTime
                                                                        ).format("HH:mm A")}
                                                                    </small>
                                                                </h6>
                                                                <h6>
                                                                    {ticket &&
                                                                    ticket.response &&
                                                                    ticket.response.FlightItinerary &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments[0] &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments[0].Destination &&
                                                                    ticket.response.FlightItinerary
                                                                        .Segments[0].Destination.ArrTime &&
                                                                    moment(
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Destination.ArrTime
                                                                    ).format("DD MMM, YY")}
                                                                    <small className="d-block">Arrival</small>
                                                                </h6>
                                                                <h6>
                                                                    Arrival Time{" "}
                                                                    <small className="d-block">
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0] &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Destination &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Destination.ArrTime &&
                                                                        moment(
                                                                            ticket.response.FlightItinerary
                                                                                .Segments[0].Destination.ArrTime
                                                                        ).format("HH:mm A")}
                                                                    </small>
                                                                </h6>
                                                                <h6>
                                                                    Class:{" "}
                                                                    <small className="d-block">Business</small>
                                                                </h6>
                                                                <h6>
                                                                    Journey Time:{" "}
                                                                    <small className="d-block">
                                                                        {ticket &&
                                                                        ticket.response &&
                                                                        ticket.response.FlightItinerary &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments &&
                                                                        ticket.response.FlightItinerary
                                                                            .Segments[0].Duration &&
                                                                        `${Math.floor(
                                                                            ticket.response.FlightItinerary
                                                                                .Segments[0].Duration / 60
                                                                        )}h ${
                                                                            ticket.response.FlightItinerary
                                                                                .Segments[0].Duration % 60
                                                                        }m`}
                                                                    </small>
                                                                </h6>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>*/}

                                            <TicketRowComponent ticket={ticket} index={index} refreshTable={() => {
                                                events.ticketList();
                                            }}/>


                                        </>
                                    );
                                })}
                            </div>
                        </> :
                        <div>
                            <Empty title={'No Records Found.'}/>
                        </div>
                    }
                </div>
            </div>
            {/*    {showModal.visible && (
                <CancelRequestModal
                    visible={showModal.visible}
                    onClose={() => events.hideCancelModal()}
                    ticket={showModal.ticket}

                />
            )}*/}
        </div>
    );
};

export default TicketListComp;
