import React from "react"
import {NavLink, useNavigate} from "react-router-dom";
import commonFxns from "../../components/commonFxns";
import {useDispatch} from "react-redux";
import {innerMenu} from "../../routes/routeMenus";

const SingleMenu = ({item}) => {
    return (
        <li>
            <NavLink to={item.path}>
                <img src={item.icon ? item.icon : "../images2/search-white.png"} alt="Search"/>
                <span>{item.name}</span>
            </NavLink>
        </li>
    )
}
const NestedMenu = ({item}) => {
    let accordionKey = `collapse${item.name}`
    return (
        <li>
            <div className="accordion custom-accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${accordionKey}`}>
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${accordionKey}`}
                            aria-expanded="false"
                            aria-controls={accordionKey}>
                            {item.icon ? <img src={item.icon} alt={item.name}/> : null}
                            <span>{item.name}</span>
                        </button>
                    </h2>
                    <div
                        id={accordionKey}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${accordionKey}`}
                        data-bs-parent="#accordionExample">
                        <div className="accordion-body p-0">
                            <ul>
                                {item.subMenu.map((itemSub) => {
                                    return (
                                        <li key={itemSub.key}>
                                            <NavLink to={itemSub.path}>
                                                <span>{itemSub.name}</span>
                                            </NavLink>
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}
const SideMenu = () => {
    const dispatch = useDispatch();
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    let events = {
        checkAuth: (data) => {
            let returnResp = data && data.authority && data.authority.length
                ? data.authority.includes(user.userType)
                    ? true
                    : false
                : true;
            return returnResp;
        },
    };
    return (
        <>
            <div className="sidenav">
                <button className="slide-btn">
                    <img src="images2/double-back-arrow.png" alt="Back Arrow"/>
                </button>
                <ul>

                    <li>
                        <NavLink to="/flight-search">
                            <img src="../images2/search-white.png" alt="Search"/>
                            <span>Search Flight</span>
                        </NavLink>
                    </li>
                    {innerMenu.map((item) => {
                        let {subMenu = []} = item;
                        let checkM = ((subMenu && subMenu.length ? (
                            <NestedMenu item={item} events={events} key={item.key}/>
                        ) : (
                            <SingleMenu item={item} events={events} key={item.key}/>
                        )));
                        return events.checkAuth(item) && !item.dontShowOnMenu ? checkM : null;
                    })}


                    <li onClick={() => commonFxns.logout(dispatch)}>
                        <a href="#">
                            <img src="../icons-img/power-off.png" alt="Search"/>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default SideMenu
