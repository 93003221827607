import React from "react"

const LccStatus = ({data}) => {
    return (
        <>
            <small className="d-block">
                {data['IsLCC'] ? "LCC" : "Non LCC"}
            </small>
        </>
    )
}
export default LccStatus
