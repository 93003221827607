import _ from "lodash"
import {
    calculateLayoverTime,
    getTotalDurationSegments,
    getTotalDurationValueSegments
} from "../../components/utils/appUtils";


export const getUniqueAirlines = (flights) => {
    let airlines = [];
    flights.forEach((flight) => {
        flight.Segments.forEach((segmentArray) => {
            segmentArray.forEach((segment) => {
                const {AirlineName} = segment.Airline;
                const {PublishedFare} = flight.Fare;
                if (!airlines.some((a) => a.name === AirlineName)) {
                    airlines.push({
                        name: AirlineName,
                        publishedFare: PublishedFare,
                        segments: flight.Segments,
                    });
                }
            });
        });
    });
    airlines = _.sortBy(airlines, 'name')
    return airlines;
};

export const getUniqueFareTypes = (flights) => {
    return _.uniq(flights.map(flight => flight?.FareClassification?.Type || 'Publish'));
};

export const getStops = (flights) => {
    let stops = _.sortBy(_.uniq(flights.map(flight => {
        return flight.Segments[0].length
    })));
    return stops
};

export const calculateCheapestAndFastestFlights = (flightList) => {
    return flightList.reduce((acc, flight) => {
        // const totalFlightDuration = calculateTotalDuration(flight.Segments[0]);
        const totalFlightDuration = getTotalDurationValueSegments(flight.Segments[0]);

        if (flight.Fare.newFare < acc.cheapestFlight || acc.cheapestFlight === 0) {
            acc.cheapestFlight = flight.Fare.newFare;
            acc.cheapestFlightDuration = totalFlightDuration;
            acc.cheapestFlightDetails = flight;
        }

        if (totalFlightDuration < acc.fastestFlightDuration || acc.fastestFlightDuration === Infinity) {
            acc.fastestFlightDuration = totalFlightDuration;
            acc.fastestFlight = flight.Fare.newFare;
            acc.fastestFlightDetails = flight;
        }

        acc.mostExpensiveFlight = Math.max(acc.mostExpensiveFlight, flight.Fare.newFare);

        return acc;
    }, {
        cheapestFlight: 0,
        mostExpensiveFlight: 0,
        cheapestFlightDuration: "N/A",
        fastestFlightDuration: Infinity,
        fastestFlight: 0,
        cheapestFlightDetails: null,
        fastestFlightDetails: null,
    });
};

export const getLayoverCity = (flights) => {
    const layoverCities = [];
    flights.forEach((flight) => {
        const segments = _.clone(flight.Segments[0]);
        segments.shift();
        segments.forEach((segment) => {
            const {AirportCode, AirportName} = segment?.Origin?.Airport || {};
            if (AirportCode && !layoverCities.some(city => city.airportCode === AirportCode)) {
                layoverCities.push({airportCode: AirportCode, airportName: AirportName});
            }
        });
    });
    return _.sortBy(layoverCities, 'airportName');
};

export const groupFlightsFxn = (flights) => {
    const groupedFlights = _.groupBy(flights, (flight) => {
        const airline = _.get(flight, 'Segments[0][0].Airline');
        if (!airline) return "Unknown-Group";
        const {AirlineCode, FlightNumber} = airline;
        let allCity = []
        _.each(flight['Segments'][0], (item) => {
            let fromCity = item?.Origin?.Airport?.CityCode;
            let toCity = item?.Origin?.Destination?.CityCode;
            allCity.push(fromCity, toCity);
        })
        let totalDuration = getTotalDurationSegments(flight['Segments'][0])
        let segmentLength = flight['Segments'][0].length || 0
        let firstSegment = flight['Segments'][0][0]
        let lastSegment = flight['Segments'][0][segmentLength - 1];
        let airportKey = firstSegment?.Origin?.Airport?.AirportName + "-" + lastSegment?.Destination?.Airport?.AirportName
        let timeKey = firstSegment?.Origin?.DepTime + "-" + lastSegment?.Destination?.ArrTime

        let allAirCode = _.uniqBy(flight.Segments[0], item => `${item.Airline.FareClass}-${item.Airline.FlightNumber}`);

        flight.allAirCode = allAirCode;
        let jointCity = allCity.join('-')

        let flightKey = `${AirlineCode}-${FlightNumber}-${airportKey}-${timeKey}-${jointCity}`


        // return `${AirlineCode}-${FlightNumber}-${segmentLength}-${totalDuration}`;
        return flightKey;
    });

    return Object.entries(groupedFlights).map(([groupKey, groupedItems]) => ({
        flights: groupedItems,
    }));
};

export const calculateTotalDuration = (segments) => {
    return segments.flat().reduce((total, segment, index, allSegments) => {
        total += segment.Duration;

        const nextSegment = allSegments[index + 1];
        if (nextSegment) {
            const layoverTime = calculateLayoverTime(segment.Destination?.ArrTime, nextSegment.Origin?.DepTime);
            total += layoverTime;
        }

        return total;
    }, 0);
};

export const getTimeSlot = (time) => {
    const hour = new Date(time).getHours();
    if (hour < 12) return 'morning';
    if (hour < 15) return 'noon';
    if (hour < 18) return 'afternoon';
    return 'night';
};

export const getArrivalTime = (flight) => {
    return new Date(_.last(flight.Segments[0]).Destination.ArrTime).getTime();
};

export const countStops = (flight) => {
    return flight.Segments.flat().length - 1;
};

