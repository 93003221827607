import React, {useEffect, useState} from "react"
import {CabinObject, getArrivalDays} from "../../../components/utils/appUtils";
import {Modal} from "../../../components/Elements"
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import AirportImg from '../../../assets/img/airport.png'
import FlightFareIconsComponent from "../components/flightFareIconsComponent";
import WithStopComponent from "./withStopComponent";
import NonStopComponent from "./nonStopComponent";
import {reissueTicketFxn} from "../../ticketList/action";

const OneWayFlightDetailsBox = (props) => {
    let {data, flightInfo} = props;
    let [selectedFlight, setSelectedFlight] = useState({})
    let [newAgentMargin, setNewAgentMargin] = useState()
    const [showModal, setShowModal] = useState({
        visible: false,
        searchDestination: '',
        flightDestination: ''
    });
    const [showSelectedFlightModal, setShowSelectedFlightModal] = useState({
        visible: false,
        FareWithoutTax: 0,
        flightPrice: 0,
        agentMargin: 0,
        taxAndSurCharges: 0,
    });
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (data && data.length) {
            setSelectedFlight(data[0])
        }
    }, [data])

    const events = {
        confirmBooking: async (data) => {
            const searchOrigin = flightInfo.origin;
            const searchDestination = flightInfo.destination;
            const flightOrigin = data?.Segments?.[0]?.[0]?.Origin?.Airport?.AirportCode;
            const firstSegment = data?.Segments?.[0];
            const flightDestination = firstSegment?.[firstSegment.length - 1]?.Destination?.Airport?.AirportCode;
            if (searchDestination !== flightDestination) {
                setShowModal({
                    visible: true,
                    searchDestination: `${searchOrigin}-${searchDestination}`,
                    flightDestination: `${flightOrigin}-${flightDestination}`,
                });
            } else {
                dispatch({
                    type: "UPDATE_RESULT_INDEX",
                    selectedFlight: {...data, FareBasisCode: {}},
                    traceId: flightInfo.traceId,
                    flightBookingType: "onWayFlight"
                });

                navigate("/checkout");

            }
        },
        handleOpenAgentMarginModal: async (data) => {
            setShowSelectedFlightModal({
                visible: true,
                FareWithoutTax: data.FareWithoutTax || 0,
                agentMargin: data.agentXTravelMargin || 0,
                taxAndSurCharges: data.taxAndSurCharge || 0,
                flightPrice: data.PublishedFare || 0,
            })
        },
        handleUpdateAgentMargin: async (newMargin) => {
            setSelectedFlight((prevSelectedFlight) => {
                const updatedMargin = parseFloat(newMargin) || 0;
                const updatedPublishFare =
                    parseFloat(prevSelectedFlight.Fare.PublishedFare || 0) -
                    (parseFloat(prevSelectedFlight.Fare.agentXTravelMargin || 0)) +
                    updatedMargin;

                return {
                    ...prevSelectedFlight,
                    Fare: {
                        ...prevSelectedFlight.Fare,
                        agentXTravelMargin: updatedMargin,
                        PublishedFare: updatedPublishFare,
                    }
                };
            });
            setNewAgentMargin('');
            setShowSelectedFlightModal((prevState) => ({
                ...prevState,
                visible: false,
            }));
        }


    }
    const handleModalAction = (action) => {
        if (action === "yes") {
            setShowModal({...showModal, visible: false});
            dispatch({
                type: "UPDATE_RESULT_INDEX",
                selectedFlight: {...selectedFlight, FareBasisCode: {}},
                traceId: flightInfo.traceId,
            });
            navigate("/checkout");
        } else {
            setShowModal({...showModal, visible: false});
        }
    };
    const calculateArrivalDays = (segments) => {
        if (!segments || !segments.length) return "N/A";

        const firstSegment = segments[0][0];
        const lastSegment = segments[0][segments[0].length - 1];

        const departureDate = new Date(firstSegment.Origin.DepTime);
        const arrivalDate = new Date(lastSegment.Destination.ArrTime);

        const depDateOnly = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate());
        const arrDateOnly = new Date(arrivalDate.getFullYear(), arrivalDate.getMonth(), arrivalDate.getDate());

        const timeDifference = arrDateOnly - depDateOnly;

        const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

        return dayDifference >= 1 ? `${dayDifference} day${dayDifference > 1 ? "s" : ""}` : "same day";

    };
    const arrivalDayValue = calculateArrivalDays(selectedFlight['Segments']);


    return (
        selectedFlight && selectedFlight['Segments'] && selectedFlight['Segments'].length ? <>
            <div className="book-flight mb-3">
                <div className="row row-gap-3">
                    <div className="col-lg-10">
                        {/*accordion-flight-track*/}
                        <div className={''}>
                            <div className="track-flights">
                                {selectedFlight['Segments'][0].length > 1 ?
                                    <WithStopComponent data={selectedFlight}/> :
                                    <NonStopComponent data={selectedFlight}/>}
                            </div>

                            <div className="class-price mt-2">
                                <div className="row">
                                    <div className="row row-gap-3">
                                        {data.map((item, index) => {
                                            // console.log(item);
                                            let firstSegment = item['Segments'][0][0]
                                            return (
                                                <div className="col-lg-4">
                                                    <div className="main-box" onClick={() => {
                                                        setSelectedFlight(item)
                                                    }}>
                                                        <input type="radio" className="class-price"
                                                               value={item['ResultIndex']}
                                                               checked={selectedFlight['ResultIndex'] == item['ResultIndex']}
                                                        />
                                                        <div className="box">
                                                            <span></span>
                                                            <div>
                                                                {
                                                                    item['FareClassification'] &&
                                                                    <div className="fare-classification">
                                                                        {item['FareClassification']?.Type}
                                                                    </div>
                                                                }
                                                                <small>{CabinObject[firstSegment['CabinClass']] || 'Economy'}
                                                                </small>
                                                                <h5>Rs. {item['Fare'] && item['Fare'].newFare ? item['Fare'].newFare : ""}</h5>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <FlightFareIconsComponent flight={item}
                                                                              traceId={flightInfo.traceId}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {selectedFlight.IsTransitVisaRequired && (
                                <small className={'d-block mt-2'}>
                                    <span className={'color-red'}>   Transit Visa is Required </span>
                                </small>
                            )}

                        </div>
                    </div>

                    <div className="col-lg-2" key={selectedFlight}>
                        <div className="d-flex flex-wrap gap-3 h-100">
                            <div className="total-price cursor-pointer" onClick={() => {
                                events.handleOpenAgentMarginModal(selectedFlight.Fare)
                            }}>
                                <span>Total Price</span>
                                <h5>Rs. {selectedFlight.Fare && selectedFlight.Fare.newFare ? selectedFlight.Fare.newFare : ""}
                                </h5>
                                <small></small>
                                <button
                                    onClick={() => {
                                        events.confirmBooking(selectedFlight)
                                    }}
                                    className="btn btn-primary">Book Now
                                </button>
                            </div>
                            {/*     <div className={"total-price cursor-pointer"}>*/}
                            {/*<div className="d-flex gap-1"*/}
                            {/*             style={{fontSize: '13px'}}>Fare Inclusions*/}
                            {/*    <img src="/images2/short.png" alt="short-icon" className={"sort-icon"}/>*/}

                            {/*     </div>*/}
                            {/* </div>*/}
                            <div className="total-price cursor-pointer" onClick={() => {
                                events.handleOpenAgentMarginModal(selectedFlight.Fare)
                            }}>

                                {arrivalDayValue !== "same day"? (
                                    <div className="total-price cursor-pointer">
                                        <span>Flight Arrives in {arrivalDayValue}
                                        </span>
                                    </div>
                                ) : null}
                                {selectedFlight['Segments'][0][0].NoOfSeatAvailable > 0 && (
                                    <div className="total-price cursor-pointer">
                                    <span className="ms-2" style={{color: "red"}}>
                                        Seats left: {selectedFlight['Segments'][0][0].NoOfSeatAvailable}
                                    </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {showModal.visible && (
                <Modal
                    visible={showModal.visible}
                    className={'popup-confirm'}
                    width={'38%'}
                    onClose={() => setShowModal({...showModal, visible: false})}
                >
                    <div className={'mt-1 alert-popup'}>
                        <div className={'mb-4'}>
                            <img src={AirportImg} alt={'airportimg'} width={'30%'}/>
                        </div>
                        <h3 className={'mb-4'}>Different Airport Alert</h3>
                        <p className={'mb-4'}>
                            You have searched for Outbound <strong>({showModal.searchDestination})</strong>,
                            however, this flight operates from Outbound <strong>({showModal.flightDestination})</strong>.

                        </p>
                        <h3 className={'mb-4'}>Do you wish to continue?</h3>
                        <div className="d-flex justify-content-center gap-3 mb-2">
                            <button
                                className="customYesBtn"
                                onClick={() => handleModalAction("yes")}
                            >
                                Yes
                            </button>
                            <button
                                className="customNoBtn"
                                onClick={() => handleModalAction("no")}
                            >
                                No
                            </button>

                        </div>
                    </div>
                </Modal>
            )}
        </> : null
    )
}

export default OneWayFlightDetailsBox
