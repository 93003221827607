import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTotalDurationValueSegments, minToHours} from "../../../components/utils/appUtils";
import {
    calculateCheapestAndFastestFlights,
    groupFlightsFxn,
    calculateTotalDuration,
} from "../commonFilterFxns";
import _ from "lodash"

const SortFlightFilters = (props) => {
    const {flightList, refundFilterList, setSortedFlights} = props

    const [selectedOption, setSelectedOption] = useState('cheapest');

    const [sortDirection, setSortDirection] = useState('asc');

    const [flightNumber, setFlightNumber] = useState('');
    const {
        cheapestFlight,
        cheapestFlightDuration,
        fastestFlight,
        fastestFlightDuration,
        cheapestFlightDetails,
        fastestFlightDetails
    } = calculateCheapestAndFastestFlights(flightList);


    const handleSortChange = (criteria) => {
        const newSortDirection = selectedOption === criteria ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        setSortDirection(newSortDirection);
        setSelectedOption(criteria);

        let sorted = [];


        if (criteria === 'flightNumber' && flightNumber) {
            sorted = flightList.filter(flight =>
                flight.Segments[0][0].Airline.FlightNumber.toString().includes(flightNumber)
            );
        } else {

            sorted = [...flightList].sort((a, b) => {
                let comparison = 0;

                switch (criteria) {
                    case 'price':
                        comparison = a.Fare.newFare - b.Fare.newFare;
                        break;
                    case 'duration':
                        const aDuration = getTotalDurationValueSegments(a.Segments);
                        const bDuration = getTotalDurationValueSegments(b.Segments);
                        comparison = aDuration - bDuration;
                        break;
                    case 'arrival':
                        const aArrivalTime = new Date(a.Segments[0][0].Destination.ArrTime);
                        const bArrivalTime = new Date(b.Segments[0][0].Destination.ArrTime);
                        comparison = aArrivalTime - bArrivalTime;
                        break;
                    case 'stops':
                        comparison = a.Segments[0].length - b.Segments[0].length;
                        break;
                    // case 'flightNumber':
                    //     comparison = a.Segments[0][0].Airline.FlightNumber.localeCompare(b.Segments[0][0].Airline.FlightNumber);
                    //     break;
                    default:
                        break;
                }

                return newSortDirection === 'asc' ? comparison : -comparison;
            });
        }

        setSortedFlights(sorted);
    };


    const handleChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;

        setSelectedOption(checked ? value : "");
    };


    const events = {
        groupFlights: async (sortedFlights) => {
            let resp = await groupFlightsFxn(sortedFlights)
            setSortedFlights(resp)
            // console.log(resp, "uuuuuuuuuuu")
        }
    }


    const filterFlights = () => {
        let filtered = [...flightList];

        if (selectedOption === "cheapest") {
            filtered.sort((a, b) => a.Fare.newFare - b.Fare.newFare);
        } else if (selectedOption === "fastest") {
            filtered.sort((a, b) => {
                const aDuration = getTotalDurationValueSegments(a.Segments[0]);
                const bDuration = getTotalDurationValueSegments(b.Segments[0]);
                // console.log(minToHours(aDuration - bDuration), "duuuu")
                return aDuration - bDuration;
            });
        }

        setSortedFlights(filtered);
    };



    useEffect(() => {
        filterFlights();
    }, [selectedOption]);


    return (
        <>
            <div className="radio-group-box">
                <div className="sorting-box">

                <div className="radio-box">
                        <input
                            type="checkbox"
                            name="radio-option"
                            value="cheapest"
                            checked={selectedOption === 'cheapest'}
                            onChange={handleChange}
                            // disabled={true}
                        />
                        <label>
                            <h6 className="m-0">Cheapest</h6>
                            <span>Rs. {cheapestFlight} - Duration: {minToHours(cheapestFlightDuration)}</span>
                        </label>
                    </div>
                    <div className="radio-box">
                        <input
                            type="checkbox"
                            name="radio-option"
                            value="fastest"
                            checked={selectedOption === 'fastest'}
                            onChange={handleChange}
                            // disabled={true}
                        />
                        <label>
                            <h6 className="m-0">Fastest</h6>
                            <span>Rs. {fastestFlight} - Duration: {minToHours(fastestFlightDuration)}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="sort-box">
                <span>Sort by:</span>
                <ul className="m-0 p-0">
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('duration')}
                        >
                            Duration
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('arrival')}
                        >
                            Arrival
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('stops')}
                        >
                            Stops
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="d-flex gap-1"
                            onClick={() => handleSortChange('price')}
                        >
                            Price
                            <img src="/images2/short.png" alt="short-icon"/>
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SortFlightFilters;
