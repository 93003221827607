import React, {useState} from "react"
import {Modal, Popconfirm} from "../../components/Elements";
import FlightSeat from "../../assets/img/seatPrefer.png"
import AirportImg from "../../assets/img/airport.png";

const ChangeBookPrice = (props) => {
    let {visible, onClose, onRelease, onSubmit, oldFare, newFare} = props;
    let fareMargin = newFare?.Fare?.newFare - oldFare?.Fare?.newFare
    // console.log(newFare)
    return (

        <Modal visible={visible} width={'40%'} onClose={onClose} className={'popup-confirm'}>
            <div className={'mt-1 alert-popup'}>
                <h3 className={'mb-4'}>Flight details have been changed!</h3>
                <p className={'mb-4'}>
                    Please verify the changed Price, Booking Class,
                    Cabin Class, Duration, Fare Basis Code before proceed to booking.The
                    new Price is increased by ₹ : <b>{fareMargin}</b> have reviewed and agreed on changed fares


                </p>
                <table className={'table table-border'}>
                    <tr>
                        <th></th>
                        <th>Published Fare</th>
                        {/*<th>Offered Fare</th>*/}
                    </tr>
                    <tr>
                        <td>New Price</td>
                        <td>{newFare?.Fare?.newFare}</td>
                        {/*<td>{newFare['OfferedFare']}</td>*/}
                    </tr>
                    <tr>
                        <td>Old Price</td>
                        <td>{oldFare?.Fare?.newFare}</td>
                        {/*<td>{oldFare['OfferedFare']}</td>*/}
                    </tr>

                    <tr>
                        <td>(* TDS Included)</td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>

                <div className={'align-center mt-3 gap-3 mb-3'}>
                    {/*<div className="d-flex justify-content-center gap-3 mb-2">*/}
                    <button
                        className="customYesBtn"
                        onClick={() => onSubmit(true, newFare, fareMargin)}
                    >
                        Accept
                    </button>
                    <Popconfirm
                        title="Are you sure you want to release this PNR?"
                        onConfirm={onRelease}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button
                            className="customNoBtn ms-3"
                            // onClick={onRelease}
                        >
                            Release
                        </button>
                    </Popconfirm>


                    {/*</div>*/}

                </div>
            </div>

        </Modal>
    )
}
export default ChangeBookPrice
