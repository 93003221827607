import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {airLinesFxn} from "../actions";
import {DatePicker, Form, notification, Radio, Select} from "../../../components/Elements";
import CityTypeheadComponent from "../../../components/CityTypeheadComponent";
import {CabinComponent, FareTypesComponent, PassengerComponent} from "./flightSearchComponent";
import moment from "moment";
import {calendarFareFxn} from "../../ticketList/action";

const RoundTripSearch = (props) => {
    let {journeyType, onClick, flightObj, state, setState} = props;
    let dispatch = useDispatch()
    let [airlineList, setAirlineList] = useState([])
    const [calendarFareData, setCalendarFareData] = useState([]);
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        _choosePreferredDepartureTime: (value) => {
            let obj = {preferredDepartureTime: value}
            if (obj.preferredReturnTime) {
                if (moment(obj.preferredReturnTime).isBefore(value)) {
                    obj.preferredReturnTime = value
                }
            } else {
                obj.preferredReturnTime = value
            }
            events._updateState(obj)
        },
        loadAirLine: async () => {
            let params = {
                results: 10000,
                count: 10000,
            }
            let {data} = await airLinesFxn(params);
            setAirlineList(data)
        },
        handleSearchFlight: async () => {
            if (!state.origin) {
                return notification.warning({message: "Please choose Departing From."})
            }
            if (!state.destination) {
                return notification.warning({message: "Please choose Going To."})
            }
            if (!state.preferredDepartureTime) {
                return notification.warning({message: "Please choose Departure Date."})
            }
            if (!state.preferredReturnTime && journeyType == "2") {
                return notification.warning({message: "Please choose Return Date."})
            }
            if (state.adultCount == 0 && state.childCount == 0 && state.infantCount == 0) {
                return notification.warning({message: "Please choose Passenger."})
            }
            let obj = {
                ...state, journeyType
            }
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})
            onClick(obj)
        },
        getCalendarFare: async (obj) => {
            let resp = await dispatch(calendarFareFxn(obj))
            console.log(resp?.data?.SearchResults, "resp----")
            if (resp?.data?.SearchResults) {
                setCalendarFareData(resp.data.SearchResults);
            }
        }
    }
    useEffect(() => {
        events.loadAirLine()
    }, [])
    useEffect(() => {
        if (flightObj) {
            if (flightObj.preferredDepartureTime) {
                flightObj.preferredDepartureTime = moment(flightObj.preferredDepartureTime)
            }
            if (flightObj.preferredReturnTime) {
                flightObj.preferredReturnTime = moment(flightObj.preferredReturnTime)
            }
            setState((prevState) => {
                return {
                    ...prevState,
                    ...flightObj
                }
            })
        }
    }, [flightObj])

    useEffect(() => {
        if (journeyType == "1") {
            events._updateState({preferredReturnTime: null})
        }
    }, [journeyType])

    const disabledDate = (current, fromDate) => {
        return current && current.isBefore(fromDate, 'day');
    };
    useEffect(() => {
        if (journeyType == "1") {
            events._updateState({preferredReturnTime: null})
        } else {
            events._updateState({preferredReturnTime: state.preferredDepartureTime})
        }
    }, [journeyType])


    useEffect(() => {
        events.getCalendarFare(state)
    }, [
        state
    ]);

    const getFareForDate = (date) => {
        if (!date) return null;
        const formattedDate = moment(date).format("YYYY-MM-DD");

        const result = calendarFareData.find(item =>
            moment(item.DepartureDate).format("YYYY-MM-DD") === formattedDate
        );

        return result ? result.Fare : null;
    };

    const dateRender = (current) => {
        const fare = getFareForDate(current);

        return (
            <div className="custom-calendar-cell">
                <div>{current.date()}</div>
                {fare !== null && (
                    <div style={{ color: "green", fontSize: "12px", marginTop: "3px" }}>
                        ₹{fare}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <Form>
                <div className="row row-gap-3">
                    <div className={'col-md-4'} key={state.origin}>
                        <CityTypeheadComponent
                            placeholder={"Departing From?"}
                            cityId={state.origin} onSelect={(value) => {
                            events._updateState({origin: value})
                        }}/>
                    </div>
                    <div className={'col-md-4'} key={state.destination}>
                        <CityTypeheadComponent
                            placeholder={"Going To?"}
                            cityId={state.destination} onSelect={(value) => {
                            events._updateState({destination: value})
                        }}/>
                    </div>
                    <div className={journeyType == "1" ? 'col-md-4' : "col-md-2"}>
                        <DatePicker
                            placeholder={'Departure Date'}
                            value={state.preferredDepartureTime}
                            dateRender={dateRender}
                            state={state}
                            disabledDate={(current) => disabledDate(current, moment())}
                            onChange={(value) => {
                                events._choosePreferredDepartureTime(value)
                            }}/>
                    </div>
                    {journeyType == "2" ?
                        <div className={'col-md-2'}>
                            <DatePicker
                                defaultValue={state.preferredReturnTime}
                                placeholder={'Return Date'}
                                state={state}
                                dateRender={dateRender}
                                disabledDate={(current) => disabledDate(current, moment(state.preferredDepartureTime))}
                                value={state.preferredReturnTime}
                                onChange={(value) => {
                                    events._updateState({preferredReturnTime: value})
                                }}/>
                        </div> : null}
                    <div className={'col-md-4'}>
                        <Select
                            options={airlineList}
                            keyAccessor={(option) => option.airLineCode}
                            valueAccessor={(option) => option.airLineName}
                            placeholder={"Preferred Airline"}
                            required={true}
                            showSearch={true}
                            multiple={true}
                            allowClear={true}
                            onChange={(value) => {
                                events._updateState({preferredAirlines: value})
                            }}
                        />
                    </div>
                    <div className="col-lg-4 col-12">
                        <CabinComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }}/>
                    </div>
                    <div className="col-lg-4 col-12">
                        <PassengerComponent state={state} onChange={(data) => {
                            events._updateState(data)
                        }} resultFareType={state.resultFareType}/>
                    </div>

                    <div className={'col-lg-12 col-12 mt-4'}>
                        <FareTypesComponent value={state.resultFareType} onClick={(data) => {
                            events._updateState({resultFareType: data})
                        }}/>
                    </div>
                </div>
                <button onClick={() => {
                    events.handleSearchFlight()
                }} className="btn btn-primary mt-4 w-100">
                    Find Your Flight
                </button>
            </Form>
        </>
    )
}

export default RoundTripSearch
