import {displayDate, minToHours,} from "../../../components/utils/appUtils";
import {Popover} from "../../../components/Elements";
import React from "react";
import FlightImg from "../../../components/flightImg";
import {getAirlineLogo} from "../../../components/utils/appUtils";

const FlightAddress = ({data, time}) => {
    return (
        <div>
            <small className="f-12 b-600">
                {displayDate(time, "hh:mm A")} {data?.Airport?.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
            </small>
            <br/>
            <small className="f-12">
                {displayDate(time, "ddd, MMM Do, YYYY")}
            </small>
        </div>
    );
};

const calculateLayoverTime = (arrivalTime, departureTime) => {
    if (!arrivalTime || !departureTime) return "";
    const arr = new Date(arrivalTime);
    const dep = new Date(departureTime);
    return minToHours(Math.max(0, Math.floor((dep - arr) / 60000)));
};

const AllStopsDetailsPopover = ({allSegmentData, children}) => {
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];

    const contentBody = (
        <div className={'flight-layover-box'}>
            <h6 className={'overlay-title'}>
                {segmentStarting?.Origin?.Airport?.CityName} &nbsp; - &nbsp;
                {segmentEnding?.Destination?.Airport?.CityName} &nbsp; | &nbsp;
                {segmentStarting?.Origin?.DepTime && (
                    <small className={'f-12'}>
                        {displayDate(segmentStarting.Origin.DepTime, "ddd, MMM Do, YYYY")}
                    </small>
                )}
            </h6>
            <hr className={'mb-2 mt-2'}/>
            <ul className={'flight-layover-popover'}>
                {allSegmentData.map((segment, index) => {

                    let {Origin, Destination, Airline: airLine, Duration} = segment;
                    const nextSegment = allSegmentData[index + 1];
                    const layoverTime = nextSegment ? calculateLayoverTime(Destination?.ArrTime, nextSegment.Origin?.DepTime) : null;

                    return (
                        <React.Fragment key={index}>
                            {/*       {segment.GroundTime ? <div className={'card mt-1 mb-1'}>
                                <p className="popover-text text-center pt-2">
                                    <span className={'color-red'}>Layover Time: {minToHours(segment.GroundTime)}</span>
                                </p>
                            </div> : null}*/}
                            <li className={'p-0 layover-li'}>
                                {airLine?.AirlineCode && (
                                    <span className="img">
                                        <img src={getAirlineLogo(airLine.AirlineCode)} alt=""/>
                                    </span>
                                )}
                                <h6 className={'mr-10'}>
                                    <div className={'f-12 b-600'}>
                                        {airLine?.AirlineCode} - {airLine?.FlightNumber} {airLine?.FareClass ?? ""}
                                    </div>
                                    <small className="f-12">
                                        {segment?.Craft ? `Craft - ${segment.Craft}` : ""}<br/>
                                        {airLine?.OperatingCarrier ? `Opt. By. - ${airLine.OperatingCarrier}` : ""}<br/>
                                    </small>
                                </h6>
                                <div className="layover-li-details">
                                    <h6>
                                        {Origin && <FlightAddress data={Origin} time={Origin.DepTime}/>}
                                        <small className={'f-12'}>
                                            Terminal: {Origin?.Airport?.Terminal ?? "N/A"}
                                        </small>
                                    </h6>
                                    <h6>

                                        <span className={'round-trip-icon'}><FlightImg/></span><br/>
                                        <small className={'f-12'}>
                                            {segment.Duration ? minToHours(segment.Duration) : ""}
                                        </small>
                                    </h6>


                                    <h6>
                                        {Destination && <FlightAddress data={Destination} time={Destination.ArrTime}/>}
                                        <small className={'f-12'}>
                                            Terminal: {Destination?.Airport?.Terminal ?? "N/A"}
                                        </small>
                                    </h6>
                                </div>
                            </li>
                            {segment.IsTransitVisaRequired ?
                                <div className="transit-visa-required-box">
                                    <div className="box">
                                        <span>Transit Visa Required</span>
                                        <small> You will require a transit visa</small>
                                    </div>
                                </div> : null}


                            {layoverTime && (
                                <div className={'card mt-1 mb-1'}>
                                    <p className="popover-text text-center pt-2">
                                        <span className={'color-red'}>Layover Time: {layoverTime}</span>
                                    </p>
                                </div>
                            )}

                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );

    return (
        <Popover content={contentBody} overlayClassName={'stop-overlay-popover'}>
            <div className={'cursor-pointer'} style={{lineHeight: 1.8}}>{children}</div>
        </Popover>
    );
};

export default AllStopsDetailsPopover;
