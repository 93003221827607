import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {getWalletBalanceFxn, lccFlightTicketFxn} from "../../flightSearch/actions";
import TickIcon from "../../../assets/img/tick.png";
import {Icon, notification} from "../../../components/Elements";
import {Card} from "../../../components/Elements";
import {ccAvenueIcon, WalletIcon} from "../../../components/imgComp"
import {formatCurrency} from "../../../components/utils/appUtils";
import {getUserData} from "../../../request";
import {connect} from "react-redux";
import {hideLoader, showLoader} from "../../../actions/loader";

const PaymentComponent = (props) => {
    const [selectedPayment, setSelectedPayment] = useState('mastercard');
    let {convinceFees, xtravelMarginData, totalPaidAmount, callbackState, currentUser} = props;
    let dispatch = useDispatch()
    let [walletBalance, setWalletBalance] = useState(0)
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [windowRef, setWindowRef] = useState(null);
    const [isWindowOpen, setIsWindowOpen] = useState(false);
    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };
    const events = {
        loadBalance: async () => {
            let {success, xTravelWallet} = await dispatch(getWalletBalanceFxn());
            if (success) {
                setWalletBalance(xTravelWallet)
            }
        },
        reloadBalance: async () => {
            let {success, xTravelWallet} = await dispatch(getWalletBalanceFxn());
            if (success) {
                setWalletBalance(xTravelWallet);
                dispatch(hideLoader())
            } else {
                dispatch(hideLoader())
            }
        },
        payDirectPayment: () => {
            dispatch(showLoader())
            if (!windowRef || windowRef.closed) {
                let finalAmount = totalPaidAmount + convinceFees
                const newWindow = window.open(`/direct-deposit?amount=${finalAmount}`, "_blank", "width=1100,height=800");
                setWindowRef(newWindow);
                setIsWindowOpen(true);
                if (newWindow) {
                    // Set interval to check if the child window is closed
                    const interval = setInterval(() => {
                        if (newWindow.closed) {
                            clearInterval(interval);  // Clear the interval once the window is closed
                            setIsWindowOpen(false);
                            onChildWindowClose();  // Trigger when the window is closed
                        }
                    }, 500);
                }
            }
        },
        closeWindow: () => {
            if (windowRef && !windowRef.closed) {
                windowRef.close();
                setWindowRef(null);
            }
        }
    }
    const onChildWindowClose = () => {
        dispatch(hideLoader())
    };


    useEffect(() => {
        events.loadBalance()
    }, [])


    const isBalanceLoading = useSelector((state) => state.global.isBalanceLoading);

    useEffect(() => {
        if (isBalanceLoading && currentUser && currentUser._id) {
            events.reloadBalance()
        }
    }, [isBalanceLoading])


    return (
        <div className="passenger-details">
            <div className="title">
                <h5 className="w-100 justify-content-start">Payments</h5>
            </div>

            <div className="payment-method">
                <h4>Choose Payment Method</h4>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active"
                            id="deposit-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#deposit-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="deposit-tab-pane"

                            aria-selected="true">
                            Deposit
                        </button>
                    </li>

                    {/*<li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="card-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#card-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="card-tab-pane"
                            aria-selected="false"
                        >
                            Credit/Debit Card
                        </button>
                    </li>*/}

                </ul>

                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="deposit-tab-pane"
                        role="tabpanel"
                        aria-labelledby="deposit-tab"
                        tabIndex="0">
                        <p className={'mt-3'}>
                            <Card className={'travel-wallet'} round={10}>
                                <div className={'d-flex'}>
                                    <div className={'d-flex justify-content-between w-100 align-items-start'}>
                                        <div className={'d-flex'}>
                                            <img src={WalletIcon} height={60}/>
                                            <div>
                                                <div className={'small-1'}>Balance :</div>
                                                <div className={'balance'}>
                                                    {formatCurrency(walletBalance)}
                                                </div>
                                                <small className="d-block">Current X Travel World Wallet Balance</small>
                                            </div>
                                        </div>
                                        {currentUser && currentUser.userType == "agent" ?
                                            <div className={'cursor-pointer small-1 text-decoration-underline'}
                                                 onClick={events.payDirectPayment}>
                                                Add Balance
                                            </div> : null}
                                    </div>
                                </div>
                            </Card>
                        </p>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="card-tab-pane"
                        role="tabpanel"
                        aria-labelledby="card-tab"
                        tabIndex="0">
                        <Card className={'travel-wallet  mt-3'}>
                            <div className="choose-mode">
                                <div className={'subTypeWrapper'}>
                                    <p className={'subTypeWrapper__text'}>
                                        {/*Please Select Preferred Option to Proceed*/}
                                        Please Select Preferred Option to Deposit Amount
                                    </p>
                                    <div className={'subTypeWrapper__box paySelect__selected'}
                                         style={{width: 190, marginBottom: 0}}>
                                        <div className={'paySelect'}>
                                            <input type="radio" id="paymentMedium_0" name="mediumRadio"
                                                   checked={true}
                                                   className="paySelect__input" value="CCAvenue"/>
                                            <label className={'paySelect__label'}>
                                                <img src={ccAvenueIcon} className={'paySelect__img'}/>
                                            </label>
                                        </div>
                                    </div>
                                    <h5 style={{fontSize: "1.05rem", marginLeft: 10}}>
                                        Amount : Rs. {totalPaidAmount}
                                    </h5>
                                </div>

                                {/*<ul>
                                    <li>
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="mastercard"
                                            onChange={handlePaymentChange}
                                            checked={selectedPayment === 'mastercard'}
                                        />
                                        <label><img src="images2/master-card.png" alt="Mastercard"/></label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="paypal"
                                            onChange={handlePaymentChange}
                                            checked={selectedPayment === 'paypal'}
                                        />
                                        <label><img src="images2/pay-pal.png" alt="PayPal"/></label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="visa"
                                            onChange={handlePaymentChange}
                                            checked={selectedPayment === 'visa'}
                                        />
                                        <label><img src="images2/visa.png" alt="Visa"/></label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="wallet"
                                            onChange={handlePaymentChange}
                                            checked={selectedPayment === 'wallet'}
                                        />
                                        <label>Wallet</label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="upi"
                                            onChange={handlePaymentChange}
                                            checked={selectedPayment === 'upi'}
                                        />
                                        <label>UPI</label>
                                    </li>
                                </ul>*/}
                            </div>

                        </Card>
                    </div>
                </div>
            </div>

            <div className="terms-check d-flex flex-wrap gap-2 gap-lg-5 flex-lg-nowrap mt-3 align-items-center">
                <div className="d-flex gap-2 text-nowrap align-items-center">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`acceptTerms`}
                            checked={isTermsAccepted}
                            onChange={() => setIsTermsAccepted(!isTermsAccepted)}

                        />
                        <label className="form-check-label ms-2 mt-1" htmlFor={`acceptTerms`}> I accept <a href="#">terms
                            & conditions</a></label>
                    </div>
                    {/*    <input*/}
                    {/*        type="checkbox"*/}
                    {/*        id="acceptTerms"*/}
                    {/*        checked={isTermsAccepted}*/}
                    {/*        className="form-check-input"*/}
                    {/*        onChange={(e) => setIsTermsAccepted(e.target.checked)}*/}
                    {/*        // style={{display: "none"}}*/}
                    {/*    />*/}
                    {/*    <span onClick={() => setIsTermsAccepted(!isTermsAccepted)} style={{cursor: "pointer"}}>*/}
                    {/*  <img src={TickIcon} alt={'icon'}*/}
                    {/*       style={{width: '22px'}}*/}
                    {/*  />*/}
                    {/*</span>*/}
                    {/*    <label htmlFor="acceptTerms" className="form-check-label">*/}
                    {/*        I accept <a href="#">terms & conditions</a>*/}
                    {/*    </label>*/}
                </div>
                <button className="btn btn-primary w-100 mx-auto" onClick={() => {
                    if (walletBalance >= totalPaidAmount) {
                        callbackState();
                    } else {
                        notification.warning({
                            message: 'Insufficient agent wallet balance.'
                        })
                    }
                }}
                        disabled={!isTermsAccepted}
                >Pay Now
                </button>
            </div>
        </div>
    );
};
const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser
})
export default connect(
    mapStateToProps,
    null
)(PaymentComponent)
