import React, {useEffect, useState} from "react";
import {bookSeatsFxn} from "../../ticketList/action";
import {useDispatch, useSelector} from "react-redux";
import SeatsLayoutModal from "../../ModalComponent/seatLayout";
import {FoodIcon, BaggageIcon, SeatIcon, BadgeIcon} from "../../../components/imgComp"
import {InputBox} from "../../../components/Elements";
import _ from "lodash"

const AncillaryServiceForm = (props) => {
    const {
        adultPassengers = [],
        childPassengers = [],
        infantPassengers = [],
        updateAncillaryServices,
        selectedFlight,
        ssrResponse,
        fareObj
    } = props;
    // console.log(ssrResponse, 'ssrResponse');
    const {flightObj, traceId} = useSelector((state) => ({
        flightObj: state.flightsReducer.flightObj,
        traceId: state.flightsReducer.traceId,
    }));
    const [selectedPass, setSelectedPass] = useState({
        index: 0,
        type: "adult"
    })
    const [seatsObj, setSeatsObj] = useState([]);
    const [flightMealList, setFlightMealList] = useState([])
    const [bagServices, setBagServices] = useState([]);
    const [checkinServices, setCheckinServices] = useState([]);
    const [showSeatsModal, setShowSeatsModal] = useState({
        visible: false,
        seatsObj: [],
        seatList: [],
        type: "", index: ""
    });
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [flightMealOptions, setFlightMealOptions] = useState([]);
    const [seatSegmentList, setSeatSegmentList] = useState([]);
    const [requiredFields, setRequiredFields] = useState({})

    const getBookSeatsUrl = () => {
        /* if (ssrResponse && ssrResponse['SeatDynamic'] && ssrResponse['SeatDynamic'][0] && ssrResponse['SeatDynamic'][0]['SegmentSeat']) {
             setSeatsObj(ssrResponse['SeatDynamic'][0]['SegmentSeat']);
         }*/
        if (fareObj) {
            setRequiredFields(fareObj && fareObj['RequiredFieldValidators'] || {});
        }
        if (ssrResponse?.SpecialServices?.[0]?.SegmentSpecialService?.[0]?.SSRService) {
            let serviceList = ssrResponse.SpecialServices?.[0]?.SegmentSpecialService?.[0]?.SSRService;
            let bagServices = []
            let checkinServices = []
            _.each(serviceList, (item) => {
                if (["Priority Check-In", "Lounge Access"]?.includes(item['Text'])) {
                    checkinServices.push(item)
                } else {
                    bagServices.push(item)
                }
            })
            setCheckinServices(checkinServices)
            setBagServices(bagServices)
        }
        if (ssrResponse['MealDynamic']?.[0]) {
            let mealList = ssrResponse['MealDynamic'][0];
            let flightMealList = []
            mealList = _.reject(mealList, (item) => {
                return item['Code'] == "NoMeal"
            })
            let dd = _.groupBy(mealList, (item) => {
                return `${item['Origin']}-${item['Destination']}`
            })
            _.each(dd, (item, key) => {
                flightMealList.push({
                    name: key,
                    list: item
                })
            })
            setFlightMealList(flightMealList)
        }

        if (ssrResponse['Meal']) {
            let mealList = ssrResponse['Meal'];
            setFlightMealOptions(mealList);
        }


    };
    useEffect(() => {
        getBookSeatsUrl();
    }, [traceId, selectedFlight, ssrResponse]);

    const formatBaggageOption = (baggage) => {
        return baggage && baggage['Weight'] ? `${baggage['Weight']}-Kg ₹${baggage['Price']}` : "";
    }

    const renderPassengerCard = (passenger, title, index) => {
        let baggage = passenger && passenger['Baggage'] && passenger['Baggage'] ? passenger['Baggage'] : {}
        let mealDynamic = passenger && passenger['MealDynamic'] && passenger['MealDynamic'] ? passenger['MealDynamic'] : []
        let bagDynamic = passenger && passenger['BagDynamic'] && passenger['BagDynamic'] ? passenger['BagDynamic'] : {}
        let specialServices = passenger && passenger['SpecialServices'] && passenger['SpecialServices'] ? passenger['SpecialServices'] : []
        // let seatDynamic = passenger && passenger['SeatDynamic'] && passenger['SeatDynamic'] ? passenger['SeatDynamic'] : []
        let allList = [...specialServices, bagDynamic]
        let serviceList = allList.map((list) => list['Text']).join(", ")
        let visibleName = passenger.firstName ? `${passenger.title ? passenger.title : ""} ${passenger.firstName} ${passenger.lastName ? passenger.lastName : ""}` : `${title} ${index + 1}`
        let selectedBox = selectedPass.index == index && selectedPass.type == title;
        const selectedSeatForPassenger = selectedSeats[`${title}-${index}`];
        let seatList = selectedSeatForPassenger?.map((list) => list['Code']).join(", ")
        return (
            <div
                key={`${title}-${index}`}
                className={`card cursor-pointer p-2 mb-3 ${selectedBox ? "border-primary" : ""}`}
                onClick={() => events.handlePassengerSelection(index, title)}>
                <label htmlFor={`passenger-${title}-${index}`} className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className={'d-flex align-items-center'}>
                           <input
                               type="radio"
                               id={`passenger-${index}`}
                               name="passenger"
                               value={index}
                               checked={selectedBox}
                               className="mr-2"
                           />
                            <b className={'ms-2 text-uppercase'}>  {visibleName}</b>
                        </span>
                    </div>
                    <div className="text-muted d-flex mt-1">
                        <small className={'d-flex align-items-center mr10 cursor-pointer'}>
                            <img src={SeatIcon} alt={'baggage'} height={15}
                                 className={'mr5'}/>
                            {seatList ? seatList : "No Seat"}
                        </small>
                        <small className={'d-flex align-items-center mr10 cursor-pointer'}>
                            <img src={FoodIcon} alt={'baggage'} height={15} className={'mr5'}/>
                            {mealDynamic.length > 0
                                ? mealDynamic.length
                                : "--"}
                        </small>

                        <small className={'d-flex align-items-center cursor-pointer'}>
                            <img src={BaggageIcon} alt={'baggage'} height={15} className={'mr5'}/> {baggage
                            ? `${formatBaggageOption(baggage)} kg`
                            : "0 kg"}
                        </small>
                    </div>

                    {serviceList ?
                        <div className="mt-1 text-muted cursor-pointer">
                            <img src={BadgeIcon} alt={'meal'} height={18} className={'mr5'}/>
                            <small>{serviceList}</small>
                        </div> : null}
                </label>
            </div>
        );
    };

    const renderPassengerDetails = (passenger, type, index) => {
        if (type === "infant") {
            return (
                selectedPass.index == index && selectedPass.type == type ? (
                    <div className="card px-3 pb-2">
                        <h6 className="p-2 pt-3 text-uppercase">{type} {index + 1}</h6>
                        {ssrResponse.Meal && !ssrResponse.MealDynamic ? (
                            <div className="mt-3">
                                <InputBox
                                    icon={<img src={FoodIcon} alt="meal" className="mr5" height={18}/>}
                                    title="Meal Selection">
                                    <select
                                        id="meal"
                                        className="form-control"
                                        value={""}
                                        onChange={(e) => events.handleChooseMeal(e.target.value, type, index)}>
                                        <option value="">Select Meal</option>
                                        {ssrResponse.Meal.map((meal, mealIndex) => (
                                            <option key={mealIndex} value={meal.Code}>
                                                {meal.Description}
                                            </option>
                                        ))}
                                    </select>
                                </InputBox>
                            </div>
                        ) : (
                            <p style={{color: 'red'}}>
                                <b>Baggage OR Meal Preference is not available for Infant.</b>
                            </p>
                        )}
                    </div>
                ) : null
            );
        }
        let baggage = passenger && passenger['Baggage'] && passenger['Baggage']['Code'] ? passenger['Baggage']['Code'] : {}
        let mealDynamic = passenger && passenger['MealDynamic'] && passenger['MealDynamic'] ? passenger['MealDynamic'] : []
        let specialServices = passenger && passenger['SpecialServices'] && passenger['SpecialServices'] ? passenger['SpecialServices'] : []
        let bagDynamic = passenger && passenger['BagDynamic'] && passenger['BagDynamic']['Code'] ? passenger['BagDynamic']['Code'] : ""
        // let seatDynamic = passenger && passenger['SeatDynamic'] && passenger['SeatDynamic'] ? passenger['SeatDynamic'] : []
        let meal = passenger && passenger['Meal'] ? passenger['Meal'] : []
        const selectedSeatForPassenger = selectedSeats[`${type}-${index}`];
        return (

            selectedPass.index == index && selectedPass.type == type ? <div className={'card px-3 pb-2'}>
                <h6 className={'p-2 pt-3 text-uppercase'}>{type} {index + 1}</h6>
                {ssrResponse?.Baggage?.[0]?.length > 0 && (
                    <div>
                        <InputBox
                            icon={<img src={BaggageIcon} alt={"baggage"} className={'mr5'} height={18} />}
                            title={"Baggage"}>
                            <select id="baggage" className="form-control"
                                    value={baggage}
                                    onChange={(e) => {
                                        events.handleChooseBaggage(e.target.value, type, index)
                                    }}>
                                <option value="">No Excess/Extra Baggage</option>

                                {(ssrResponse.Baggage?.[0]?.length > 1) &&
                                (ssrResponse.Baggage[0] || []).map((baggage, index) => (
                                    baggage['Code'] !== "NoBaggage" ?
                                        <option key={index} value={baggage.Code}>
                                            {formatBaggageOption(baggage)}
                                        </option>
                                        : null
                                ))
                                }
                            </select>
                        </InputBox>
                    </div>
                )}


                {meal.length ? meal.map((mealItem, mealIndex) => (
                    <div className={'mt-3'} key={mealIndex}>
                        <InputBox
                            icon={<img src={FoodIcon} alt={"meal"} className={'mr5'} height={18}/>}
                            title={`Meal -  (${mealItem.Description})`}>
                            <select
                                id="meal"
                                className="form-control"
                                value={mealItem.Code || ""}
                                onChange={(e) => {
                                    events.handleChooseMealDynamic(e.target.value, type, index, mealIndex)
                                }}>
                                <option value="">Add No Meal - INR 0</option>
                                {meal.map((mealOption, index) => (
                                    <option key={index} value={mealOption.Code}>
                                        {mealOption.Description}
                                    </option>
                                ))}
                            </select>
                        </InputBox>
                    </div>
                )) : null}
                {flightMealList && flightMealList.length ? flightMealList?.map((meal, mealIndex) => {
                    let ffMeal = _.find(mealDynamic, (item) => {
                        let kKey = item && item['Origin'] ? `${item['Origin']}-${item['Destination']}` : null
                        return kKey == meal.name
                    })
                    return (
                        <div className={'mt-3'}>
                            <InputBox
                                icon={<img src={FoodIcon} alt={"meal"} className={'mr5'} height={18}/>}
                                title={`Meal -  (${meal.name})`}>
                                <select
                                    id="meal"
                                    className="form-control"
                                    value={ffMeal && ffMeal['Code'] || ""}
                                    onChange={(e) => {
                                        events.handleChooseMealDynamic(e.target.value, type, index, mealIndex)
                                    }}>
                                    <option value="">Add No Meal - INR 0</option>
                                    {(meal.list).map((meal, index) => (
                                        <option key={index} value={meal.Code}>
                                            {meal.AirlineDescription} - ₹{meal.Price}
                                        </option>
                                    ))}
                                </select>
                            </InputBox>
                        </div>
                    )
                }) : null}

                {flightMealOptions.length > 0 && (
                    <div className={'mt-3'}>
                        <InputBox
                            icon={<img src={FoodIcon} alt={"meal"} className={'mr5'} height={18}/>}
                            title="Meal Selection">
                            <select
                                id="meal"
                                className="form-control"
                                value={meal.Code || ""}
                                onChange={(e) => {
                                    events.handleChooseMeal(e.target.value, type, index);
                                }}>
                                <option value="">Add No Meal - INR 0</option>
                                {flightMealOptions.map((mealOption, mealIndex) => (
                                    <option key={mealIndex} value={mealOption.Code}>
                                        {mealOption.Description}
                                    </option>
                                ))}
                            </select>
                        </InputBox>
                    </div>
                )}
                {((checkinServices && checkinServices.length > 0) || (bagServices && bagServices.length)) ?
                    <div className="mb-2 mt-3">
                        <InputBox icon={<img src={BadgeIcon} alt={'special services'} height={18} className={'mr5'}/>}
                                  title={"Special Services"}>
                            <div style={{marginLeft: 25}}>
                                <div className={'row'}>
                                    {checkinServices && checkinServices.length ? checkinServices.map((service) => {
                                        return (
                                            <div key={index} className="form-check col-md-6">
                                                <input
                                                    type={"checkbox"}
                                                    id={`specialService-${service.Code}`}
                                                    className="form-check-input"
                                                    value={service.Code || ""}

                                                    checked={specialServices.some((s) => s && s.Code === service.Code) || ""}
                                                    onChange={(e) => {
                                                        events.handleSpecialServices(e.target, type, index)
                                                    }}
                                                />
                                                <label htmlFor={`specialService-${service.Code}`}
                                                       className="form-check-label option-form">
                                                    {service.Text} - <b>₹{service.Price} {service.Currency}</b>
                                                </label>
                                            </div>
                                        )
                                    }) : null}
                                </div>

                                <div className={'row mt-2'}>
                                    {bagServices && bagServices.length ? bagServices.map((service) => {
                                        return (
                                            <div key={index} className="form-check col-md-12">
                                                <input
                                                    type={"radio"}
                                                    id={`specialService-${service.Code}`}
                                                    className="form-check-input"
                                                    value={service.Code || ""}
                                                    name={"specialServiceRadio"}
                                                    checked={bagDynamic == service['Code']}
                                                    onChange={(e) => {
                                                        events.handleBagSpecialServices(e.target, type, index)
                                                    }}
                                                />
                                                <label htmlFor={`specialService-${service.Code}`}
                                                       className="form-check-label option-form">
                                                    {service.Text} - <b>₹{service.Price} {service.Currency}</b>
                                                </label>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>

                        </InputBox>
                    </div>
                    : null}
                {/*  {requiredFields.IsSeatRequired && (

                )}*/}

                {ssrResponse['SeatDynamic']?.[0]?.['SegmentSeat'].length > 0 && (
                    <div className="mb-2 mt-3">
                        <InputBox
                            icon={<img src={SeatIcon} alt={'baggage'} style={{height: 18, marginRight: 5}}/>}
                            title={"Seat Preference"}
                            extra={(
                                <a type="button"
                                   className="btn btn-primary ms-3 mt-1"
                                   onClick={() => {
                                       events.handleShowSeatsModal(passenger, type, index)
                                   }}>
                                    Select Seats
                                </a>
                            )}
                        />
                    </div>
                )}


            </div> : null
        )
    }


    const events = {
        handlePassengerSelection: (passengerIndex, type) => {
            setSelectedPass({
                index: passengerIndex, type
            })
        },
        handleShowSeatsModal: async (passenger, type, index) => {
            if (traceId) {
                await getBookSeatsUrl();
                const seats = ssrResponse['SeatDynamic']?.[0]?.['SegmentSeat'] || [];
                let seatDynamic = passenger && passenger['SeatDynamic'] ? passenger['SeatDynamic'] : []
                if (seats.length > 0) {
                    setShowSeatsModal({
                        visible: true,
                        seatsObj: seats,
                        seatList: seatDynamic,
                        type, index
                    });
                }
            }
        },
        handleChooseBaggage: (value, type, index) => {
            let baggageList = ssrResponse.Baggage && ssrResponse.Baggage[0] ? ssrResponse.Baggage[0] : []
            const aa = _.find(baggageList, (item) => {
                return item['Code'] == value;
            })
            updateAncillaryServices({"Baggage": aa}, type, index)
        },
        handleChooseMealDynamic: (value, type, index, mealIndex) => {
            let mealList = flightMealList[mealIndex];
            const selectedMeal = mealList.list.find(item => item['Code'] === value);

            let passengerList = type === "adult" ? adultPassengers : childPassengers;
            let passenger = {...passengerList[index]};

            let mealDynamic = passenger.MealDynamic ? [...passenger.MealDynamic] : [];

            mealDynamic[mealIndex] = selectedMeal || {};

            updateAncillaryServices({"MealDynamic": mealDynamic}, type, index);
        },

        handleChooseMeal: (value, type, index) => {
            let meal = flightMealOptions.find(meal => meal.Code === value);
            updateAncillaryServices({"Meal": meal}, type, index);
        },
        handleChooseSeatDynamic: (value) => {
            const maxSeats = Math.max(...value.map(segment => segment.seats.length));
            let updatedSeats = {...selectedSeats};
            for (let seatIndex = 0; seatIndex < maxSeats; seatIndex++) {
                let groupedSeats = [];

                value.forEach((segment) => {
                    if (segment.seats[seatIndex]) {
                        groupedSeats.push(segment.seats[seatIndex]);
                    }
                });

                if (groupedSeats.length > 0) {
                    let passengerType = "adult";
                    let passengerIndex = seatIndex;

                    if (seatIndex >= adultPassengers.length) {
                        passengerType = "child";
                        passengerIndex = seatIndex - adultPassengers.length;
                    }
                    updatedSeats[`${passengerType}-${passengerIndex}`] = groupedSeats;
                    updateAncillaryServices(
                        {"SeatDynamic": groupedSeats},
                        passengerType,
                        passengerIndex
                    );
                }
            }

            setSelectedSeats(updatedSeats);
        },

        handleBagSpecialServices: (obj, type, index) => {
            let findD = _.find(bagServices, (item) => {
                return item['Code'] == obj['value']
            })
            if (findD) {
                updateAncillaryServices({"BagDynamic": findD}, type, index)
            }
        },
        handleSpecialServices: (obj, type, index) => {
            let {checked, value} = obj;
            let findD = _.find(checkinServices, (item) => {
                return item['Code'] == value;
            });
            let passengerList = type == "adult" ? adultPassengers : childPassengers;
            let services = passengerList[index] && passengerList[index]['SpecialServices'] ? passengerList[index]['SpecialServices'] : [];

            if (checked) {
                services.push(findD);
            } else {
                services = _.reject(services, (item) => {
                    return item['Code'] == value;
                });
            }

            updateAncillaryServices({"SpecialServices": services}, type, index)
        }

    }

    return (
        <>

        <div className={'ssr-grid'}>
                {
                    ssrResponse && Object.keys(ssrResponse).length > 0 ? (
                        <>
                            <div>
                                {adultPassengers?.map((passenger, index) => {
                                    return renderPassengerCard(passenger, 'adult', index)
                                })}
                                {childPassengers?.map((passenger, index) => {
                                    return renderPassengerCard(passenger, 'child', index)
                                })}
                                {infantPassengers?.map((passenger, index) => {
                                    return renderPassengerCard(passenger, 'infant', index)
                                })}

                            </div>
                            <div>
                                {adultPassengers?.map((passenger, index) => {
                                    return renderPassengerDetails(passenger, 'adult', index)
                                })}
                                {childPassengers?.map((passenger, index) => {
                                    return renderPassengerDetails(passenger, 'child', index)
                                })}
                                {infantPassengers?.map((passenger, index) => {
                                    return renderPassengerDetails(passenger, 'infant', index)
                                })}
                            </div>
                        </>
                    ) : null
                }

            </div>


            {showSeatsModal.visible && showSeatsModal.seatsObj.length > 0 && (
                <SeatsLayoutModal
                    visible={showSeatsModal.visible}
                    onClose={() => setShowSeatsModal({visible: false})}
                    seatsObj={showSeatsModal.seatsObj}
                    adultPassengers={adultPassengers}
                    childPassengers={childPassengers}
                    selectedFlight={selectedFlight}
                    selectedSeats={selectedSeats}
                    setSelectedSeats={setSelectedSeats}
                    seatSegmentList={seatSegmentList}
                    onSeatConfirm={(value) => {
                        events.handleChooseSeatDynamic(value);
                        setSeatSegmentList(value)
                    }}
                />
            )}
        </>
    );
};

export default AncillaryServiceForm;
