import React, {useEffect} from "react";
import Slider from "react-slick";
import Reviews from "../components/pageComponents/reviews";
import DownloadApplications from "../components/pageComponents/downloadApplications";
import RegisterBanner from "../components/pageComponents/registerBanner";
import {useLocation} from 'react-router-dom';

const Home = (props) => {

    return (
        <>


            <section className="why-choose spacing-60">
                {/*<img src="../images/why-choose-banner.png" alt="Why Choose Banner"/>*/}
                <div className="container position-relative">
                    <div className="head text-center mb-5">
                        <h3>Why Choose X Travel World?</h3>
                    </div>
                    <div className="col-lg-9 col-12 mx-auto">
                        <div className="row row-gap-4">
                            <div className="col-lg-4 col-12">
                                <div className="content-box">
                                        <span><img src="../images/why-choose-icon-1.png"
                                                   alt="Exclusive Deals Icon"/></span>
                                    <h4>Exclusive Access to the Best Deals</h4>
                                    <p className="m-0">Our B2B partners with exclusive access to airline and hotel deals.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="content-box">
                                        <span><img src="../images/why-choose-icon-2.png"
                                                   alt="Real-Time Booking Icon"/></span>
                                    <h4>Effortless Booking with Advanced Tools</h4>
                                    <p className="m-0">Fast and seamless flight and hotel bookings with real-time inventory updates.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="content-box">
                                        <span><img src="../images/why-choose-icon-3.png"
                                                   alt="24/7 Support Icon"/></span>
                                    <h4>Dedicated Support for B2B Partners</h4>
                                    <p className="m-0">Ensuring a hassle-free experience and maximizing your business potential.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="note-banner pt-lg-4 pt-3">
                <div className="container">
                    <h3 className="m-0">Trusted by Thousands. Begin Your Journey with Confidence Today!</h3>
                </div>
            </section>

            <section className="agent-with-us spacing-60 pb-0">
                <div className="container">
                    <div className="head text-center mb-5">
                        <h3>How to Register as an Agent with us?</h3>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="grid">
                                <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                    <span><img src="../images/agent-icon-1.png" alt="Signup Icon"/></span>
                                    <div className="content-box">
                                        <h4>Register Yourself</h4>
                                        <p>Create your account by providing basic details on our website.</p>
                                    </div>
                                </div>
                                <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-2.png"
                                                   alt="Registration Form Icon"/></span>
                                    <div className="content-box">
                                        <h4>Complete the Registration Form</h4>
                                        <p>Fill out the required information, including your business details, to get started.</p>
                                    </div>
                                </div>
                                <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-3.png"
                                                   alt="Submit Application Icon"/></span>
                                    <div className="content-box">
                                        <h4>Account Verification & Approval</h4>
                                        <p>Our team will review your application and approve your account promptly.</p>
                                    </div>
                                </div>
                                <div className="box p-lg-4 p-3 g-col-12">
                                    <span><img src="../images/agent-icon-4.png" alt="Start Booking Icon"/></span>
                                    <div className="content-box">
                                        <h4>Start Booking Flights and Hotels</h4>
                                        <p>Once approved, log in and begin accessing exclusive flight and hotel deals.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 position-sticky" style={{top: "150px"}}>
                            <div id="container" className="text-center">
                                <div className="item">
                                    <img src="../images/logo-blue.png" alt="Logo"/>
                                </div>
                                <div className="circle"></div>
                                <div className="circle" style={{animationDelay: "0s"}}></div>
                                <div className="circle" style={{animationDelay: "1s"}}></div>
                                <div className="circle" style={{animationDelay: "2s"}}></div>
                                <div className="circle" style={{animationDelay: "3s"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DownloadApplications/>

            <Reviews size={'sm'}/>
            <RegisterBanner/>
        </>
    );
};

export default Home;
