import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux";
import {addPaymentFxn, ccAvenuePaymentFxn} from "./action";
import ReactHtmlParser from "react-html-parser";

let initState = {
    amount: 5000
}
const CcAvenueTesting = (props) => {
    let dispatch = useDispatch()
    let [state, setState] = useState(initState);
    let [ccForm, setCcForm] = useState("")
    const [windowRef, setWindowRef] = useState(null);

    const openWindow = () => {
        if (!windowRef || windowRef.closed) {
            // const newWindow = window.open("/payment-response/67cc3d881715c942f945182a", "_blank", "width=1100,height=800");
            const newWindow = window.open(`/direct-deposit?amount=5020`, "_blank", "width=1100,height=800");
            setWindowRef(newWindow);
        }
    };

    const closeWindow = () => {
        if (windowRef && !windowRef.closed) {
            windowRef.close();
            setWindowRef(null);
        }
    };
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        addPayment: async (e) => {
            e.preventDefault();
            let obj = {
                ...state,
                email: "rakesh@scizers.com",
                name: "Rakesh koli",
                phoneNo: "9050668544"
            }
            let resp = await dispatch(addPaymentFxn(obj));
            if (resp && resp.data) {
                events.loadPayment(resp.data._id)
            }
        },
        loadPayment: async (id) => {
            let respForm = await dispatch(ccAvenuePaymentFxn(id));
            setCcForm(respForm)
        }
    }

    useEffect(() => {
        if (ccForm) {
            const form = document.getElementById("nonseamless");
            form.submit()
        }
    }, [ccForm])
    return (
        <>
            <section className="about-banner">
                <div className="spacing-60">
                    <div className="container">
                        <div className="col-lg-10 mx-auto col-12">
                            <div className="row row-gap-3 align-items-center">
                                <div className="col-lg-6 col-12">
                                    <h2 className="m-0">Let’s Get in Touch</h2>
                                </div>
                                <div className="col-lg-5 ms-auto col-12">
                                    <p className="m-0">We’re here to assist you with all your travel booking needs.
                                        Whether you’re looking to partner with us or have a query, our team is ready to
                                        help</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="contact-form spacing-60 pb-0">
                <div className="container">
                    <div className="row row-gap-3">
                        <div className="col-lg-12 col-12">

                            <button onClick={openWindow}>Open Window</button>
                            <button onClick={closeWindow} variant="destructive">
                                Close Window
                            </button>

                            <section className="form-section p-0">
                                <div className="card rounded-0 shadow-none p-0 border-0 m-0">
                                    <div>
                                        <div className="row row-gap-3">
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Amount</label>
                                                <input type="text" className="form-control"
                                                       value={state.amount}
                                                       onChange={({target}) => {
                                                           events._updateState({amount: target.value})
                                                       }}
                                                       placeholder="Your First Name" required/>
                                            </div>
                                        </div>
                                        <a className="btn btn-primary mt-4" onClick={events.addPayment}>Submit</a>
                                    </div>
                                </div>
                                {ccForm ? ReactHtmlParser(ccForm) : null}

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CcAvenueTesting
