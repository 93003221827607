import {xTravelAxios as axios} from "../../../request";
import {
    addPaymentUrl,
    ccAvenuePaymentUrl,
    ccAvenueSuccessUrl,
    singlePaymentUrl,
    updatePaymentUrl,
    ccAvenueCancelUrl
} from "../api";
import {showLoader, hideLoader} from "../../../actions/loader";

export const addPaymentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addPaymentUrl(), valData);
    dispatch(hideLoader())
    return data
}
export const singlePaymentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(singlePaymentUrl(), valData);
    dispatch(hideLoader())
    return data
}

export const ccAvenuePaymentFxn = (paymentId) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.get(ccAvenuePaymentUrl(paymentId), {});
    dispatch(hideLoader())
    return data
}

export const ccAvenueSuccessFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(ccAvenueSuccessUrl(), valData);
    dispatch(hideLoader())
    return data
}
export const ccAvenueCancelFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(ccAvenueCancelUrl(), valData);
    dispatch(hideLoader())
    return data
}
