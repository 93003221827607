import React, {useEffect, useState} from "react";

import SearchFlightMainComponent from "../../flightSearch/searchComponent/SearchFlightMainComponent";

const ModifySearch = (props) => {
    let {flightObj, flightSearch, onClose} = props
    return (
        <>
            <div className="flight-booking">
                <div className="container position-relative">
                    <div className={"tab-content p-3 p-lg-4 bg-white modify_box"}>

                    <SearchFlightMainComponent
                            {...props}
                            flightObj={flightObj}
                            onClick={(resp) => {
                                flightSearch(resp)
                            }}/>

                        <div className={'mt-2 align-center'}>
                            <a onClick={onClose} className="w-100 cursor-pointer">
                                Cancel
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default ModifySearch;
