import React, {useEffect, useState} from "react";
import Calendar from "rc-calendar";
import DatePicker from "rc-calendar/lib/Picker";
import moment from "moment";
import "rc-calendar/assets/index.css";
import "./index.css";
import "./flightIndex.css";
import {calendarFareFxn} from "../../../containers/ticketList/action";
import {useDispatch} from "react-redux";
import {formatRupees} from "../../utils/appUtils";
import _ from "lodash"

const defaultFormat = "DD-MMM-YYYY";

const FlightDateTimePicker = (props) => {
    let {
        value = null,
        onChange,
        format = defaultFormat,
        item = {},
        placeholder: customPlaceholder,
        customCss = "",
        disabledDate = false,
        defaultValue = "",
        label = "",
        journeyType = "1",
        showDay = true,
        height = 'auto',
        disabled = false,
        tabStyle = {padding: "15px 5px"},
        changeJourneyType = () => null,
        searchResults = [],
        state
    } = props;

    const [calendarFareData, setCalendarFareData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let [lowestPrice, setLowestPrice] = useState(0)
    const {placeholder = "Please select Date"} = item;
    const finalPlaceholder = customPlaceholder ? customPlaceholder : placeholder;
    const defaultDate = moment().startOf("month");
    const getFareForDate = (date) => {
        if (!date) return null;
        const formattedDate = moment(date).format("YYYY-MM-DD");
        const result = calendarFareData.find(item => {
            const apiDate = moment(item.DepartureDate).format("YYYY-MM-DD");
            return apiDate === formattedDate;
        });
        return result ? result.Fare : null;
    };
    const events = {
        getCalendarFare: async (date) => {
            let firstDayOfMonth = moment(date).startOf('month');
            if (firstDayOfMonth < moment()) {
                firstDayOfMonth = moment(date)
            }
            console.log(firstDayOfMonth, 'firstDayOfMonth', firstDayOfMonth.format("DD MM YYYY hh:mm:ss"));
            let obj = {
                origin: state?.origin,
                destination: state?.destination,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            }
            let objReturn = {
                origin: state?.destination,
                destination: state?.origin,
                cabinClass: state?.cabinClass,
                preferredDepartureTime: firstDayOfMonth,
                journeyType: journeyType
            }

            if (firstDayOfMonth > moment()) {
                let valData = label == 'Return' ? objReturn : obj
                let resp = await dispatch(calendarFareFxn(valData));
                if (resp?.data?.SearchResults) {
                    const smallestValueObj = _.minBy(resp.data.SearchResults, "Fare");
                    if (smallestValueObj && smallestValueObj['Fare']) {
                        setLowestPrice(smallestValueObj['Fare'])
                    }
                    setCalendarFareData(resp.data.SearchResults);
                } else {
                    setCalendarFareData([]);
                }
            }
        }
    };

    useEffect(() => {
        setCalendarFareData([])
        // events.getCalendarFare();
    }, [props.state]);


    const dateRender = (current) => {
        const fare = getFareForDate(current);
        return (
            <div className={`custom-calendar-cell cursor-pointer rc-calendar-date`}>
                <span>
                    {current.date()}
                </span>
                {/*{fare !== null && (
                    <span className={`flight-price ${fare == lowestPrice && "lowest"}`}>
                        {formatRupees(Math.round(fare))}
                    </span>
                )}*/}

            </div>
        );
    };

    const calendar = (
        <Calendar
            style={{zIndex: 1000}}
            showClear={false}
            dateInputPlaceholder="Choose Date"
            format={format}
            showDateInput={false}
            animation={'slide-up'}
            disabledDate={disabledDate}
            dateRender={dateRender}
            // onChange={(date) => events.getCalendarFare(date)}
        />
    );

    const tabComp = (
        <small style={{color: "gray", ...tabStyle}}>
            Tap to add return
        </small>
    );

    const valueComp = (
        <>
            <h5>{value ? moment(value).format(`D MMM' YY`) : ""}</h5>
            {showDay ? <small>{value ? moment(value).format(`dddd`) : ""}</small> : null}
        </>
    );

    return (
        <DatePicker
            calendar={calendar}
            value={value || defaultValue}
            showClear={false}
            autoFocus={true}
            disabled={disabled}
            showDateInput={false}
            defaultValue={defaultValue}
            onChange={onChange}
        >
            {({value1}) => {
                return (
                    <div
                        style={{height}}
                        onClick={() => {
                            if (label !== "Departure") {
                                changeJourneyType("2");
                            }
                        }}
                    >
                        <span>{label}</span>
                        {journeyType === "1" ? (
                            <>
                                {label === "Departure" ? valueComp : value ? valueComp : tabComp}
                            </>
                        ) : (
                            <>{value ? valueComp : tabComp}</>
                        )}
                    </div>
                );
            }}
        </DatePicker>
    );
};

export default FlightDateTimePicker;
