import React, { useState, useRef, useEffect, useCallback } from "react";
import { DualCalender } from "../../../components/Elements";
import moment from "moment";

const ChooseDateComponent = (props) => {
    let { label, style, value, onChange, showDay = true, tabStyle = { padding: "15px 5px" }, callback, searchResults } = props;
    const [visibleTypehead, setVisibleTypehead] = useState(false);
    const containerRef = useRef(null);

    // Click outside handler
    const handleClickOutside = useCallback((event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleTypehead(false);
        }
    }, []);

    useEffect(() => {
        if (visibleTypehead) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [visibleTypehead, handleClickOutside]);

    const tabComp = (
        <small style={{ color: "gray", ...tabStyle }}>
            Tap to add return
        </small>
    );

    const valueBox = (
        <>
            <h5>{value ? moment(value).format(`D MMM' YY`) : ""}</h5>
            {showDay ? <small>{value ? moment(value).format(`dddd`) : ""}</small> : null}
        </>
    );

    // useEffect(() => {
    //     if (callback) {
    //         callback(value, label);
    //     }
    // }, [value]);

    return (
        <div ref={containerRef} className="content" style={style} onClick={() => {
            setVisibleTypehead(true)
            callback(value, label);
        }

        }>
            <span>{label}</span>
            {label === "Departure" ? valueBox : value ? valueBox : tabComp}

            {visibleTypehead && (
                <div className={'flight-date-picker'}>
                    <DualCalender
                        {...props}
                        callback={callback}
                        searchResults={searchResults}
                        onSelect={(value) => {
                            onChange(value)
                            // callback(value, label)
                            setTimeout(() => setVisibleTypehead(false), 100);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ChooseDateComponent;
